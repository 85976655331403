import React, { useState } from "react";
import * as S from "./AdminPanel.styles";
import Popup from "../Popup/Popup";
import frame1 from "../../img/Frame1.png";
import frame4 from "../../img/Frame4.png";
import { Button } from "../../panels/Arena/Arena.styles";
import { Close } from "../../App.styles";

function AdminPanel({ app }) {
  const [open, setOpen] = useState(false);
  const [func, setFunc] = useState({});

  function updateInfo() {
    app.service("users").updateAdminInfo({});
  }

  function updateLimits() {
    app.service("users").updateLimitsInfo({});
  }

  function removeDamage() {
    app.service("users").removeStock({ type: "damage" });
  }

  function removeStock() {
    app.service("users").removeStock({ type: "stock" });
  }

  function removeBox() {
    app.service("users").removeStock({ type: "box" });
  }

  function stockClear() {
    app.service("users").removeStock({ type: "promoClear" });
  }

  function raidDamageClear() {
    app.service("users").removeStock({ type: "raidDamage" });
  }

  function shytClear() {
    app.service("users").removeStock({ type: "shyt" });
  }

  function stockClearDonate() {
    app.service("users").removeStock({ type: "promoClearDonate" });
  }

  function limitChange() {
    app.service("users").removeStock({ type: "limitChange" });
  }

  function updateTalents() {
    app.service("users").updateTalentsInfo({});
  }

  function updateMagic() {
    app.service("users").updateMagic({});
  }

  function raidBossClose() {
    app.service("users").raidBossClose({ name: "end" });
  }

  function raidBossClear() {
    app.service("users").raidBossClose({ name: "clear" });
  }

  function tournamentClose() {
    app.service("users").removeStock({ type: "tournamentClose" });
  }

  function tournamentClear() {
    app.service("users").removeStock({ type: "tournamentClear" });
  }

  function handleClick(func) {
    setFunc({ func });
  }

  // const data = [
  //   {
  //     h: "Large pool of SAP experts",
  //     p: `With over 60 SAP specialists on board, we've successfully delivered over 40 SAP projects to our clients. 85% of our team are senior or mid-level developers, which means that at Innowise, you’ll get the proficiency to cover all your SAP needs.`,
  //   },
  //   {
  //     h: "Quick and easy ramp-up ",
  //     p: `We can quickly supply up to 10 skilled SAP developers within a few days. Need their CVs? We’ll get those to you in 1-2 days. Once you choose the candidates who have piqued your interest, they will start working on your project immediately.`,
  //   },
  //   {
  //     h: "Flexible hiring models ",
  //     p: `Our dedicated teams provide full-time, focused support on your project, while our staff extension and augmentation services let you add skilled developers on a full-time or part-time basis to boost your current setup.`,
  //   },
  //   {
  //     h: "Agility and easy integration",
  //     p: `Our specialists can blend right into any project — adapting to different team setups, sizes, and development methods. We can join your existing workforce, spearhead a new project, or adapt to your chosen development approach.`,
  //   },
  //   {
  //     h: "High code quality",
  //     p: `We guarantee high code quality by employing a rigorous testing strategy that combines both manual and automated testing. By mixing manual insights with automated efficiency, we achieve a high standard of reliability and performance for your software.`,
  //   },
  //   {
  //     h: "Efficient management and delivery",
  //     p: `All SAP developers are paired with project coordinators to successfully carry out tasks. For larger and more sophisticated SAP projects, as well as dedicated teams, we deploy a full-fledged project management office (PMO) and certified Scrum masters.`,
  //   },
  // ];

  return (
    <S.Wrapper>
      <Button
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <div>Админка</div>
      </Button>
      <Popup isOpen={open} setIsOpen={setOpen} w={506} h={502} back={frame4}>
        <Close
          onClick={() => {
            setOpen(false);
          }}
        />
        <S.Content>
          {/*{data.map(({ h, p }, i) => {*/}
          {/*  return (*/}
          {/*    // <div className="domains__descriptions-item-new ">*/}
          {/*    //   <div className="slider-title-new">*/}
          {/*    //     <h3>{h}</h3>*/}
          {/*    //   </div>*/}
          {/*    //   <p className="slider-desc-new">{p}</p>*/}
          {/*    //   <div className="slider-image-block-new">*/}
          {/*    //     <img*/}
          {/*    //       src={`https://innowise.com/wp-content/uploads/2024/09/image-19.09-${*/}
          {/*    //         i + 1*/}
          {/*    //       }.jpg`}*/}
          {/*    //     />*/}
          {/*    //   </div>*/}
          {/*    // </div>*/}

          {/*    <div*/}
          {/*      className="mobile-domains-navitem-new"*/}
          {/*      data-mobile-domain={`domain-${i + 1}`}*/}
          {/*    >*/}
          {/*      <span>{h}</span>*/}
          {/*      <p className="slider-desc-new mobile-domain-list-new">*/}
          {/*        {p}*/}
          {/*        <img*/}
          {/*          src={`https://innowise.com/wp-content/uploads/2024/09/image-19.09-${*/}
          {/*            i + 1*/}
          {/*          }.jpg`}*/}
          {/*        />*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  );*/}
          {/*})}*/}

          <button tabIndex="-1" onClick={handleClick.bind(null, updateInfo)}>
            Обновить админ. инфо
          </button>
          <div>
            <button tabIndex="-1" onClick={handleClick.bind(null, limitChange)}>
              Повысить лимит
            </button>
            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, updateLimits)}
            >
              Сброс лимитов
            </button>
          </div>

          <div>
            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, raidDamageClear)}
            >
              Рейд урон очистить
            </button>

            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, raidBossClose)}
            >
              Рейд босс награды
            </button>
            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, raidBossClear)}
            >
              Рейд босс очистить
            </button>
          </div>

          <div>
            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, tournamentClose)}
            >
              Турнир награды
            </button>
            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, tournamentClear)}
            >
              Турнир очистить
            </button>
          </div>

          <div>
            <button tabIndex="-1" onClick={handleClick.bind(null, removeStock)}>
              Очистить акцию банк
            </button>
            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, removeDamage)}
            >
              Очистить акцию урон
            </button>
            <button tabIndex="-1" onClick={handleClick.bind(null, removeBox)}>
              Очистить акцию коробки
            </button>
          </div>

          <div>
            <button tabIndex="-1" onClick={handleClick.bind(null, stockClear)}>
              Очистить промку
            </button>
            <button
              tabIndex="-1"
              onClick={handleClick.bind(null, stockClearDonate)}
            >
              Очистить промку донат
            </button>
          </div>

          <div>
            <button tabIndex="-1" onClick={handleClick.bind(null, shytClear)}>
              Шут очистить
            </button>
          </div>
          {/*<button tabIndex="-1" onClick={handleClick.bind(null, updateTalents)}>*/}
          {/*  Сброс талантов*/}
          {/*</button>*/}
          {/*<button tabIndex="-1" onClick={handleClick.bind(null, updateMagic)}>*/}
          {/*  Сброс заклинаний*/}
          {/*</button>*/}
        </S.Content>
      </Popup>
      <Popup isOpen={func.func} w={281} h={152} back={frame1}>
        <b>Вы уверены?</b>
        <button
          tabIndex="-1"
          onClick={() => {
            func.func();
            setFunc({});
          }}
        >
          Да
        </button>
        <button
          tabIndex="-1"
          onClick={() => {
            setFunc({});
          }}
        >
          Нет
        </button>
      </Popup>
    </S.Wrapper>
  );
}

export default React.memo(AdminPanel);
