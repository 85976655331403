import React, { useCallback, useEffect, useRef, useState } from "react";

import * as S from "./Arena.styles";
import Popup from "../../components/Popup/Popup";
import { getMultipleRandom, secondsToDhm, secondsToHm } from "../../helpers";
import ArenaHistory from "../../components/ArenaHistory/ArenaHistory";
import { CentredWrapper, Close } from "../../App.styles";
import SumCharacter from "../../components/SumCharacter/SumCharacter";
import { grindData } from "../../info/data";
import { ButtonValue } from "../../components/PrivateInfo/PrivateInfo.style";
import { TopPart } from "../../components/BossInterface/BossInterface.styles";
import { ButtonsWrapper } from "../../components/ActiveBoss/ActiveBoss.styles";
import { MenuRight, MenuElemRight } from "../Home/Home.styles";
import helperIcon from "../../img/menuIcons/Helper.png";
import frame6 from "../../img/Frame6.png";
import { createPortal } from "react-dom";
import imgTop from "../../img/boss/TopPart.png";
import { TopButton } from "../Tops/Tops.styles";
import ArenaFight from "../../components/ArenaFight/ArenaFight";
import ArenaInfo from "../../components/ArenaInfo/ArenaInfo";

function Arena({
  user,
  allUsers,
  app,
  setUser,
  setIsFetching,
  isFetching,
  isModalOpen,
  setIsModalOpen,
  serverTime,
  setDisableMove,
  changeOptionsLocation,
  setModalError,
  getCharacterSum,
  moveToUserRoom,
  bridge,
}) {
  const [maxHourFight, setMaxHourFight] = useState(
    user.arena.maxHourFight *
      ((user.helpers?.arena || 0) >= serverTime ? 24 : 1)
  );
  const [helperOpen, setHelperOpen] = useState(false);
  const [timeHelp, setTimeHelp] = useState(null);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chooseUsers, setChooseUsers] = useState([]);
  const [usersInfo, setUsersInfo] = useState([]);
  const [timeToNext, setTimeToNext] = useState("00ч:00м");
  const intervalRef = useRef(null);
  const maxCharac = useRef(100);
  const [maxValue, setMaxValue] = useState(maxCharac.current);
  const [minValue, setMinValue] = useState(1);
  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    setDomReady(true);
    changeOptionsLocation("arena");

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setMaxHourFight(
      user.arena.maxHourFight *
        ((user.helpers?.arena || 0) >= serverTime ? 24 : 1)
    );

    const mm = (59 - new Date(serverTime).getMinutes()) * 60;
    const ss = 60 - new Date(serverTime).getSeconds();

    if ((user.helpers?.arena || 0) >= serverTime) {
      const d1 = new Date(serverTime);
      const offsetOld = d1.getTimezoneOffset();
      const offsetMoscow = -180;
      const d2 = new Date(
        serverTime + offsetOld * 60 * 1000 - offsetMoscow * 60 * 1000
      );

      const d3 = new Date(
        d2.getFullYear(),
        d2.getMonth(),
        d2.getDate(),
        23,
        59,
        59
      );

      setTimeToNext(secondsToHm((d3.getTime() - d2.getTime()) / 1000));

      setTimeHelp(
        secondsToDhm(((user.helpers?.arena || 0) - serverTime) / 1000)
      );
    } else {
      setTimeToNext(secondsToHm(mm + ss));
      setTimeHelp(null);
    }
  }, [serverTime, user]);

  useEffect(() => {
    setFilter();
  }, [allUsers]);

  function changeHelperOpen(open) {
    setHelperOpen(open);
  }

  function buyHelper({ days, price }) {
    setIsFetching(true);
    setIsLoading(true);

    bridge
      .send("VKWebAppShowOrderBox", {
        type: "item",
        item: JSON.stringify({
          helpersArena: days,
          g: price,
        }),
      })
      .then((data) => {
        if (data.success) {
          console.log("успех", data);
        }
        setIsLoading(false);
        setIsFetching(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsFetching(false);
      });
  }

  const setFilter = useCallback(() => {
    if (allUsers.length > 0) {
      maxCharac.current = allUsers.sort(
        (a, b) => b.allCharac - a.allCharac
      )[0].allCharac;
      if (user.arenaFilter.length) {
        changeRange(user.arenaFilter[0], user.arenaFilter[1]);
        setMinValue(user.arenaFilter[0]);
        setMaxValue(user.arenaFilter[1]);
      } else {
        changeRange(1, maxCharac.current);
        setMinValue(1);
        setMaxValue(maxCharac.current);
      }
    }
  }, [user]);

  useEffect(() => {
    setChooseUsers(getMultipleRandom(usersInfo, 3));
  }, [usersInfo]);

  function setPlayerInfo(obj) {
    const scales = {};
    const characteristic = obj.characteristic;
    const grindValue = grindData["book"].bonus[obj.grind["book"] - 1] / 100;
    obj.damageTalents.characteristicTalents.forEach((elem) => {
      scales[elem.property] =
        1 + grindValue + Math.round(elem.step * elem.countDone * 100) / 10000;
    });

    const player = {
      hp: Math.floor(
        Object.values(characteristic.health).reduce((acc, cur, i) => {
          if (i < 2) {
            return acc + cur * 5 * scales.health;
          }
          return acc + cur * 5;
        }, 0)
      ),
    };
    Object.entries(characteristic).forEach((obj) => {
      player[obj[0]] = Math.floor(
        Object.values(obj[1]).reduce((acc, cur, i) => {
          if (i < 2) {
            return acc + cur * scales[obj[0]];
          }
          return acc + cur;
        }, 0)
      );
    });
    return player;
  }

  function startFight(character) {
    if (
      (timeHelp && user.arena.countDayFight >= maxHourFight) ||
      user.arena.countHourFight >= maxHourFight
    ) {
      setModalError("Вы достигли лимита дуэлей");
      console.log("Вы достигли лимита дуэлей");
    } else {
      setIsFetching(true);
      setIsLoading(true);
      setDisableMove(true);
      const me = setPlayerInfo(user);
      const enemy = setPlayerInfo(character);

      const meSum = getCharacterSum(user);
      const enemySum = getCharacterSum(character);

      if (me.hp && enemy.hp) {
        app
          .service("users")
          .patch(
            user._id,
            {
              "arena.isOver": false,
              "arena.me": {
                ...me,
                fullHp: me.hp,
                lvl: user.lvl,
                sum: meSum,
                name: user.name,
                nickName: user.nickName,
                img: user.photo,
                id: user._id,
                faculty: user.faculty,
                orden: user.orden,
              },
              "arena.enemy": {
                ...enemy,
                fullHp: enemy.hp,
                lvl: character.lvl,
                sum: enemySum,
                name: character.name,
                nickName: character.nickName,
                img: character.photo,
                id: character._id,
                orden: character.orden,
                faculty: character.faculty,
              },
              common: true,
            },
            {
              query: {
                $select: ["_id", "email", "arena"],
              },
            }
          )
          .then((data) => {
            setUser((prev) => ({ ...prev, ...data }));
            setIsFetching(false);
            setIsLoading(false);
          })
          .catch((e) => {
            setModalError(e);
            console.log(e);
            setIsLoading(false);
            setIsFetching(false);
            setDisableMove(false);
          });
      }
    }
  }

  const swapOpponents = useCallback(() => {
    setChooseUsers(getMultipleRandom(usersInfo, 3));
  }, [usersInfo]);

  useEffect(() => {
    changeRange(minValue, maxValue);
  }, [minValue, maxValue]);

  const handleInputMin = (e) => {
    let value = e.target.value;
    if (!value) {
      setMinValue("");
    } else {
      if (value >= maxValue) {
        value = maxValue - 1;
      }
      if (value < 1) {
        value = 1;
      }
      setMinValue(value ? Math.abs(+Math.floor(value)) : "");
    }
  };

  const handleInputMax = (e) => {
    let value = e.target.value;

    if (!value) {
      setMaxValue("");
    } else {
      if (value >= maxCharac.current) {
        value = maxCharac.current;
      }
      if (value <= 1) {
        value = 1;
      }
      setMaxValue(value ? Math.abs(+Math.floor(value)) : "");
    }
  };

  function changeRange(min, max) {
    if (allUsers.length > 0) {
      setUsersInfo(
        allUsers.filter((el) => {
          if (
            el.email !== user.email &&
            el.allCharac >= min &&
            el.allCharac <= max
          ) {
            return el;
          }
        })
      );
    }
  }

  function saveFilter(minValue, maxValue) {
    if (minValue !== user.arenaFilter[0] || maxValue !== user.arenaFilter[1]) {
      setIsFetching(true);
      setIsLoading(true);
      app
        .service("users")
        .patch(
          user._id,
          {
            arenaFilter: [minValue, maxValue],
            field: serverTime,
          },
          {
            query: {
              $select: ["_id", "email", "arenaFilter"],
            },
          }
        )
        .then((data) => {
          setUser((prev) => ({ ...prev, ...data }));
          setIsFetching(false);
          setIsLoading(false);
        })
        .catch((e) => {
          setModalError(e);
          console.log(e);
          setIsFetching(false);
          setIsLoading(false);
        });
    }
  }

  return (
    <React.Fragment>
      <TopPart>
        <img src={imgTop} alt="интерфейс" />
      </TopPart>

      <CentredWrapper>
        <S.ArenaWrapper>
          {user.arena.isOver && isHistoryOpen ? (
            <>
              <ArenaHistory
                moveToUserRoom={moveToUserRoom}
                allUsers={allUsers}
                id={user._id}
                history={user.arenaHistory}
                setIsOpen={setIsHistoryOpen}
              />

              <TopButton>
                <S.Button
                  width={130}
                  onClick={
                    !isLoading &&
                    !isFetching &&
                    !isModalOpen &&
                    chooseUsers.length > 0
                      ? () => {
                          setIsHistoryOpen(false);
                        }
                      : null
                  }
                >
                  <div>Личная карточка</div>
                </S.Button>
              </TopButton>
            </>
          ) : user.arena.isOver && isInfoOpen ? (
            <>
              <ArenaInfo
                user={user}
                isFetching={isFetching}
                isModalOpen={isModalOpen}
                getCharacterSum={getCharacterSum}
                isLoading={isLoading}
                setIsInfoOpen={setIsInfoOpen}
                chooseUsers={chooseUsers}
                setIsHistoryOpen={setIsHistoryOpen}
              />
              <TopButton>
                <S.Button
                  width={130}
                  onClick={
                    !isLoading &&
                    !isFetching &&
                    !isModalOpen &&
                    chooseUsers.length > 0
                      ? () => {
                          setIsInfoOpen(false);
                        }
                      : null
                  }
                >
                  <div>Назад к дуэлям</div>
                </S.Button>
              </TopButton>
            </>
          ) : user.arena.isOver && !isHistoryOpen && !isInfoOpen ? (
            <>
              <S.FastInfo>
                <div>
                  <span>Мои характеристики</span>
                  <S.ButtonValue width={140}>
                    <div>
                      <SumCharacter getCharacterSum={getCharacterSum} />
                    </div>
                  </S.ButtonValue>
                </div>

                <div>
                  <span>Лимит дуэлей</span>
                  <S.ButtonValue width={140}>
                    <div>
                      {timeHelp
                        ? user.arena.countDayFight
                        : user.arena.countHourFight}
                      /{maxHourFight}
                    </div>
                  </S.ButtonValue>
                </div>

                <div>
                  <span>Сброс лимита</span>
                  <S.ButtonValue width={140}>
                    <div>{timeToNext}</div>
                  </S.ButtonValue>
                </div>
              </S.FastInfo>

              <S.OpponentsWrapper>
                {chooseUsers.length > 0 &&
                  chooseUsers.map((enemy, i) => {
                    return (
                      <S.Opponent key={enemy._id} faculty={enemy.faculty}>
                        <div>
                          <S.Lvl>
                            <span>{enemy.lvl} уровень</span>
                          </S.Lvl>

                          <S.Avatar>
                            <img
                              src={enemy.photo}
                              width={100}
                              height={100}
                              alt={"аватар"}
                            />
                          </S.Avatar>

                          <S.Name>
                            <span>
                              {enemy.orden?.tag ? `[${enemy.orden?.tag}] ` : ""}
                            </span>
                            <span>
                              {enemy.nickName
                                ? enemy.nickName
                                : `${enemy.name?.firstName} ${enemy.name?.lastName}`}
                            </span>
                          </S.Name>

                          <S.CharactersInfo>
                            <S.ButtonValue width={110}>
                              <div>
                                <SumCharacter
                                  isRound={true}
                                  user={enemy}
                                  getCharacterSum={getCharacterSum}
                                />
                              </div>
                            </S.ButtonValue>
                          </S.CharactersInfo>

                          <S.Attack>
                            <S.Button
                              width={110}
                              disabled={isLoading || isFetching || isModalOpen}
                              onClick={
                                !isLoading && !isFetching && !isModalOpen
                                  ? startFight.bind(null, enemy)
                                  : null
                              }
                            >
                              <div>Напасть</div>
                            </S.Button>
                          </S.Attack>
                        </div>
                      </S.Opponent>
                    );
                  })}
              </S.OpponentsWrapper>

              <S.FilterString>
                <div>
                  <S.FiltersCharac>
                    <span>Фильтр: от</span>

                    <S.ButtonValue width={160}>
                      <div>
                        <input
                          value={minValue}
                          onChange={handleInputMin}
                          type="number"
                          max={maxValue - 1}
                          min={1}
                          placeholder={""}
                          step={1}
                        />
                      </div>
                    </S.ButtonValue>

                    <span>до</span>

                    <S.ButtonValue width={160}>
                      <div>
                        <input
                          value={maxValue}
                          onChange={handleInputMax}
                          type="number"
                          max={maxCharac.current}
                          min={1}
                          placeholder={""}
                          step={1}
                        />
                      </div>
                    </S.ButtonValue>
                  </S.FiltersCharac>

                  <ButtonsWrapper>
                    <S.Button
                      width={110}
                      onClick={
                        !isLoading &&
                        !isFetching &&
                        !isModalOpen &&
                        chooseUsers.length > 0
                          ? saveFilter.bind(null, minValue, maxValue)
                          : null
                      }
                    >
                      <div>Сохранить</div>
                    </S.Button>

                    <S.Button
                      width={110}
                      onClick={
                        !isLoading &&
                        !isFetching &&
                        !isModalOpen &&
                        chooseUsers.length > 0
                          ? swapOpponents
                          : null
                      }
                    >
                      <div>Обновить</div>
                    </S.Button>
                  </ButtonsWrapper>
                </div>
              </S.FilterString>

              <TopButton>
                <S.Button
                  width={130}
                  onClick={
                    !isLoading &&
                    !isFetching &&
                    !isModalOpen &&
                    chooseUsers.length > 0
                      ? () => {
                          setIsInfoOpen(true);
                        }
                      : null
                  }
                >
                  <div>Моя карточка</div>
                </S.Button>
              </TopButton>
            </>
          ) : (
            <ArenaFight
              user={user}
              app={app}
              setUser={setUser}
              isFetching={isFetching}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              serverTime={serverTime}
              setModalError={setModalError}
              isLoading={isLoading}
              setDisableMove={setDisableMove}
              setIsFetching={setIsFetching}
              setIsLoading={setIsLoading}
              swapOpponents={swapOpponents}
            />
          )}
        </S.ArenaWrapper>
      </CentredWrapper>
      {domReady
        ? createPortal(
            user.arena.isOver && (
              <MenuRight>
                <MenuElemRight name={"Резервация"}>
                  <img
                    width={80}
                    height={80}
                    src={helperIcon}
                    alt={"резервация"}
                  />
                  <div onClick={changeHelperOpen.bind(null, true)} />
                </MenuElemRight>
              </MenuRight>
            ),
            document.querySelector("#menuRight")
          )
        : null}
      <Popup
        isOpen={helperOpen}
        setIsOpen={setHelperOpen}
        w={436}
        h={320}
        back={frame6}
      >
        <Close onClick={changeHelperOpen.bind(null, false)} />
        <b>Резервация Дуэльной комнаты</b>
        <span>Доступны сразу все лимиты (240)</span>
        <span>Лимиты сбрасываются в конце игрового дня</span>
        {timeHelp && (
          <>
            <span>Оставшееся время резервации:</span>
            <span>{timeHelp}</span>
          </>
        )}
        <S.BottomPrice>
          {timeHelp ? (
            <span>Желаете продлить резервацию?</span>
          ) : (
            <span>Доступные варианты для резервации:</span>
          )}
          <S.BottomPrices>
            <div>
              <span>3 суток</span>
              <S.Button
                width={100}
                onClick={buyHelper.bind(null, { days: 3, price: 10 })}
              >
                <div>10 голосов</div>
              </S.Button>
            </div>
            <div>
              <span>7 суток</span>
              <S.Button
                width={100}
                onClick={buyHelper.bind(null, { days: 7, price: 15 })}
              >
                <div>15 голосов</div>
              </S.Button>
            </div>
            <div>
              <span>30 суток</span>
              <S.Button
                width={100}
                onClick={buyHelper.bind(null, { days: 30, price: 50 })}
              >
                <div>50 голосов</div>
              </S.Button>
            </div>
          </S.BottomPrices>
        </S.BottomPrice>
      </Popup>
    </React.Fragment>
  );
}

export default React.memo(Arena);
