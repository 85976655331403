import React, { useEffect, useState } from "react";

import * as S from "./Map.styles";

import backImg from "../../img/MapBack.png";
import { Close } from "../../App.styles";
import { raidBossesInfo, shytArray } from "../../info/data";

const locations = [
  {
    name: "Замок",
    floors: {
      "Второй этаж замка": [
        {
          location: "potionRoom",
          name: "Зельеварение",
          string: "Посещение уроков",
        },
        {
          location: "grassRoom",
          name: "Растениеводство",
          string: "Посещение уроков",
        },
      ],
      "Первый этаж замка": [
        {
          location: "potionLab",
          name: "Лаборатория",
          string: "Изготовление зелий",
        },
        {
          location: "greenHouse",
          name: "Теплица",
          string: "Выращивание растений",
        },
      ],
      "Жилые подземелья замка": [
        {
          location: "bosses",
          name: "Бестиарий",
          string: "Сражения с Боссами",
        },
        {
          location: "soloBoss",
          name: "Боссы на локации",
          string: "Сражения с Боссами",
        },
        {
          location: "arena",
          name: "Дуэльная комната",
          string: "Дуэли с игроками",
        },
        {
          location: "gameRoom",
          name: "Игровая комната",
          string: "Азартные игры",
        },
      ],
    },
  },
];

const tournaments = {
  diceGameTournament: "Игра в кости",
  game21Tournament: "Игра в '21'",
};

function Map({
  isOpen,
  setIsOpen,
  isFetching,
  setActivePanel,
  changeActiveMenuModal,
  activePanel,
  setModalError,
  disableMove,
  serverTime,
  setUser,
  app,
  admin,
  _id,
  lvl,
  soloBossNumber,
  activeBoss,
  raidBoss,
}) {
  const [isRaidBoss, setIsRaidBoss] = useState(false);
  const [isShyt, setIsShyt] = useState(false);
  const [activeTournament, setActiveTournament] = useState(false);

  useEffect(() => {
    setIsRaidBoss(
      !raidBoss.isEnd &&
        admin.raidBoss.isActive > -1 &&
        serverTime > admin.raidBoss.endTime - admin.raidBoss.maxTime * 3600000
    );

    setIsShyt(
      (serverTime > admin.shyt.startTime &&
        serverTime < admin.shyt.startTime + admin.shyt.time * 3600000) ||
        (activeBoss.number > -1 && activeBoss.isShyt)
    );

    if (
      serverTime <
        admin.diceGameTournament.startTime +
          admin.diceGameTournament.time * 3600000 &&
      serverTime > admin.diceGameTournament.startTime
    ) {
      setActiveTournament("diceGameTournament");
    } else if (
      serverTime <
        admin.game21Tournament.startTime +
          admin.game21Tournament.time * 3600000 &&
      serverTime > admin.game21Tournament.startTime
    ) {
      setActiveTournament("game21Tournament");
    } else {
      setActiveTournament("");
    }
  }, [serverTime, admin]);

  function handleClose() {
    setIsOpen("");
  }

  function moveLocation(name) {
    if (activePanel !== name) {
      changeActiveMenuModal();
      setActivePanel(name);
      handleClose();
    } else {
      setModalError("Вы уже находитесь в этой локации");
    }
  }

  return (
    <S.Wrapper isOpen={isOpen} onClick={handleClose}>
      <S.Content onClick={(e) => e.stopPropagation()} back={backImg}>
        {isOpen && (
          <div>
            <S.RightWrapper>
              <S.LocationsWrapper>
                {(isShyt || isRaidBoss || activeTournament) && (
                  <>
                    <S.Floor>
                      <span>Активные события</span>
                    </S.Floor>
                    {isRaidBoss && (
                      <S.Location
                        important={true}
                        onClick={
                          !isFetching && !disableMove
                            ? moveLocation.bind(null, "raidBoss")
                            : null
                        }
                      >
                        <span>Рейдовый Босс</span>
                        <span>
                          {raidBossesInfo[admin.raidBoss.isActive].name}
                        </span>
                      </S.Location>
                    )}

                    {isShyt && (
                      <S.Location
                        important={true}
                        onClick={
                          !isFetching && !disableMove
                            ? moveLocation.bind(null, "shyt")
                            : null
                        }
                      >
                        <span>Ивентовый Босс</span>
                        <span>{shytArray[0][0].name}</span>
                      </S.Location>
                    )}

                    {activeTournament && (
                      <S.Location
                        important={true}
                        onClick={
                          !isFetching && !disableMove
                            ? moveLocation.bind(null, "gameRoom")
                            : null
                        }
                      >
                        <span>Турнир</span>
                        <span>{tournaments[activeTournament]}</span>
                      </S.Location>
                    )}
                  </>
                )}

                {Object.entries(locations[0].floors).map((floor, i) => {
                  return (
                    <React.Fragment key={i}>
                      <S.Floor>
                        <span>{floor[0]}</span>
                      </S.Floor>

                      {floor[1].map((elem, i) => {
                        return (
                          <S.Location
                            key={i}
                            onClick={
                              !isFetching && !disableMove
                                ? moveLocation.bind(
                                    null,
                                    elem.location,
                                    elem?.number
                                  )
                                : null
                            }
                          >
                            <span>{elem.name}</span>
                            <span>{elem.string}</span>
                          </S.Location>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </S.LocationsWrapper>
            </S.RightWrapper>
            <Close onClick={handleClose} />
          </div>
        )}
      </S.Content>
    </S.Wrapper>
  );
}

export default React.memo(Map);
