import styled from "styled-components";

import imgSliz from "../../img/arena/Sliz.png";
import imgGriff from "../../img/arena/Griff.png";
import imgPuff from "../../img/arena/Puff.png";
import imgKogt from "../../img/arena/Kogt.png";
import imgAvatar from "../../img/FriendsFrame.png";
import buttonPart from "../../img/boss/ButtonPart.png";

import infoLeft from "../../img/arena/InfoLeft.png";
import infoRight from "../../img/arena/InfoRight.png";
import imgMarker2 from "../../img/Marker2Small.png";
import imgMarker5 from "../../img/Marker5Small.png";
import buttonConnect from "../../img/ButtonConnect.png";

export const LogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  overflow-y: hidden;
  max-height: 332px;

  & > div {
    text-shadow: 0 0 4px #000000;
    font-weight: 600;
  }

  & > div {
    text-shadow: 0 0 4px #000000;
    font-weight: 600;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
`;

export const Rewards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const FightWrapper = styled.div`
  width: 100%;
  height: 370px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  & > div > div {
    margin-top: 71px;
    height: 332px;
  }
`;

export const Opponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px;

  background-image: url("${(props) =>
    props.faculty === "slytherin"
      ? imgSliz
      : props.faculty === "ravenclaw"
      ? imgKogt
      : props.faculty === "hufflepuff"
      ? imgPuff
      : imgGriff}");
  background-size: contain;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 3px;

  & > img {
    border-radius: 10px;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("${imgAvatar}");
    background-size: contain;
  }
`;

export const Name = styled.div`
  width: 180px;
  position: absolute;
  bottom: 150px;
  transform: translateY(50%);

  display: flex;
  gap: 2px;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #f7b471;
  text-shadow: 0 0 #281707;
  font-weight: 600;
  line-height: 20px;
`;

export const Lvl = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #281707;
  font-weight: 600;
  text-shadow: 0 0 #281707;
  margin-bottom: 5px;
`;

export const HealthInfo = styled.div`
  position: absolute;
  bottom: 25px;
  color: #281707;
  font-weight: 600;
  text-shadow: 0 0 #281707;

  & > div:nth-child(1) {
    font-size: 13px;
    display: flex;
    justify-content: center;
    gap: 2px;
  }
`;

export const CharactersInfo = styled.div`
  position: absolute;
  bottom: 95px;
`;

export const ButtonValue = styled.div`
  bottom: 0;
  position: relative;
  min-width: ${(props) => props.width && `${props.width}px`};
  background-color: rgba(0, 0, 0, 0.3);
  &::before {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%);
  }
  &::after {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    right: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%) rotateZ(180deg);
  }

  border-radius: 10px;
  box-shadow: 0px 0.5px 0px 1px #281707;

  & > div {
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 10px;
    padding: 2px 8px 2px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    text-align: center;
    width: 100%;
  }
`;

export const Button = styled.div`
  min-width: ${(props) => props.width && `${props.width}px`};
  cursor: ${(props) => (!props.disabled ? "pointer" : "default")};
  position: relative;
  transition: filter 0.15s linear;
  backface-visibility: hidden;
  will-change: transform;
  &::before {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%);
  }
  &::after {
    aspect-ratio: 18 / 50;
    height: 130%;
    position: absolute;
    right: 0;
    top: 50%;
    content: "";
    background-image: url(${buttonPart});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(0%, -50%) rotateZ(180deg);
  }
  &:hover {
    filter: ${(props) => !props.disabled && "brightness(1.2)"};
  }
  &:active {
    filter: ${(props) => !props.disabled && "brightness(0.9)"};
  }
  filter: ${(props) => props.isDone && "brightness(0.7)"};
  border-radius: 36px;
  box-shadow: 0px 0.5px 0px 1px #281707;
  background: ${(props) =>
    props.isActive
      ? `linear-gradient(0deg, rgba(255, 223, 175, 0.2), rgba(255, 223, 175, 0.2)), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
      : props.disabled
      ? `linear-gradient(0deg, #301E0D, #301E0D), linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4E2700 100%), linear-gradient(180deg, rgba(255, 217, 159, 0.1) 0%, rgba(255, 223, 176, 0) 42.19%), #6B3700;
`
      : `linear-gradient(180deg, rgba(78, 39, 0, 0) 0%, #4e2700 100%),
linear-gradient(
180deg,
rgba(255, 217, 159, 0.1) 0%,
rgba(255, 223, 176, 0) 42.19%
),
#6b3700`};
  & > div {
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 36px;
    padding: 2px 10px;
    color: #fcc382;
    font-family: "Bellota-Regular";
    font-size: 12px;
    line-height: 1.5;
  }
`;

export const ButtonPercent = styled.div`
  margin-top: 2px;
  position: relative;
  width: 100px;
  &::after {
    width: 12px;
    height: 8px;
    transform: translate(50%, -50%);
    position: absolute;
    right: 1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::before {
    z-index: 1;
    width: 12px;
    height: 8px;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 1px;
    top: 50%;
    content: "";
    background-image: url(${buttonConnect});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  border-radius: 10px;
  box-shadow: 0px 0.5px 0px 1px #281707;

  & > div {
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 0px 1px #281707 inset;
    border: 2px solid #e98101;
    border-radius: 10px;
    height: 16px;
    overflow: hidden;
    & > div {
      display: flex;
      width: 100%;
      height: 100%;
      box-shadow: inset 0px 0px 12px black;
      clip-path: polygon(
        0% 0%,
        ${(props) => props.value}%0%,
        ${(props) => props.value}% 50%,
        ${(props) => props.value}% 100%,
        0% 100%
      );
      transition: all 0.5s ease-in-out;

      background: ${(props) =>
        props.isMe
          ? `linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 75.98%,
          rgba(0, 0, 0, 0.2) 100%
        ),
        linear-gradient(
          180deg,
          rgba(255, 234, 234, 0) 19.47%,
          rgba(255, 234, 234, 0.2) 34.88%,
          rgba(255, 234, 234, 0) 60.57%
        ),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0) 42.19%
        ),
        #acae4f;`
          : `linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 75.98%,
          rgba(0, 0, 0, 0.2) 100%
        ),
        linear-gradient(
          180deg,
          rgba(255, 234, 234, 0) 19.47%,
          rgba(255, 234, 234, 0.2) 34.88%,
          rgba(255, 234, 234, 0) 60.57%
        ),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0) 42.19%
        ),
        #ea3e27;`};
    }
  }
`;
