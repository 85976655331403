import imgForce from "../img/skills/Force.png";
import imgForceAccuracy from "../img/skills/ForceAccuracy.png";
import imgPowerForce from "../img/skills/PowerForce.png";

export const tournamentPrizes = [
  {
    gold: 2500,
    clothes: {
      count: 1,
      event: true,
    },
  },
  {
    gold: 2500,
    clothes: {
      count: 2,
      event: false,
    },
  },
  {
    gold: 2500,
    clothes: {
      count: 1,
      event: false,
    },
  },
  {
    gold: 2500,
  },
  {
    gold: 2250,
  },
  {
    gold: 2000,
  },
  {
    gold: 1750,
  },
  {
    gold: 1500,
  },
  {
    gold: 1250,
  },
  {
    gold: 1000,
  },
];

export const newYearPrize = {
  gold: {
    count: 2024,
  },
  game21: {
    count: 100,
  },
  diceGame: {
    count: 200,
  },
};

export const newQuestPrizes = [
  {
    price: 10,
    gold: 500,
    silver: 500000,
    essence: 100000,
  },
  {
    price: 20,
    gold: 1200,
    silver: 1200000,
    essence: 240000,
  },
  {
    price: 50,
    gold: 4000,
    silver: 4000000,
    essence: 800000,
  },
];

export const newSummerSavePrizes = [
  {
    price: 15,
    gold: 300,
    silver: 300000,
    mana: 15000,
  },
  {
    price: 35,
    gold: 1000,
    silver: 1000000,
    mana: 50000,
  },
  {
    price: 65,
    gold: 2000,
    silver: 2000000,
    mana: 100000,
  },
];

export const newSummerSaveClothes = [
  {
    category: "shoes",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "pants",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "hand",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "shirt",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "head",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "broom",
    name: "Event1",
    lvl: 0,
  },
  {
    category: "wand",
    name: "Event1",
    lvl: 0,
  },
];

export const newQuestArray = [
  {
    property: "arenaWins",
    count: 3,
    reward: {
      property: "essence",
      count: 300,
    },
    place: "arena",
    string: "Победи волшебника в дуэли.",
  },
  {
    property: "addCharac",
    count: 1,
    reward: {
      property: "potion",
      count: 2,
      potionName: "+100%",
      category: "energy",
      value: 100,
      percent: true,
    },
    place: "book",
    string: "Увеличь любую из своих характеристик.",
  },
  {
    property: "classroom1",
    count: 1,
    reward: {
      property: "mana",
      count: 100,
    },
    place: "potionRoom",
    string: "Пройди урок зельеварения.",
  },
  {
    property: "classroom2",
    count: 1,
    reward: {
      property: "mana",
      count: 100,
    },
    place: "grassRoom",
    string: "Пройди урок растениеводства.",
  },
  {
    property: "bossesWins",
    count: 3,
    reward: {
      property: "magic",
      count: 2,
    },
    place: "bosses",
    string: `Одолей босса "Пикси"`,
  },
  {
    property: "potions",
    count: 3,
    reward: {
      property: "diceGame",
      count: 5,
    },
    place: "potionLab",
    string: "Начни приготовление зелий.",
  },
  {
    property: "grasses",
    count: 10,
    reward: {
      property: "game21",
      count: 5,
    },
    place: "greenHouse",
    string: "Засади всю теплицу растениями.",
  },
  {
    property: "diceGame",
    count: 5,
    reward: {
      property: "silver",
      count: 10000,
    },
    place: "gameRoom",
    string: `Сыграй в игру "кости"`,
  },
  {
    property: "game21",
    count: 5,
    reward: {
      property: "gold",
      count: 10,
    },
    place: "gameRoom",
    string: `Сыграй в игру "21"`,
  },
];

export const newQuestEndPrizes = [
  { property: "gold", count: 50 },
  { property: "silver", count: 50000 },
  { property: "magic", count: 10 },
  { property: "soul", number: 1, count: 20 },
  { property: "diceGame", count: 20 },
  { property: "game21", count: 20 },
];

export const boxPrice = [10, 20, 50];

export const boxPrizes = [
  [
    { property: "soul", number: 3, count: 30 }, //600
    { property: "soul", number: 4, count: 30 }, //1200
    { property: "diceGame", count: 100 }, // 100
    { property: "game21", count: 50 }, // 100
  ],
  [
    { property: "soul", number: 3, count: 65 }, //1300
    { property: "soul", number: 4, count: 65 }, //2500
    { property: "diceGame", count: 200 }, //200
    { property: "game21", count: 100 }, //200
  ],
  [
    { property: "soul", number: 3, count: 180 }, //3600
    { property: "soul", number: 4, count: 180 }, //7200
    { property: "diceGame", count: 500 }, //500
    { property: "game21", count: 250 }, //500
  ],
];

export const bankInfo = {
  // gold: {
  //   name: "золото",
  //   prices: [8, 16, 35, 70, 105, 145],
  //   pricesSale: [8, 16, 35, 70, 105, 145, 290],
  //   count: [100, 220, 600, 1500, 2500, 4000],
  //   countSale: [120, 280, 850, 2250, 4400, 8000, 20000],
  //   percentSale: [20, 25, 35, 50, 75, 100, 150],
  // },

  // mana: {
  //   name: "ману",
  //   prices: [8, 16, 35, 70, 105, 145],
  //   pricesSale: [8, 16, 35, 70, 105, 145, 290],
  //   count: [5000, 11500, 34000, 77000, 140000, 220000],
  //   countSale: [6000, 15000, 46000, 115000, 245000, 440000, 1100000],
  // },

  // essence: {
  //   name: "эссенцию",
  //   prices: [8, 16, 35, 70, 105, 145],
  //   pricesSale: [8, 16, 35, 70, 105, 145, 290],
  //   count: [15000, 35000, 100000, 250000, 450000, 700000],
  //   countSale: [18000, 44000, 135000, 375000, 790000, 1400000, 3500000],
  //   additional: [1200, 3000, 8000, 18000, 32000, 50000],
  //   additionalSale: [1440, 3750, 10800, 27000, 56000, 100000, 250000],
  // },

  gold: {
    name: "золото",
    prices: [1, 5, 10, 20, 50, 100],
    count: [50, 300, 650, 1400, 4000, 10000],
  },

  mana: {
    name: "ману",
    prices: [1, 5, 10, 20, 50, 100],
    count: [2500, 15000, 32500, 75000, 200000, 500000],
  },

  essence: {
    name: "эссенцию",
    prices: [1, 5, 10, 20, 50, 100],
    count: [7500, 45000, 97500, 225000, 600000, 1500000],
    additional: [750, 4500, 9750, 22500, 60000, 150000],
  },
};

export const raidBossesInfo = [
  {
    classType: "entity",
    bossKritChance: 22,
    bossEvasionChance: 8,
    photo: "photo-221829804_457239196",
    name: "Тролль",
    text: [
      "Огромный и свирепый тролль напал на ученика! Именно сейчас требуется помощь каждого волшебника!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой! Каждый ученик может нанести не более 5% урона.",
    ],
    string:
      "Пугающе большое существо, издалека напоминающее груду камней. Однако вблизи - это неповоротливый, но грозный соперник, который одним ударом своего кулака сможет разнести все на пути.",
    quests: [
      {
        countAttackNeed: 100,
        rewards: {
          gold: 40,
          exp: 1000,
        },
      },
      {
        countAttackNeed: 500,
        rewards: {
          gold: 200,
          exp: 5000,
        },
      },
      {
        countAttackNeed: 2000,
        rewards: {
          gold: 800,
          exp: 20000,
        },
      },
      {
        countAttackNeed: 10000,
        rewards: {
          gold: 4000,
          exp: 100000,
        },
      },
    ],
  },
  {
    classType: "entity",
    bossKritChance: 18,
    bossEvasionChance: 12,
    photo: "photo-221829804_457239191",
    name: "Сточные крысы",
    text: [
      "Сточные крысы заполонили подземелье! Именно сейчас требуется помощь каждого волшебника!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой! Каждый ученик может нанести не более 5% урона.",
    ],
    string:
      "Группа маленьких, хитрых и выносливых грызунов, которые обитают в запущенных и практически заброшенных уголках замка. Быстро передвигаются по водосточным трубам и не всегда дружелюбны к студентам.",
    quests: [
      {
        countAttackNeed: 100,
        rewards: {
          gold: 25,
          exp: 1000,
        },
      },
      {
        countAttackNeed: 500,
        rewards: {
          gold: 125,
          exp: 5000,
        },
      },
      {
        countAttackNeed: 2000,
        rewards: {
          gold: 500,
          exp: 20000,
        },
      },
      {
        countAttackNeed: 10000,
        rewards: {
          gold: 2500,
          exp: 100000,
        },
      },
    ],
  },
  {
    classType: "man",
    bossKritChance: 15,
    bossEvasionChance: 15,
    photo: "photo-221829804_457239183",
    name: "Верховный маг холода",
    text: [
      "Школа в ледяном плену Верховного мага! Именно сейчас требуется помощь каждого волшебника!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой! Каждый ученик может нанести не более 5% урона.",
    ],
    string:
      "Владыка холода, чья власть надо льдом и снегом превращает его в воплощение  великой силы и чародейства. Он готов распрощаться с любой своей жертвой с помощью хладнокровности, присущей ему буквально.",
    quests: [
      {
        countAttackNeed: 100,
        rewards: {
          gold: 20,
          exp: 1000,
        },
      },
      {
        countAttackNeed: 500,
        rewards: {
          gold: 100,
          exp: 5000,
        },
      },
      {
        countAttackNeed: 2000,
        rewards: {
          gold: 400,
          exp: 20000,
        },
      },
      {
        countAttackNeed: 10000,
        rewards: {
          gold: 2000,
          exp: 100000,
        },
      },
    ],
  },
  {
    classType: "ghost",
    bossKritChance: 8,
    bossEvasionChance: 22,
    photo: "photo-221829804_457239227",
    name: "Банши",
    text: [
      "Школу потряс леденящий вой Банши! Именно сейчас требуется помощь каждого волшебника!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой! Каждый ученик может нанести не более 5% урона.",
    ],
    string:
      "Костлявая ведьма-привидение с длинными, до пола, чёрными волосами и зелёным лицом, вестница смерти. Обладает пронзительным, очень громким криком. Старается заставить человека кричать от ужаса, ведь при этом условии она сможет нанести ему вред.",
    quests: [
      {
        countAttackNeed: 100,
        rewards: {
          gold: 25,
          exp: 1000,
        },
      },
      {
        countAttackNeed: 500,
        rewards: {
          gold: 125,
          exp: 5000,
        },
      },
      {
        countAttackNeed: 2000,
        rewards: {
          gold: 500,
          exp: 20000,
        },
      },
      {
        countAttackNeed: 10000,
        rewards: {
          gold: 2500,
          exp: 100000,
        },
      },
    ],
  },

  {
    classType: "man",
    bossKritChance: 15,
    bossEvasionChance: 15,
    photo: "photo-221829804_457239231",
    name: "Инспектор",
    text: [
      "Генеральный инспектор перешел уже все границы, пора с этим покончить!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой! Каждый ученик может нанести не более 5% урона.",
    ],
    string:
      "Пухлая женщина в розовых одеждах с прескверным характером. Является генеральным инспектором Школы. Выдумывает жесткие законы, несогласных ужасно наказывает, вплоть до пыток в своем кабинете.",
    quests: [
      {
        countAttackNeed: 100,
        rewards: {
          gold: 20,
          exp: 1000,
        },
      },
      {
        countAttackNeed: 500,
        rewards: {
          gold: 100,
          exp: 5000,
        },
      },
      {
        countAttackNeed: 2000,
        rewards: {
          gold: 400,
          exp: 20000,
        },
      },
      {
        countAttackNeed: 10000,
        rewards: {
          gold: 2000,
          exp: 100000,
        },
      },
    ],
  },

  {
    classType: "man",
    bossKritChance: 15,
    bossEvasionChance: 15,
    photo: "photo-221829804_457239233",
    name: "Двуликий",
    text: [
      "Студенты застали профессора за жутким и неествественным занятием!",
      "Директор школы пообещал разделить щедрую награду между всеми, кто внесёт свой вклад в победу над нависшей угрозой! Каждый ученик может нанести не более 5% урона.",
    ],
    string:
      "Будучи весьма молодым, но уже способным волшебником, будущий преподаватель отправился в кругосветное путешествие, чтобы доказать всему магическому сообществу свое мастерство. Однако нечто, произошедшее там, круто изменило судьбу амбициозного мага!",
    quests: [
      {
        countAttackNeed: 100,
        rewards: {
          gold: 20,
          exp: 1000,
        },
      },
      {
        countAttackNeed: 500,
        rewards: {
          gold: 100,
          exp: 5000,
        },
      },
      {
        countAttackNeed: 2000,
        rewards: {
          gold: 400,
          exp: 20000,
        },
      },
      {
        countAttackNeed: 10000,
        rewards: {
          gold: 2000,
          exp: 100000,
        },
      },
    ],
  },
];

export const grindData = {
  owl: {
    string: "Максимальная энергия",
    percent: false,
    price: {
      type: "silver",
      count: [
        500, 1000, 1500, 2000, 4000, 5000, 6000, 7000, 8000, 10000, 12500,
        15000, 25000, 50000,
      ],
    },
    chance: [95, 90, 85, 80, 75, 70, 65, 60, 55, 45, 40, 30, 20, 10],
    bonus: [0, 3, 6, 9, 12, 18, 24, 30, 36, 42, 60, 90, 120, 180, 270],
  },
  cup: {
    string: "Эссенции в дуэлях",
    percent: true,
    price: {
      type: "gold",
      count: [10, 20, 30, 40, 50, 80, 120, 160, 200, 250, 300, 350, 400, 500],
    },
    chance: [10, 10, 10, 10, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1],
    bonus: [0, 1, 2, 3, 4, 6, 8, 10, 12, 14, 20, 30, 40, 60, 90],
  },
  book: {
    string: "Повышение характеристик",
    percent: true,
    price: {
      type: "gold",
      count: [5, 10, 15, 20, 40, 50, 60, 70, 80, 100, 125, 150, 250, 500],
    },
    chance: [80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 10],
    bonus: [0, 1, 2, 3, 4, 6, 8, 10, 12, 14, 20, 30, 40, 60, 90],
  },
};

export const stockDamage = [
  { scale: 0.1, price: 15 },
  { scale: 0.2, price: 25 },
  { scale: 0.3, price: 40 },
];

export const shopItems = [
  {
    name: "+50%",
    category: "hp",
    value: 50,
    percent: true,
    price: {
      property: "silver",
      name: "серебра",
      count: 500,
    },
    time: 0,
  },
  {
    name: "+100%",
    category: "hp",
    value: 100,
    percent: true,
    price: {
      property: "silver",
      name: "серебра",
      count: 1000,
    },
    time: 0,
  },
  {
    name: "+50%",
    category: "hp",
    value: 50,
    percent: true,
    price: {
      property: "gold",
      name: "золота",
      count: 2,
    },
    time: 0,
  },
  {
    name: "+100%",
    category: "hp",
    value: 100,
    percent: true,
    price: {
      property: "gold",
      name: "золота",
      count: 4,
    },
    time: 0,
  },
  {
    name: "+100",
    category: "energy",
    value: 100,
    percent: false,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    time: 0,
  },
  {
    name: "+200",
    category: "energy",
    value: 200,
    percent: false,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    time: 0,
  },
  {
    name: "+50%",
    category: "energy",
    value: 50,
    percent: true,
    price: {
      property: "gold",
      name: "золота",
      count: 5,
    },
    time: 0,
  },
  {
    name: "+100%",
    category: "energy",
    value: 100,
    percent: true,
    price: {
      property: "gold",
      name: "золота",
      count: 10,
    },
    time: 0,
  },
  {
    name: "+200",
    category: "mana",
    value: 200,
    percent: false,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    time: 0,
  },
  {
    name: "+200",
    category: "mana",
    value: 200,
    percent: true,
    price: {
      property: "gold",
      name: "золота",
      count: 5,
    },
    time: 0,
  },
  {
    name: "душа Полтергейста",
    category: "soul",
    value: 1,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 5,
    },
    time: 0,
  },
  {
    name: "душа Призрака Плаксы",
    category: "soul",
    value: 2,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 10,
    },
    time: 0,
  },
  {
    name: "душа Жуткого Завхоза",
    category: "soul",
    value: 3,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 20,
    },
    time: 0,
  },
  {
    name: "душа Кошмара",
    category: "soul",
    value: 4,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 40,
    },
    time: 0,
  },
  {
    name: "душа Зловещего Призрака",
    category: "soul",
    value: 5,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 80,
    },
    time: 0,
  },
  {
    name: "душа Обскура",
    category: "soul",
    value: 6,
    percent: false,
    price: {
      property: "gold",
      name: "золота",
      count: 160,
    },
    time: 0,
  },
];

export const weakDamagePrizes = [
  {
    gold: 3000,
    silver: 100000,
    mana: 30000,
  },
  {
    gold: 1500,
    silver: 500000,
    mana: 15000,
  },
  {
    gold: 800,
    silver: 300000,
    mana: 8000,
  },
  {
    gold: 500,
    silver: 180000,
    mana: 5000,
  },
  {
    gold: 300,
    silver: 150000,
    mana: 3000,
  },
  {
    gold: 200,
    silver: 120000,
    mana: 2000,
  },
  {
    gold: 100,
    silver: 90000,
    mana: 1000,
  },
  {
    gold: 80,
    silver: 60000,
    mana: 800,
  },
  {
    gold: 50,
    silver: 30000,
    mana: 500,
  },
  {
    gold: 30,
    silver: 20000,
    mana: 300,
  },
];

export const weakBossesPrizes = [
  {
    gold: 4000,
    exp: 8000,
  },
  {
    gold: 3000,
    exp: 6000,
  },
  {
    gold: 2000,
    exp: 4000,
  },
  {
    gold: 1500,
    exp: 3000,
  },
  {
    gold: 1250,
    exp: 2500,
  },
  {
    gold: 1000,
    exp: 2000,
  },
  {
    gold: 800,
    exp: 1600,
  },
  {
    gold: 600,
    exp: 1200,
  },
  {
    gold: 400,
    exp: 800,
  },
  {
    gold: 200,
    exp: 400,
  },
];

export const weakArenaPrizes = [
  {
    mana: 60000,
    exp: 3000,
  },
  {
    mana: 40000,
    exp: 2000,
  },
  {
    mana: 30000,
    exp: 1500,
  },
  {
    mana: 25000,
    exp: 1250,
  },
  {
    mana: 20000,
    exp: 1000,
  },
  {
    mana: 15000,
    exp: 750,
  },
  {
    mana: 12000,
    exp: 600,
  },
  {
    mana: 9000,
    exp: 450,
  },
  {
    mana: 6000,
    exp: 300,
  },
  {
    mana: 3000,
    exp: 150,
  },
];

export const weakGamesPrizes = [
  {
    silver: 1200000,
    exp: 3000,
  },
  {
    silver: 800000,
    exp: 2000,
  },
  {
    silver: 600000,
    exp: 1500,
  },
  {
    silver: 500000,
    exp: 1250,
  },
  {
    silver: 400000,
    exp: 1000,
  },
  {
    silver: 300000,
    exp: 750,
  },
  {
    silver: 240000,
    exp: 600,
  },
  {
    silver: 180000,
    exp: 450,
  },
  {
    silver: 120000,
    exp: 300,
  },
  {
    silver: 60000,
    exp: 150,
  },
];

export const weakClassroomsPrizes = [
  {
    essence: 300000,
    exp: 3000,
  },
  {
    essence: 200000,
    exp: 2000,
  },
  {
    essence: 150000,
    exp: 1500,
  },
  {
    essence: 125000,
    exp: 1250,
  },
  {
    essence: 100000,
    exp: 1000,
  },
  {
    essence: 75000,
    exp: 750,
  },
  {
    essence: 60000,
    exp: 600,
  },
  {
    essence: 45000,
    exp: 450,
  },
  {
    essence: 30000,
    exp: 300,
  },
  {
    essence: 15000,
    exp: 150,
  },
];

export const topsInfo = {
  bosses: {
    rewards: weakBossesPrizes,
    property: "weakBossesPoints",
    info: "weakBossesInfo",
    name: "Топ по Боссам",
    rewardName: "gold",
    strings: [
      "Очки начисляются за победу над Боссами в Бестиарии.",
      "Очки за сложность боссов: 'Пикси' - 1,  'Полтергейст'- 2, 'Призрак Плаксы'- 3 и т.д.",
      "Очки за победу в различных стадиях: 'обычный' - x1,  'агрессивный'- x2,  'проклятый'- x3.",
      "Очки в Соло режиме: 'Пикси' - x2,  'Полтергейст'- x3, 'Призрак Плаксы'- x4 и т.д.",
      "Дополнительное условие: при равенстве очков сравниваются показатели недельного урона.",
    ],
  },
  arena: {
    rewards: weakArenaPrizes,
    property: "weakEssence",
    info: "weakArenaInfo",
    name: "Топ по Арене",
    rewardName: "mana",
    strings: [
      "Очки начисляются за полученные эссенции при победе в дуэльной комнате, за каждую полученную эссенцию игрок получает 1 очко.",
      "Дополнительное условие: при равенстве очков сравнивается сумма всех побед на арене (в нападении и защите).",
    ],
  },
  games: {
    rewards: weakGamesPrizes,
    property: "weakGamesPoints",
    info: "weakGamesInfo",
    name: "Топ по Играм",
    rewardName: "silver",
    strings: [
      "Условия начисления очков в игре 'Кости': учитывается сумма на костях выигрышных комбинаций (две или более кости с одинаковым числом).",
      "Условия начисления очков в игре '21': победа с 21 очком - 25, с 20 очками - 15, с 19 очками и менее - 10, два туза - 80, пять 'картинок', 678 или 777 - 100.",
      "Дополнительное условие: при равенстве очков сравнивается сумма уровня игры в 'Кости' и количество побед в '21'.",
    ],
  },
  classrooms: {
    rewards: weakClassroomsPrizes,
    property: "weakEnergy",
    info: "weakClassroomsInfo",
    name: "Топ по Урокам",
    rewardName: "essence",
    strings: [
      "Очки начисляются за потраченную энергию при прохождении учебных классов,за каждую единицу энергии игрок получает 1 очко.",
      "Дополнительное условие: при равенстве очков сравнивается количество пройденных уроков во всех учебных классах.",
    ],
  },
};

export const refPrizesArray = [
  {
    countRef: 1,
    prize: {
      property: "gold",
      name: "золота",
      count: 10,
    },
  },
  {
    countRef: 5,
    prize: {
      property: "gold",
      name: "золота",
      count: 60,
    },
  },
  {
    countRef: 10,
    prize: {
      property: "gold",
      name: "золота",
      count: 130,
    },
  },
  {
    countRef: 25,
    prize: {
      property: "gold",
      name: "золота",
      count: 330,
    },
  },
  {
    countRef: 50,
    prize: {
      property: "gold",
      name: "золота",
      count: 700,
    },
  },
  {
    countRef: 100,
    isGet: false,
    prize: {
      property: "gold",
      name: "золота",
      count: 1500,
    },
  },
  {
    countRef: 250,
    prize: {
      property: "gold",
      name: "золота",
      count: 4000,
    },
  },
];

export const classRoomsArray = [
  {
    name: "Зельеварение",
    rewards: {
      exp: 300,
      mana: 100,
      silver: 300,
    },
    steps: [
      {
        name: "Протереть склянки",
        energy: 4,
        countNeed: 6,
        rewards: {
          exp: 4,
          mana: 2,
        },
      },
      {
        name: "Изготовить зелье",
        energy: 16,
        countNeed: 10,
        rewards: {
          exp: 16,
          mana: 8,
        },
      },
      {
        name: "Подготовить ингредиенты",
        energy: 12,
        countNeed: 8,
        rewards: {
          exp: 12,
          mana: 6,
        },
      },
      {
        name: "Изучить рецепт",
        energy: 8,
        countNeed: 6,
        rewards: {
          exp: 8,
          mana: 4,
        },
      },
    ],
  },
  {
    name: "Растениеводство",
    rewards: {
      exp: 300,
      mana: 100,
      silver: 300,
    },
    steps: [
      {
        name: "Подготовить инструменты",
        energy: 4,
        countNeed: 6,
        rewards: {
          exp: 4,
          mana: 2,
        },
      },
      {
        name: "Подготовить воду для полива",
        energy: 8,
        countNeed: 6,
        rewards: {
          exp: 8,
          mana: 4,
        },
      },
      {
        name: "Подготовить удобрения",
        energy: 12,
        countNeed: 8,
        rewards: {
          exp: 12,
          mana: 6,
        },
      },
      {
        name: "Просушить растения",
        energy: 16,
        countNeed: 10,
        rewards: {
          exp: 16,
          mana: 8,
        },
      },
    ],
  },
];

export const collectionsArray = [
  {
    name: "Зельеварение",
    rewards: {
      exp: 200,
      silver: 200,
      mana: 50,
    },
    counts: [
      {
        name: "Колба с водой",
      },
      {
        name: "Огненный порошок",
      },
      {
        name: "Котёл",
      },
      {
        name: "Перо феникса",
      },
      {
        name: "Рыбий скелет",
      },
    ],
  },
  {
    name: "Растениеводство",
    rewards: {
      exp: 200,
      silver: 200,
      mana: 50,
    },
    counts: [
      {
        name: "Лопатка",
      },
      {
        name: "Семена рассады",
      },
      {
        name: "Горшок",
      },
      {
        name: "Удобрение",
      },
      {
        name: "Лейка",
      },
    ],
  },
];

export const bossesTalents = {
  bossExpScale: {
    entity: "bossEntityExpScale",
    man: "bossManExpScale",
    ghost: "bossGhostExpScale",
  },
  bossSilverScale: {
    entity: "bossEntitySilverScale",
    man: "bossManSilverScale",
    ghost: "bossGhostSilverScale",
  },
};

export const simpleTalentsDefault = {
  maxValueEnergy: 100,
  timerEnergy: 120,
  bossEntityExpScale: 0,
  bossManExpScale: 0,
  bossGhostExpScale: 0,
  bossManSilverScale: 0,
  bossEntitySilverScale: 0,
  bossGhostSilverScale: 0,
  collectionChance: 7,
  hpSilverDiscount: 0,
  manaScale: 0,
  kritChance: 5,
  accuracyChance: 0,
  bossDamageScale: 0,
  bossDefenceScale: 0,
};

export const simpleTalents = [
  {
    property: "maxValueEnergy",
    name: "Повышение максимального значения энергии",
    string: "Увеличивает максимальное значение энергии на 50 (5 за уровень)",
    number: 0,
    need: [],
    countNeed: 0,
    countHave: 10,
    step: 5,
    percent: false,
  },
  {
    property: "timerEnergy",
    name: "Уменьшение времени на восстановление энергии",
    string:
      "Уменьшает время на восстановление энергии на 1 минуту (6 секунд за уровень)",
    number: 1,
    need: [],
    countNeed: 0,
    countHave: 10,
    step: -6,
    percent: false,
  },
  {
    property: "bossManExpScale",
    name: "Повышенный опыт за пораженных людей",
    string:
      "Повышает получаемый опыт после поединка с Боссом (человеком) на 50% (2% за уровень)",
    number: 2,
    need: [0],
    countNeed: 6,
    countHave: 25,
    step: 1,
    percent: true,
  },
  {
    property: "bossGhostExpScale",
    name: "Повышенный опыт за пораженных призраков",
    string:
      "Повышает получаемый опыт после поединка с Боссом (призраком) на 50% (2% за уровень)",
    number: 3,
    need: [0, 1],
    countNeed: 6,
    countHave: 25,
    step: 1,
    percent: true,
  },
  {
    property: "bossEntityExpScale",
    name: "Повышенный опыт за пораженных существ",
    string:
      "Повышает получаемый опыт после поединка с Боссом (существом) на 50% (2% за уровень)",
    number: 4,
    need: [1],
    countNeed: 6,
    countHave: 25,
    step: 1,
    percent: true,
  },

  {
    property: "bossManSilverScale",
    name: "Повышенное серебро за пораженных людей",
    string:
      "Повышает получаемое серебро после поединка с Боссом (человеком) на 100% (2% за уровень)",
    number: 5,
    need: [2],
    countNeed: 15,
    countHave: 50,
    step: 2,
    percent: true,
  },
  {
    property: "bossGhostSilverScale",
    name: "Повышенное серебро за пораженных призраков",
    string:
      "Повышает получаемое серебро после поединка с Боссом (призраком) на 100% (2% за уровень)",
    number: 6,
    need: [3],
    countNeed: 15,
    countHave: 50,
    step: 2,
    percent: true,
  },
  {
    property: "bossEntitySilverScale",
    name: "Повышенное серебро за пораженных существ",
    string:
      "Повышает получаемое серебро после поединка с Боссом (существом) на 100% (2% за уровень)",
    number: 7,
    need: [4],
    countNeed: 15,
    countHave: 50,
    step: 2,
    percent: true,
  },

  {
    property: "hpSilverDiscount",
    name: "Уменьшение стоимости восстановления здоровья (за серебро)",
    string:
      "Уменьшает стоимость покупки зелий лечения за серебро на 50% (1% за уровень)",
    number: 8,
    need: [5],
    countNeed: 35,
    countHave: 50,
    step: 1,
    percent: true,
  },
  {
    property: "collectionChance",
    name: "Повышает шанс выпада коллекции",
    string: "Повышает шанс выпадения любой коллекции на 15% (0.3% за уровень)",
    number: 9,
    need: [7],
    countNeed: 35,
    countHave: 50,
    step: 0.3,
    percent: true,
  },

  {
    property: "kritChance",
    name: "Увеличение шанса критического попадания в поединке с Боссом",
    string:
      "Повышает шанс критического урона на 16% (0.2% за уровень) при поединке с любым Боссом",
    number: 10,
    need: [8],
    countNeed: 35,
    countHave: 80,
    step: 0.2,
    percent: true,
  },
  {
    property: "accuracyChance",
    name: "Уменьшает шанс промахнуться заклинанием в поединке с Боссом",
    string:
      "Повышает точность заклинаний в поединке с любым Боссом на 80% (1% за уровень)",
    number: 11,
    need: [9],
    countNeed: 35,
    countHave: 80,
    step: 1,
    percent: true,
  },

  {
    property: "bossDamageScale",
    name: "Увеличение урона от заклинания в поединке с Боссом",
    string:
      "Повышает урон на 30% (0.3% за уровень) при поединке с любым Боссом",
    number: 12,
    need: [10],
    countNeed: 60,
    countHave: 100,
    step: 0.3,
    percent: true,
  },
  {
    property: "bossDefenceScale",
    name: "Снижение получаемого урона в поединке с Боссом",
    string:
      "Понижает получаемый урон в поединке с любым Боссом на 40% (0.4% за уровень)",
    number: 13,
    need: [11],
    countNeed: 60,
    countHave: 100,
    step: 0.4,
    percent: true,
  },

  {
    property: "manaScale",
    name: "Снижение затрат маны на использование заклинаний",
    string:
      "Уменьшает затрату маны на 25% (0.25% за уровень) за один удар в сражении с Боссом",
    number: 14,
    need: [5, 6, 7],
    countNeed: 35,
    countHave: 100,
    step: 0.25,
    percent: true,
  },
];

export const characteristicTalents = [
  {
    name: "Здоровье",
    string: "C каждым улучшением навыка базовое здоровье увеличивается на 0.2%",
  },
  {
    name: "Сила магии",
    string:
      "C каждым улучшением навыка базовая сила магии увеличивается на 0.2%",
  },
  {
    name: "Ярость",
    string: "C каждым улучшением навыка базовая ярость увеличивается на 0.2%",
  },
  {
    name: "Сопротивление",
    string:
      "C каждым улучшением навыка базовое сопротивление увеличивается на 0.2%",
  },
  {
    name: "Точность",
    string: "C каждым улучшением навыка базовая точность увеличивается на 0.2%",
  },
  {
    name: "Ловкость",
    string: "C каждым улучшением навыка базовая ловкость увеличивается на 0.2%",
  },
  {
    name: "Скорость",
    string: "C каждым улучшением навыка базовая скорость увеличивается на 0.2%",
  },
];

export const dayQuestRewards = [
  {
    gold: 10,
    exp: 100,
    mana: 100,
    master: 1,
  },
  {
    gold: 30,
    exp: 600,
    mana: 300,
    master: 1,
  },
  {
    gold: 80,
    exp: 2400,
    mana: 800,
    master: 1,
  },
  {
    gold: 200,
    exp: 8000,
    mana: 2000,
    master: 1,
  },
  {
    gold: 1000,
    exp: 50000,
    mana: 10000,
    master: 1,
  },
];

export const summerSaveQuestsArray = [
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Сыграть в кости",
      count: 5,
      property: "diceGameGames",
    },
    {
      string: "Сыграть в 21",
      count: 5,
      property: "game21Games",
    },
    {
      string: "Провести дуэль на арене",
      count: 5,
      property: "arenaFight",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Пройти урок Зельевариние",
      count: 2,
      property: "classroom1",
    },
    {
      string: "Собрать коллекцию Зельевариние",
      count: 2,
      property: "collection1",
    },
    {
      string: "Одолеть Босса в Бестиарии",
      count: 10,
      property: "bossesWins",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Пройти урок Растениеводство",
      count: 2,
      property: "classroom2",
    },
    {
      string: "Собрать коллекцию Растениеводство",
      count: 2,
      property: "collection2",
    },
    {
      string: "Одолеть Босса в Бестиарии в соло-режиме",
      count: 10,
      property: "bossesSoloWins",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Вырастить растения",
      count: 10,
      property: "grasses",
    },
    {
      string: "Сварить зелья",
      count: 10,
      property: "potions",
    },
    {
      string: "Одержать победу в дуэлях",
      count: 10,
      property: "arenaWins",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Выиграть в 21",
      count: 10,
      property: "game21Win",
    },
    {
      string: "Отправить драже 'Сюрприз' одногруппникам",
      count: 10,
      property: "candyMove",
    },
    {
      string: "Съесть драже 'Сюрприз'",
      count: 10,
      property: "candyGet",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Открыть сундуки",
      count: 10,
      property: "treasures",
    },
    {
      string: "Отправить элементы коллекций одногруппникам",
      count: 10,
      property: "collectionsMove",
    },
    {
      string: "Получить элементы коллекций от одногруппников",
      count: 10,
      property: "collectionsGetCount",
    },
  ],
  [
    {
      string: "Одолеть приспешников Верховного мага холода",
      count: 1,
      property: "snegoviki",
    },
    {
      string: "Одолеть соло Босса на локации",
      count: 10,
      property: "soloBossesWins",
    },
    {
      string: "Собрать комбинацию из 2-ух тузов в 21",
      count: 1,
      property: "game21Aces",
    },
    {
      string: "Собрать комбинацию из 4-ёх пятёрок в костях",
      count: 1,
      property: "diceGame5555",
    },
  ],
];

export const dayQuestArray = [
  [
    {
      string: "Сыграть в кости",
      count: 1,
      property: "diceGameGames",
    },
    {
      string: "Сыграть в 21",
      count: 1,
      property: "game21Games",
    },
    {
      string: "Поделиться элементами коллекции",
      count: 2,
      property: "collectionsMove",
    },
    {
      string: "Одержать победу в дуэлях",
      count: 10,
      property: "arenaWins",
    },
    {
      string: "Одолеть Босса в бестиарии",
      count: 10,
      property: "bossesWins",
    },
    {
      string: "Израсходовать энергию на уроках",
      count: 200,
      property: "classroomsEnergy",
    },
  ],
  [
    {
      string: "Выиграть в 21",
      count: 3,
      property: "game21Win",
    },
    {
      string: "Собрать коллекцию",
      count: 1,
      property: "collectionsGet",
    },
    {
      string: "Полить растения в теплице",
      count: 10,
      property: "plantsWater",
      price: 10,
    },
    {
      string: "Сварить зелья в лаборатории",
      count: 3,
      property: "potionsGet",
      price: 10,
    },
    {
      string: "Пройти урок в учебном классе",
      count: 1,
      property: "classroomsGet",
    },
    {
      string: "Одолеть Босса в бестиарии в соло-режиме",
      count: 1,
      property: "bossesSoloWins",
    },
  ],
  [
    {
      string: "Собрать комбинацию в 21",
      count: 1,
      property: "game21Combinations",
      price: 15,
    },
    {
      string: "Выиграть золото в кости",
      count: 20,
      property: "diceGameGold",
      price: 15,
    },
    {
      string: "Собрать коллекцию",
      count: 3,
      property: "collectionsGet",
    },
    {
      string: "Одержать победу в дуэлях",
      count: 40,
      property: "arenaWins",
      price: 25,
    },
    {
      string: "Одолеть Босса в бестиарии",
      count: 40,
      property: "bossesWins",
    },
    {
      string: "Одолеть соло Босса на локации",
      count: 4,
      property: "soloBossesWins",
    },
  ],
  [
    {
      string: "Сварить зелья в лаборатории",
      count: 20,
      property: "potionsGet",
      price: 40,
    },
    {
      string: "Посадить растения в теплице",
      count: 30,
      property: "plantsSet",
      price: 40,
    },
    {
      string: "Одолеть Босса в бестиарии в соло-режиме",
      count: 30,
      property: "bossesSoloWins",
    },
    {
      string: "Израсходовать энергию на уроках",
      count: 10000,
      property: "classroomsEnergy",
    },
    {
      string: "Отправить драже 'Сюрприз' одногруппникам",
      count: 5,
      property: "candyMove",
    },
    {
      string: "Съесть драже 'Сюрприз'",
      count: 5,
      property: "candyGet",
      price: 25,
    },
  ],
  [
    {
      string: "Собрать комбинацию в 21",
      count: 8,
      property: "game21Combinations",
      price: 100,
    },
    {
      string: "Одержать победу в дуэлях",
      count: 80,
      property: "arenaWins",
      price: 100,
    },
    {
      string: "Сбросить лимит в бестиарии",
      count: 1,
      property: "bossesLimit",
    },
    {
      string: "Израсходовать ману",
      count: 80000,
      property: "mana",
    },
    {
      string: "Одолеть Босса в бестиарии",
      count: 80,
      property: "bossesWins",
    },
    {
      string: "Одолеть соло Босса на локации",
      count: 20,
      property: "soloBossesWins",
    },
  ],
];

export const dayPrizes = [
  [
    {
      property: "silver",
      count: 1000,
    },
    {
      property: "mana",
      count: 100,
    },
  ],
  [
    {
      property: "silver",
      count: 2000,
    },
    {
      property: "mana",
      count: 200,
    },
  ],
  [
    {
      property: "gold",
      count: 5,
    },
    {
      property: "game21",
      count: 2,
    },
  ],
  [
    {
      property: "soul",
      number: 2,
      count: 2,
    },
    {
      property: "diceGame",
      count: 2,
    },
  ],
  [
    {
      property: "silver",
      count: 5000,
    },
    {
      property: "mana",
      count: 500,
    },
  ],
  [
    {
      property: "gold",
      count: 20,
    },
    {
      property: "soul",
      number: 3,
      count: 1,
    },
  ],
  [
    {
      property: "gold",
      count: 50,
    },
    {
      property: "magic",
      count: 1,
    },
  ],
];

export const spellsArrayDefault = {
  entity: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  man: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  ghost: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
};

export const spellsArray = {
  entity: [
    {
      number: 0,
      name: "Опуньо",
      string:
        "Заклинание - приказ магической твари, к атаке на выбранную цель.",
      mana: 10,
      krit: 1.25,
      deffence: 1,
      damageBase: 100,
      damage: 0.2,
      raidDamage: 1,
      damageScales: ["force"],
      need: [],
      countNeed: 0,
      countHave: 5,
      img: imgForce,
    },
    {
      number: 1,

      name: "Серпентониа",
      string:
        "Призывает крупную змею, подчиняющуюся воле мага, призвавшего её.",
      mana: 20,
      krit: 2,
      deffence: 1,
      damageBase: 100,
      damage: 0.2,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [0],
      countNeed: 3,
      countHave: 20,
      img: imgPowerForce,
    },
    {
      number: 2,

      name: "Флурбатио",
      string:
        " Из палочки вырывается рой летучих мышей нападающих на противника.",
      mana: 20,
      krit: 1.25,
      deffence: 1,
      damageBase: 200,
      damage: 0.3,
      raidDamage: 1,
      damageScales: ["force"],
      need: [0],
      countNeed: 3,
      countHave: 20,
      img: imgForce,
    },
    {
      number: 3,

      name: "Ависортум",
      string:
        "Призывает стаю птиц, кружащих над противником, сбивая его с толку.",
      mana: 15,
      krit: 1.25,
      deffence: 1.25,
      damageBase: 100,
      damage: 0.2,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [0],
      countNeed: 3,
      countHave: 20,
      img: imgForceAccuracy,
    },
    {
      number: 4,

      name: "Реддукто",
      string:
        "Из палочки заклинателя вырывается синий луч, взрывающий твердый объект.",
      mana: 35,
      krit: 3,
      deffence: 1,
      damageBase: 300,
      damage: 0.3,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [1],
      countNeed: 15,
      countHave: 30,
      img: imgPowerForce,
    },
    {
      number: 5,

      name: "Аквантарис",
      string:
        "Выпускает мощную и тонкую струю воды, способную разрезать камень.",
      mana: 35,
      krit: 1.35,
      deffence: 1,
      damageBase: 600,
      damage: 0.45,
      raidDamage: 1,
      damageScales: ["force"],
      need: [2],
      countNeed: 15,
      countHave: 30,
      img: imgForce,
    },
    {
      number: 6,

      name: "Брахинато",
      string: "Связывающее заклинание для обездвиживания любой живой цели.",
      mana: 25,
      krit: 1.35,
      deffence: 1.4,
      damageBase: 300,
      damage: 0.3,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [3],
      countNeed: 15,
      countHave: 30,
      img: imgForceAccuracy,
    },

    {
      number: 7,

      name: "Иллюзион",
      string:
        "Создаёт противнику реалистичную иллюзию нападения на него нескольких врагов.",
      mana: 100,
      krit: 3,
      deffence: 1,
      damageBase: 1000,
      damage: 0.5,
      raidDamage: 1.5,
      damageScales: ["force", "power"],
      need: [4],
      countNeed: 20,
      countHave: 40,
      img: imgPowerForce,
    },
    {
      number: 8,

      name: "Боммбардо",
      string:
        "Взрывающее заклятье, которое крошит на куски выбранную цель и все вокруг нее.",
      mana: 70,
      krit: 4,
      deffence: 1,
      damageBase: 1000,
      damage: 0.6,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [4],
      countNeed: 20,
      countHave: 40,
      img: imgPowerForce,
    },
    {
      number: 9,

      name: "Десендит",
      string:
        "Посылает следящий шар черного пламени, сжигающего все на своем пути.",
      mana: 70,
      krit: 1.75,
      deffence: 1,
      damageBase: 2000,
      damage: 0.8,
      raidDamage: 1,
      damageScales: ["force"],
      need: [5],
      countNeed: 20,
      countHave: 40,
      img: imgForce,
    },
    {
      number: 10,

      name: "Хазитум",
      string:
        "Погружает цель заклинания в твердый материал, на котором он стоит.",
      mana: 50,
      krit: 1.75,
      deffence: 1.5,
      damageBase: 1000,
      damage: 0.6,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [6],
      countNeed: 20,
      countHave: 40,
      img: imgForceAccuracy,
    },
    {
      number: 11,

      name: "Феанто Дуро",
      string:
        "Укрепляет и усиляет действие любых заклинаний, делая их нерушимыми.",
      mana: 60,
      krit: 1.5,
      deffence: 1.3,
      damageBase: 1000,
      damage: 0.5,
      raidDamage: 1.5,
      damageScales: ["force", "accuracy"],
      need: [6],
      countNeed: 20,
      countHave: 40,
      img: imgForceAccuracy,
    },
    {
      number: 12,

      name: "Губбрайтовы огни",
      string:
        "Создает вечные огни, которые наносят беспрерывный урон противнику.",
      mana: 300,
      krit: 4,
      deffence: 1,
      damageBase: 3000,
      damage: 1.6,
      raidDamage: 2,
      damageScales: ["force", "power"],
      need: [7],
      countNeed: 30,
      countHave: 80,
      img: imgPowerForce,
    },
    {
      number: 13,

      name: "Боммбардо Максимум",
      string:
        "Усиленная версия Боммбардо - наносит непоправимый ущерб всему живому и не очень.",
      mana: 100,
      krit: 5,
      deffence: 1,
      damageBase: 2000,
      damage: 0.85,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [8],
      countNeed: 30,
      countHave: 80,
      img: imgPowerForce,
    },
    {
      number: 14,

      name: "Вентрененум",
      string: "Темное заклинание, посылает во врага мощную темную стрелу.",
      mana: 100,
      krit: 2,
      deffence: 1,
      damageBase: 5000,
      damage: 1,
      raidDamage: 1,
      damageScales: ["force"],
      need: [9],
      countNeed: 30,
      countHave: 80,
      img: imgForce,
    },
    {
      number: 15,

      name: "Потего тоталус",
      string:
        "Мощное защитное заклинание, ограждающее от любых действий противника.",
      mana: 60,
      krit: 2,
      deffence: 1.6,
      damageBase: 2000,
      damage: 0.85,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [10],
      countNeed: 30,
      countHave: 80,
      img: imgForceAccuracy,
    },
    {
      number: 16,

      name: "Репело Иниктум",
      string:
        "В сочетании с заклинаниями Феанто Дуро и Потего тоталус создаёт практически нерушимую защиту от любых воздействий врага.",
      mana: 180,
      krit: 2,
      deffence: 1.4,
      damageBase: 3000,
      damage: 1.6,
      raidDamage: 2,
      damageScales: ["force", "accuracy"],
      need: [11],
      countNeed: 30,
      countHave: 80,
      img: imgForceAccuracy,
    },
  ],
  man: [
    {
      number: 0,
      name: "Веспатум",
      string:
        "Заклинание, приносящее противнику боль, аналогичную десяткам укусов пчел.",
      mana: 10,
      krit: 1.25,
      deffence: 1,
      damageBase: 100,
      damage: 0.2,
      raidDamage: 1,
      damageScales: ["force"],
      need: [],
      countNeed: 0,
      countHave: 5,
      img: imgForce,
    },
    {
      number: 1,

      name: "Авинорус",
      string:
        "Противник превращается в стаю летучих тварей и теряет способность колдовать.",
      mana: 20,
      krit: 2,
      deffence: 1,
      damageBase: 100,
      damage: 0.2,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [0],
      countNeed: 3,
      countHave: 20,
      img: imgPowerForce,
    },
    {
      number: 2,

      name: "Флипенндо",
      string:
        "Отбрасывающие чары. Заклинание отбрасывает человека или существо.",
      mana: 20,
      krit: 1.25,
      deffence: 1,
      damageBase: 200,
      damage: 0.3,
      raidDamage: 1,
      damageScales: ["force"],
      need: [0],
      countNeed: 3,
      countHave: 20,
      img: imgForce,
    },
    {
      number: 3,

      name: "Экспилиармус",
      string: "Заклинание позволяет уничтожить любое оружие в руках врага.",
      mana: 15,
      krit: 1.25,
      deffence: 1.25,
      damageBase: 100,
      damage: 0.2,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [0],
      countNeed: 3,
      countHave: 20,
      img: imgForceAccuracy,
    },
    {
      number: 4,

      name: "Столбеней",
      string:
        "Оглушающее заклятие. Мгновенно парализует и отбрасывает противника.",
      mana: 35,
      krit: 3,
      deffence: 1,
      damageBase: 300,
      damage: 0.3,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [1],
      countNeed: 15,
      countHave: 30,
      img: imgPowerForce,
    },
    {
      number: 5,

      name: "Фулгурум",
      string: "Выпускает из конца волшебной палочки яркую ослепляющую молнию.",
      mana: 35,
      krit: 1.35,
      deffence: 1,
      damageBase: 600,
      damage: 0.45,
      raidDamage: 1,
      damageScales: ["force"],
      need: [2],
      countNeed: 15,
      countHave: 30,
      img: imgForce,
    },
    {
      number: 6,

      name: "Потего",
      string: "Отражает заклинание противника, полностью блокируя урон.",
      mana: 25,
      krit: 1.35,
      deffence: 1.4,
      damageBase: 300,
      damage: 0.3,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [3],
      countNeed: 15,
      countHave: 30,
      img: imgForceAccuracy,
    },

    {
      number: 7,

      name: "Теневой туман",
      string:
        "Густой туман обволакивает цель мрачной аурой, прочно сковывает ее движения и наносит урон.",
      mana: 100,
      krit: 3,
      deffence: 1,
      damageBase: 1000,
      damage: 0.5,
      raidDamage: 1.5,
      damageScales: ["force", "power"],
      need: [4],
      countNeed: 20,
      countHave: 40,
      img: imgPowerForce,
    },
    {
      number: 8,

      name: "Даркомус",
      string:
        "В голову противнику проникают самые мрачные мысли, затуманивая его рассудок.",
      mana: 70,
      krit: 4,
      deffence: 1,
      damageBase: 1000,
      damage: 0.6,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [4],
      countNeed: 20,
      countHave: 40,
      img: imgPowerForce,
    },
    {
      number: 9,

      name: "Фламос Фурия",
      string:
        "Вызывает внезапный всплеск пламени, атакующий врагов вокруг языками огня.",
      mana: 70,
      krit: 1.75,
      deffence: 1,
      damageBase: 2000,
      damage: 0.8,
      raidDamage: 1,
      damageScales: ["force"],
      need: [5],
      countNeed: 20,
      countHave: 40,
      img: imgForce,
    },
    {
      number: 10,

      name: "Импидемента",
      string: "Значительно замедляет объект или человека на некоторое время.",
      mana: 50,
      krit: 1.75,
      deffence: 1.5,
      damageBase: 1000,
      damage: 0.6,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [6],
      countNeed: 20,
      countHave: 40,
      img: imgForceAccuracy,
    },
    {
      number: 11,

      name: "Конфуриус",
      string:
        "Заклятие повергает противника в ступор, полностью дезориентирует.",
      mana: 60,
      krit: 1.5,
      deffence: 1.3,
      damageBase: 1000,
      damage: 0.5,
      raidDamage: 1.5,
      damageScales: ["force", "accuracy"],
      need: [6],
      countNeed: 20,
      countHave: 40,
      img: imgForceAccuracy,
    },
    {
      number: 12,

      name: "Мортем Илем",
      string:
        "Заклинание призывает армию мертвецов, которые жаждут живой плоти.",
      mana: 300,
      krit: 4,
      deffence: 1,
      damageBase: 3000,
      damage: 1.6,
      raidDamage: 2,
      damageScales: ["force", "power"],
      need: [7],
      countNeed: 30,
      countHave: 80,
      img: imgPowerForce,
    },
    {
      number: 13,

      name: "Сектумсентра",
      string:
        "Заклинание, которое, будто мечом, разит противника, причиняя ему острую боль.",
      mana: 100,
      krit: 5,
      deffence: 1,
      damageBase: 2000,
      damage: 0.85,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [8],
      countNeed: 30,
      countHave: 80,
      img: imgPowerForce,
    },
    {
      number: 14,

      name: "Инседио Игнис",
      string:
        "Призывает огонь, который одновременно и обжигает цель, и замораживает ее.",
      mana: 100,
      krit: 2,
      deffence: 1,
      damageBase: 5000,
      damage: 1,
      raidDamage: 1,
      damageScales: ["force"],
      need: [9],
      countNeed: 30,
      countHave: 80,
      img: imgForce,
    },
    {
      number: 15,

      name: "Питрификус Тоталос",
      string:
        "Будто сковывает невидимыми цепями противника, не давая ему шевелиться.",
      mana: 60,
      krit: 2,
      deffence: 1.6,
      damageBase: 2000,
      damage: 0.85,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [10],
      countNeed: 30,
      countHave: 80,
      img: imgForceAccuracy,
    },
    {
      number: 16,

      name: "Иммпериум",
      string:
        "Абсолютное подчинение воли противника, управление его мыслями и телом.",
      mana: 180,
      krit: 2,
      deffence: 1.4,
      damageBase: 3000,
      damage: 1.6,
      raidDamage: 2,
      damageScales: ["force", "accuracy"],
      need: [11],
      countNeed: 30,
      countHave: 80,
      img: imgForceAccuracy,
    },
  ],
  ghost: [
    {
      number: 0,
      name: "Люнос Соллем",
      string: "Вызывает солнечный луч из конца палочки, озаряющий все вокруг.",
      mana: 10,
      krit: 1.25,
      deffence: 1,
      damageBase: 100,
      damage: 0.2,
      raidDamage: 1,
      damageScales: ["force"],
      need: [],
      countNeed: 0,
      countHave: 5,
      img: imgForce,
    },
    {
      number: 1,

      name: "Имобюлус",
      string: "Заклинание, которое полностью запрещает движение врагу.",
      mana: 20,
      krit: 2,
      deffence: 1,
      damageBase: 100,
      damage: 0.2,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [0],
      countNeed: 3,
      countHave: 20,
      img: imgPowerForce,
    },
    {
      number: 2,

      name: "Ридикуллус",
      string: "Защитное заклинание для превращения призрака во что-то смешное.",
      mana: 20,
      krit: 1.25,
      deffence: 1,
      damageBase: 200,
      damage: 0.3,
      raidDamage: 1,
      damageScales: ["force"],
      need: [0],
      countNeed: 3,
      countHave: 20,
      img: imgForce,
    },
    {
      number: 3,

      name: "Ревеллио Венефикус",
      string:
        "Обнаруживает призраков в округе и подсвечивает их сквозь предметы.",
      mana: 15,
      krit: 1.25,
      deffence: 1.25,
      damageBase: 100,
      damage: 0.2,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [0],
      countNeed: 3,
      countHave: 20,
      img: imgForceAccuracy,
    },
    {
      number: 4,

      name: "Ретарум",
      string:
        "Чары захвата и притяжения. Притягивает цель к тому, кто произнес заклинание.",
      mana: 35,
      krit: 3,
      deffence: 1,
      damageBase: 300,
      damage: 0.3,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [1],
      countNeed: 15,
      countHave: 30,
      img: imgPowerForce,
    },
    {
      number: 5,

      name: "Каритус максима",
      string: "Проявляет сокрытые темной магией предметы, делая их осязаемыми.",
      mana: 35,
      krit: 1.35,
      deffence: 1,
      damageBase: 600,
      damage: 0.45,
      raidDamage: 1,
      damageScales: ["force"],
      need: [2],
      countNeed: 15,
      countHave: 30,
      img: imgForce,
    },
    {
      number: 6,

      name: "Иннера",
      string:
        " Заклинание, защищающее от разрушительного воздействия огня или температуры.",
      mana: 25,
      krit: 1.35,
      deffence: 1.4,
      damageBase: 300,
      damage: 0.3,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [3],
      countNeed: 15,
      countHave: 30,
      img: imgForceAccuracy,
    },

    {
      number: 7,

      name: "Сервиторе",
      string:
        "Заклинание, которое подчиняет волшебнику волю магических существ.",
      mana: 100,
      krit: 3,
      deffence: 1,
      damageBase: 1000,
      damage: 0.5,
      raidDamage: 1.5,
      damageScales: ["force", "power"],
      need: [4],
      countNeed: 20,
      countHave: 40,
      img: imgPowerForce,
    },
    {
      number: 8,

      name: "Реддуцио",
      string: "Сжимающие чары - уменьшают объект, сохраняя его пропорции.",
      mana: 70,
      krit: 4,
      deffence: 1,
      damageBase: 1000,
      damage: 0.6,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [4],
      countNeed: 20,
      countHave: 40,
      img: imgPowerForce,
    },
    {
      number: 9,

      name: "Флипенндо Трио",
      string:
        "Создает маленький, но довольно мощный торнадо, сметающий все на пути.",
      mana: 70,
      krit: 1.75,
      deffence: 1,
      damageBase: 2000,
      damage: 0.8,
      raidDamage: 1,
      damageScales: ["force"],
      need: [5],
      countNeed: 20,
      countHave: 40,
      img: imgForce,
    },
    {
      number: 10,

      name: "Потего умбра",
      string: "Мощное защитное заклинание, ограждающее от любой темной магии.",
      mana: 50,
      krit: 1.75,
      deffence: 1.5,
      damageBase: 1000,
      damage: 0.6,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [6],
      countNeed: 20,
      countHave: 40,
      img: imgForceAccuracy,
    },
    {
      number: 11,

      name: "Ресто Моменто",
      string: "Заклинание, останавливающее или замедляющее время противникам.",
      mana: 60,
      krit: 1.5,
      deffence: 1.3,
      damageBase: 1000,
      damage: 0.5,
      raidDamage: 1.5,
      damageScales: ["force", "accuracy"],
      need: [6],
      countNeed: 20,
      countHave: 40,
      img: imgForceAccuracy,
    },
    {
      number: 12,

      name: "Спектра Спирум",
      string:
        "Приносит разрушительную боль, которая будто сжигает противника изнутри.",
      mana: 300,
      krit: 4,
      deffence: 1,
      damageBase: 3000,
      damage: 1.6,
      raidDamage: 2,
      damageScales: ["force", "power"],
      need: [7],
      countNeed: 30,
      countHave: 80,
      img: imgPowerForce,
    },
    {
      number: 13,

      name: "Эспекто Натронум",
      string:
        "Заклинание, вызывающее охранный магический дух, защищающий от врагов.",
      mana: 100,
      krit: 5,
      deffence: 1,
      damageBase: 2000,
      damage: 0.85,
      raidDamage: 1,
      damageScales: ["force", "power"],
      need: [8],
      countNeed: 30,
      countHave: 80,
      img: imgPowerForce,
    },
    {
      number: 14,

      name: "Элацио",
      string:
        "Стирает грань между миром живых и духов. Призрак станет вновь смертным.",
      mana: 100,
      krit: 2,
      deffence: 1,
      damageBase: 5000,
      damage: 1,
      raidDamage: 1,
      damageScales: ["force"],
      need: [9],
      countNeed: 30,
      countHave: 80,
      img: imgForce,
    },
    {
      number: 15,

      name: "Карселум Аспра",
      string: "Заклинание заточения призрака в объект, который не был живым.",
      mana: 60,
      krit: 2,
      deffence: 1.6,
      damageBase: 2000,
      damage: 0.85,
      raidDamage: 1,
      damageScales: ["force", "accuracy"],
      need: [10],
      countNeed: 30,
      countHave: 80,
      img: imgForceAccuracy,
    },
    {
      number: 16,

      name: "Финита Инканнтатем",
      string:
        "Универсальное контрзаклятие, которое снимает действие заговоров и чар.",
      mana: 180,
      krit: 2,
      deffence: 1.4,
      damageBase: 3000,
      damage: 1.6,
      raidDamage: 2,
      damageScales: ["force", "accuracy"],
      need: [11],
      countNeed: 30,
      countHave: 80,
      img: imgForceAccuracy,
    },
  ],
};

export const treasuresArray = [
  [
    {
      property: "gold",
      count: 120,
    },
    {
      property: "gold",
      count: 20,
    },
    {
      property: "gold",
      count: 10,
    },
    {
      property: "gold",
      count: 5,
    },
    {
      property: "soul",
      number: 3,
      count: 1,
    },
    {
      property: "soul",
      number: 2,
      count: 1,
    },
    {
      property: "soul",
      number: 1,
      count: 1,
    },
    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 1,
    },
    {
      property: "potion",
      category: "energy",
      potionName: "+50%",
      value: 50,
      percent: true,
      count: 1,
    },
    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 1,
    },
    {
      property: "potion",
      category: "energy",
      potionName: "+100",
      value: 100,
      percent: false,
      count: 1,
    },
    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 1,
    },
    {
      property: "potion",
      category: "hp",
      potionName: "+50%",
      value: 50,
      percent: true,
      count: 1,
    },
    {
      property: "game21",
      count: 2,
    },
    {
      property: "diceGame",
      count: 5,
    },
  ],

  [
    {
      property: "gold",
      count: 150,
    },
    {
      property: "gold",
      count: 25,
    },
    {
      property: "gold",
      count: 15,
    },
    {
      property: "gold",
      count: 10,
    },
    {
      property: "soul",
      number: 4,
      count: 1,
    },
    {
      property: "soul",
      number: 3,
      count: 1,
    },
    {
      property: "soul",
      number: 2,
      count: 1,
    },
    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 1,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 2,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 1,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 2,
    },

    {
      property: "potion",
      category: "energy",
      potionName: "+200",
      value: 200,
      percent: false,
      count: 3,
    },

    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 1,
    },

    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 2,
    },

    {
      property: "potion",
      category: "hp",
      potionName: "+100%",
      value: 100,
      percent: true,
      count: 3,
    },

    {
      property: "game21",
      count: 5,
    },

    {
      property: "diceGame",
      count: 10,
    },
  ],
];

export const bossesStages = ["Обычный", "Агрессивный", "Проклятый"];

export const eventBossArray = [
  {
    lvlNeed: 5,
    postImage: "photo-221829804_457239492",
    classType: "entity",
    name: "Порождения холода",
    attack: 15,
    allHp: 2500,
    currentHp: 2500,
    rewards: {
      exp: 200,
      silver: 2000,
      gold: 20,
    },
    time: {
      maxTime: 1,
    },
    bossKritChance: 7,
    bossEvasionChance: 5,
    string:
      "Ужасного вида снеговики с жуткими язвами на туловище используют тёмную магию холода и забирают тепло и свет у всего живого вокруг. Они устойчивы ко многим известным заклинаниям и держатся вместе.",
    text: [
      "Известно, что чудовища - это дело рук Верховного мага холода, которые решил окунуть замок в атмосферу мрака и лишить нас лета.",
    ],
  },
];

export const shytArray = [
  [
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239754",
      classType: "random",
      name: "Шут",
      attack: 30,
      allHp: 80000,
      currentHp: 80000,
      manaCost: 1.25,
      rewards: {
        exp: 200,
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: "random",
      bossEvasionChance: "random",
      string:
        "Во тьме ночной, при свете дня – всегда есть место шутовским затеям! И в комнате безумной той, уж ни один Волшебник был потерян... Коварный нрав и хитрая натура встречают тех, кто смотрит на него угрюмо. Он фокусами задурит тебя сразу, отчего твоя победа, твой подвиг будет славен!",
    },
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239754",
      classType: "random",
      name: "Шут",
      attack: 40,
      allHp: 80000,
      currentHp: 80000,
      manaCost: 2,
      rewards: {
        exp: 250,
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: "random",
      bossEvasionChance: "random",
      string:
        "Во тьме ночной, при свете дня – всегда есть место шутовским затеям! И в комнате безумной той, уж ни один Волшебник был потерян... Коварный нрав и хитрая натура встречают тех, кто смотрит на него угрюмо. Он фокусами задурит тебя сразу, отчего твоя победа, твой подвиг будет славен!",
    },
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239754",
      classType: "random",
      name: "Шут",
      attack: 55,
      allHp: 80000,
      currentHp: 80000,
      manaCost: 3,
      rewards: {
        exp: 350,
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: "random",
      bossEvasionChance: "random",
      string:
        "Во тьме ночной, при свете дня – всегда есть место шутовским затеям! И в комнате безумной той, уж ни один Волшебник был потерян... Коварный нрав и хитрая натура встречают тех, кто смотрит на него угрюмо. Он фокусами задурит тебя сразу, отчего твоя победа, твой подвиг будет славен!",
    },
  ],
];

export const soloBossArray = [
  [
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239232",
      classType: "man",
      name: "Задиры",
      attack: 25,
      allHp: 1000,
      currentHp: 1000,
      rewards: {
        exp: 50,
        silver: 4000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.5,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 4,
      bossEvasionChance: 4,
      string:
        "Два грубоватого вида старшекурсника, они не прочь помахать кулаками и поиздеваться над новичками школы. Высок шанс встретить их в подземелье. Невероятно тупы, из-за чего слабо владеют заклинаниями, а сильных магов обходят стороной.",
    },
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239232",
      classType: "man",
      name: "Задиры",
      attack: 40,
      allHp: 5000,
      currentHp: 5000,
      rewards: {
        exp: 75,
        silver: 22000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.7,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 5,
      bossEvasionChance: 5,
      string:
        "Два грубоватого вида старшекурсника, они не прочь помахать кулаками и поиздеваться над новичками школы. Высок шанс встретить их в подземелье. Невероятно тупы, из-за чего слабо владеют заклинаниями, а сильных магов обходят стороной.",
    },
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239232",
      classType: "man",
      name: "Задиры",
      attack: 55,
      allHp: 25000,
      currentHp: 25000,
      rewards: {
        exp: 100,
        silver: 125000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.9,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 7,
      bossEvasionChance: 7,
      string:
        "Два грубоватого вида старшекурсника, они не прочь помахать кулаками и поиздеваться над новичками школы. Высок шанс встретить их в подземелье. Невероятно тупы, из-за чего слабо владеют заклинаниями, а сильных магов обходят стороной.",
    },
  ],
  [
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239235",
      classType: "ghost",
      name: "Сумрак",
      attack: 25,
      allHp: 1000,
      currentHp: 1000,
      rewards: {
        exp: 50,
        silver: 4000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.5,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 3,
      bossEvasionChance: 5,
      string:
        "Это редкий подвид призрака, представляет собой фантом плаща. Ведёт ночной образ жизни, во время охоты стелется над самой землёй. Питается в основном спящими людьми, он оборочивает жертву в полы плаща и переваривает, оставляя после трапезы лишь жалкие останки.",
    },
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239235",
      classType: "ghost",
      name: "Сумрак",
      attack: 40,
      allHp: 5000,
      currentHp: 5000,
      rewards: {
        exp: 75,
        silver: 22000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.7,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 3,
      bossEvasionChance: 7,
      string:
        "Это редкий подвид призрака, представляет собой фантом плаща. Ведёт ночной образ жизни, во время охоты стелется над самой землёй. Питается в основном спящими людьми, он оборочивает жертву в полы плаща и переваривает, оставляя после трапезы лишь жалкие останки.",
    },
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239235",
      classType: "ghost",
      name: "Сумрак",
      attack: 55,
      allHp: 25000,
      currentHp: 25000,
      rewards: {
        exp: 100,
        silver: 125000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.9,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 3,
      bossEvasionChance: 11,
      string:
        "Это редкий подвид призрака, представляет собой фантом плаща. Ведёт ночной образ жизни, во время охоты стелется над самой землёй. Питается в основном спящими людьми, он оборочивает жертву в полы плаща и переваривает, оставляя после трапезы лишь жалкие останки.",
    },
  ],
  [
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239234",
      classType: "entity",
      name: "Биомасса",
      attack: 25,
      allHp: 1000,
      currentHp: 1000,
      rewards: {
        exp: 50,
        silver: 4000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.5,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 5,
      bossEvasionChance: 3,
      string:
        "Никому доподлинно не известно, каким именно образом в заброшенных туалетах зародилось это богомерзское существо. Из-за утечки алхимических отходов, а может по вине неаккуратных студентов, сия жижа стала захватывать территорию путем пожирания всего что движется.",
    },
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239234",
      classType: "entity",
      name: "Биомасса",
      attack: 40,
      allHp: 5000,
      currentHp: 5000,
      rewards: {
        exp: 75,
        silver: 22000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.7,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 7,
      bossEvasionChance: 3,
      string:
        "Никому доподлинно не известно, каким именно образом в заброшенных туалетах зародилось это богомерзское существо. Из-за утечки алхимических отходов, а может по вине неаккуратных студентов, сия жижа стала захватывать территорию путем пожирания всего что движется.",
    },
    {
      lvlNeed: 10,
      postImage: "photo-221829804_457239234",
      classType: "entity",
      name: "Биомасса",
      attack: 55,
      allHp: 25000,
      currentHp: 25000,
      rewards: {
        exp: 100,
        silver: 125000,
        lvl: 0,
        treasure: {
          lvl: 0,
          chance: 0.9,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 11,
      bossEvasionChance: 3,
      string:
        "Никому доподлинно не известно, каким именно образом в заброшенных туалетах зародилось это богомерзское существо. Из-за утечки алхимических отходов, а может по вине неаккуратных студентов, сия жижа стала захватывать территорию путем пожирания всего что движется.",
    },
  ],
  [
    {
      lvlNeed: 40,
      postImage: "photo-221829804_457239611",
      classType: "entity",
      name: "Демон канализации",
      attack: 30,
      allHp: 6000,
      currentHp: 6000,
      rewards: {
        exp: 100,
        silver: 7000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.35,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 9,
      bossEvasionChance: 5,
      string:
        "Монстр является частью сгнивших стен и водосточных труб, появляется в разных местах и захватывает жертву в кокон. Преподавателям удалось найти остатки одежды пропавших студентов, однако сами Волшебники, кажется, пропали навсегда.",
    },
    {
      lvlNeed: 40,
      postImage: "photo-221829804_457239611",
      classType: "entity",
      name: "Демон канализации",
      attack: 50,
      allHp: 30000,
      currentHp: 30000,
      rewards: {
        exp: 150,
        silver: 38000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.55,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 11,
      bossEvasionChance: 5,
      string:
        "Монстр является частью сгнивших стен и водосточных труб, появляется в разных местах и захватывает жертву в кокон. Преподавателям удалось найти остатки одежды пропавших студентов, однако сами Волшебники, кажется, пропали навсегда.",
    },
    {
      lvlNeed: 40,
      postImage: "photo-221829804_457239611",
      classType: "entity",
      name: "Демон канализации",
      attack: 70,
      allHp: 150000,
      currentHp: 150000,
      rewards: {
        exp: 200,
        silver: 200000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.75,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 15,
      bossEvasionChance: 5,
      string:
        "Монстр является частью сгнивших стен и водосточных труб, появляется в разных местах и захватывает жертву в кокон. Преподавателям удалось найти остатки одежды пропавших студентов, однако сами Волшебники, кажется, пропали навсегда.",
    },
  ],

  [
    {
      lvlNeed: 40,
      postImage: "photo-221829804_457239613",
      classType: "man",
      name: "Златоуст",
      attack: 30,
      allHp: 6000,
      currentHp: 6000,
      rewards: {
        exp: 100,
        silver: 7000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.35,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 7,
      bossEvasionChance: 7,
      string:
        "Гнилые поступки стали причиной изгнания преподавателя из Школы. Озабоченный своей персоной Волшебник не смог смириться с отставкой и создал закрытый клуб глубоко в замке. Жажда мести и усугубившиеся психические заболевания делают его опасным противником.",
    },
    {
      lvlNeed: 40,
      postImage: "photo-221829804_457239613",
      classType: "man",
      name: "Златоуст",
      attack: 50,
      allHp: 30000,
      currentHp: 30000,
      rewards: {
        exp: 150,
        silver: 38000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.55,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 8,
      bossEvasionChance: 8,
      string:
        "Гнилые поступки стали причиной изгнания преподавателя из Школы. Озабоченный своей персоной Волшебник не смог смириться с отставкой и создал закрытый клуб глубоко в замке. Жажда мести и усугубившиеся психические заболевания делают его опасным противником.",
    },
    {
      lvlNeed: 40,
      postImage: "photo-221829804_457239613",
      classType: "man",
      name: "Златоуст",
      attack: 70,
      allHp: 150000,
      currentHp: 150000,
      rewards: {
        exp: 200,
        silver: 200000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.75,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 10,
      bossEvasionChance: 10,
      string:
        "Гнилые поступки стали причиной изгнания преподавателя из Школы. Озабоченный своей персоной Волшебник не смог смириться с отставкой и создал закрытый клуб глубоко в замке. Жажда мести и усугубившиеся психические заболевания делают его опасным противником.",
    },
  ],

  [
    {
      lvlNeed: 40,
      postImage: "photo-221829804_457239612",
      classType: "ghost",
      name: "Призрак Рыцаря",
      attack: 30,
      allHp: 6000,
      currentHp: 6000,
      rewards: {
        exp: 100,
        silver: 7000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.35,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 5,
      bossEvasionChance: 9,
      string:
        "Вооруженный вполне материальным мечом и снаряжённый в тяжёлую броню призрак рыцаря, ставший жертвой темного проклятия, появляется из ниоткуда и бросает смертельнй вызов всем Волшебникам без разбора.",
    },
    {
      lvlNeed: 40,
      postImage: "photo-221829804_457239612",
      classType: "ghost",
      name: "Призрак Рыцаря",
      attack: 50,
      allHp: 30000,
      currentHp: 30000,
      rewards: {
        exp: 150,
        silver: 38000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.55,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 5,
      bossEvasionChance: 11,
      string:
        "Вооруженный вполне материальным мечом и снаряжённый в тяжёлую броню призрак рыцаря, ставший жертвой темного проклятия, появляется из ниоткуда и бросает смертельнй вызов всем Волшебникам без разбора.",
    },
    {
      lvlNeed: 40,
      postImage: "photo-221829804_457239612",
      classType: "ghost",
      name: "Призрак Рыцаря",
      attack: 70,
      allHp: 150000,
      currentHp: 150000,
      rewards: {
        exp: 200,
        silver: 200000,
        lvl: 0,
        treasure: {
          lvl: 1,
          chance: 0.75,
        },
      },
      time: {
        maxTime: 1,
      },
      bossKritChance: 5,
      bossEvasionChance: 15,
      string:
        "Вооруженный вполне материальным мечом и снаряжённый в тяжёлую броню призрак рыцаря, ставший жертвой темного проклятия, появляется из ниоткуда и бросает смертельнй вызов всем Волшебникам без разбора.",
    },
  ],
];

export const bossesArray = [
  [
    {
      postImage: "photo-221829804_457239189",
      classType: "entity",
      name: "Пикси",
      attack: 50,
      allHp: 1000,
      currentHp: 1000,
      rewards: {
        exp: 100,
        silver: 200,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 0,
      bossKritChance: 6,
      bossEvasionChance: 4,
      string:
        "Несмотря на свой маленький рост, необыкновенно проказливые синие существа. После того, как один непутевый преподаватель случайно выпустил их из клетки, слоняются по замку и доставляют хлопоты его обитателям.",
    },
    {
      postImage: "photo-221829804_457239189",
      classType: "entity",
      name: "Пикси",
      attack: 100,
      allHp: 5000,
      currentHp: 5000,
      rewards: {
        exp: 300,
        silver: 600,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 0,
      bossKritChance: 8,
      bossEvasionChance: 4,
      string:
        "Несмотря на свой маленький рост, необыкновенно проказливые синие существа. После того, как один непутевый преподаватель случайно выпустил их из клетки, слоняются по замку и доставляют хлопоты его обитателям.",
    },
    {
      postImage: "photo-221829804_457239189",
      classType: "entity",
      name: "Пикси",
      attack: 200,
      allHp: 15000,
      currentHp: 15000,
      rewards: {
        exp: 800,
        silver: 1600,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 0,
      bossKritChance: 12,
      bossEvasionChance: 4,
      string:
        "Несмотря на свой маленький рост, необыкновенно проказливые синие существа. После того, как один непутевый преподаватель случайно выпустил их из клетки, слоняются по замку и доставляют хлопоты его обитателям.",
    },
  ],
  [
    {
      postImage: "photo-221829804_457239436",
      classType: "ghost",
      name: "Полтергейст",
      attack: 100,
      allHp: 5000,
      currentHp: 5000,
      rewards: {
        exp: 250,
        silver: 500,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 5,
      bossEvasionChance: 7,
      string:
        "Обитающий в замке, злобный полтергейст в шутовском наряде. Постоянно вытворяет различные фокусы и шалости, которые могут порой доставлять проблемы и хаос в замке. Особенно неравнодушен к новичкам.",
    },
    {
      postImage: "photo-221829804_457239436",
      classType: "ghost",
      name: "Полтергейст",
      attack: 200,
      allHp: 25000,
      currentHp: 25000,
      rewards: {
        exp: 750,
        silver: 1500,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 5,
      bossEvasionChance: 10,
      string:
        "Обитающий в замке, злобный полтергейст в шутовском наряде. Постоянно вытворяет различные фокусы и шалости, которые могут порой доставлять проблемы и хаос в замке. Особенно неравнодушен к новичкам.",
    },
    {
      postImage: "photo-221829804_457239436",
      classType: "ghost",
      name: "Полтергейст",
      attack: 400,
      allHp: 75000,
      currentHp: 75000,
      rewards: {
        exp: 2000,
        silver: 4000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 5,
      bossEvasionChance: 13,
      string:
        "Обитающий в замке, злобный полтергейст в шутовском наряде. Постоянно вытворяет различные фокусы и шалости, которые могут порой доставлять проблемы и хаос в замке. Особенно неравнодушен к новичкам.",
    },
  ],
  [
    {
      postImage: "photo-221829804_457239435",
      classType: "ghost",
      name: "Призрак Плаксы",
      attack: 200,
      allHp: 25000,
      currentHp: 25000,
      rewards: {
        exp: 500,
        silver: 1000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 6,
      bossEvasionChance: 8,
      string:
        "Призрак девочки c факультета Мудрости, обитающий  в туалетах замка. На первый взгляд, может показаться очень ранимой, однако… Особенно недолюбливает одногруппников, насмехавшихся над ней при жизни.",
    },
    {
      postImage: "photo-221829804_457239435",
      classType: "ghost",
      name: "Призрак Плаксы",
      attack: 400,
      allHp: 125000,
      currentHp: 125000,
      rewards: {
        exp: 1500,
        silver: 3000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 6,
      bossEvasionChance: 12,
      string:
        "Призрак девочки c факультета Мудрости, обитающий  в туалетах замка. На первый взгляд, может показаться очень ранимой, однако… Особенно недолюбливает одногруппников, насмехавшихся над ней при жизни.",
    },
    {
      postImage: "photo-221829804_457239435",
      classType: "ghost",
      name: "Призрак Плаксы",
      attack: 800,
      allHp: 375000,
      currentHp: 375000,
      rewards: {
        exp: 4000,
        silver: 8000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 6,
      bossEvasionChance: 16,
      string:
        "Призрак девочки c факультета Мудрости, обитающий  в туалетах замка. На первый взгляд, может показаться очень ранимой, однако… Особенно недолюбливает одногруппников, насмехавшихся над ней при жизни.",
    },
  ],
  [
    {
      postImage: "photo-221829804_457239434",
      classType: "man",
      name: "Жуткий Завхоз",
      attack: 500,
      allHp: 100000,
      currentHp: 100000,
      rewards: {
        exp: 1200,
        silver: 2400,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 8,
      bossEvasionChance: 8,
      string:
        "Жуткий Завхоз прекрасно знает все закоулки в замке и слишком придирчиво следит за порядком. Обожает свою кошку, ибо она - точная копия завхоза! Если Вы ему попадетесь в ночное время, завхоз придумает самое ужасное наказание.",
    },
    {
      postImage: "photo-221829804_457239434",
      classType: "man",
      name: "Жуткий Завхоз",
      attack: 1000,
      allHp: 500000,
      currentHp: 500000,
      rewards: {
        exp: 3600,
        silver: 7200,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 13,
      bossEvasionChance: 13,
      string:
        "Жуткий Завхоз прекрасно знает все закоулки в замке и слишком придирчиво следит за порядком. Обожает свою кошку, ибо она - точная копия завхоза! Если Вы ему попадетесь в ночное время, завхоз придумает самое ужасное наказание.",
    },
    {
      postImage: "photo-221829804_457239434",
      classType: "man",
      name: "Жуткий Завхоз",
      attack: 2000,
      allHp: 1500000,
      currentHp: 1500000,
      rewards: {
        exp: 9600,
        silver: 19200,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 18,
      bossEvasionChance: 18,
      string:
        "Жуткий Завхоз прекрасно знает все закоулки в замке и слишком придирчиво следит за порядком. Обожает свою кошку, ибо она - точная копия завхоза! Если Вы ему попадетесь в ночное время, завхоз придумает самое ужасное наказание.",
    },
  ],
  [
    {
      postImage: "photo-221829804_457239433",
      classType: "entity",
      name: "Кошмар",
      attack: 1000,
      allHp: 500000,
      currentHp: 500000,
      rewards: {
        exp: 3000,
        silver: 6000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 11,
      bossEvasionChance: 9,
      string:
        "Самое загадочное существо, обитающее в темных закоулках замка. Никто не знает его истинного обличия, так как Кошмар принимает форму того, чего больше всего в жизни боится противник перед ним.",
    },
    {
      postImage: "photo-221829804_457239433",
      classType: "entity",
      name: "Кошмар",
      attack: 2000,
      allHp: 2500000,
      currentHp: 2500000,
      rewards: {
        exp: 9000,
        silver: 18000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 17,
      bossEvasionChance: 9,
      string:
        "Самое загадочное существо, обитающее в темных закоулках замка. Никто не знает его истинного обличия, так как Кошмар принимает форму того, чего больше всего в жизни боится противник перед ним.",
    },
    {
      postImage: "photo-221829804_457239433",
      classType: "entity",
      name: "Кошмар",
      attack: 4000,
      allHp: 7500000,
      currentHp: 7500000,
      rewards: {
        exp: 24000,
        silver: 48000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 23,
      bossEvasionChance: 9,
      string:
        "Самое загадочное существо, обитающее в темных закоулках замка. Никто не знает его истинного обличия, так как Кошмар принимает форму того, чего больше всего в жизни боится противник перед ним.",
    },
  ],
  [
    {
      postImage: "photo-221829804_457239432",
      classType: "ghost",
      name: "Зловещий Призрак",
      attack: 2000,
      allHp: 2000000,
      currentHp: 2000000,
      rewards: {
        exp: 8000,
        silver: 16000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 10,
      bossEvasionChance: 12,
      string:
        "Жуткий призрак факультета Хитрости с выпученными пустыми глазами, вытянутым костлявым лицом, и в одеждах, запачканных кровью. Даже другие привидения школы стараются избегать его, что уж говорить о студентах...",
    },
    {
      postImage: "photo-221829804_457239432",
      classType: "ghost",
      name: "Зловещий Призрак",
      attack: 4000,
      allHp: 10000000,
      currentHp: 10000000,
      rewards: {
        exp: 24000,
        silver: 48000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 10,
      bossEvasionChance: 19,
      string:
        "Жуткий призрак факультета Хитрости с выпученными пустыми глазами, вытянутым костлявым лицом, и в одеждах, запачканных кровью. Даже другие привидения школы стараются избегать его, что уж говорить о студентах...",
    },

    {
      postImage: "photo-221829804_457239432",
      classType: "ghost",
      name: "Зловещий Призрак",
      attack: 8000,
      allHp: 30000000,
      currentHp: 30000000,
      rewards: {
        exp: 64000,
        silver: 128000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 10,
      bossEvasionChance: 26,
      string:
        "Жуткий призрак факультета Хитрости с выпученными пустыми глазами, вытянутым костлявым лицом, и в одеждах, запачканных кровью. Даже другие привидения школы стараются избегать его, что уж говорить о студентах...",
    },
  ],
  [
    {
      postImage: "photo-221829804_457239193",
      classType: "entity",
      name: "Обскур",
      attack: 4000,
      allHp: 10000000,
      currentHp: 10000000,
      rewards: {
        exp: 18000,
        silver: 36000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 13,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
    {
      postImage: "photo-221829804_457239193",
      classType: "entity",
      name: "Обскур",
      attack: 8000,
      allHp: 50000000,
      currentHp: 50000000,
      rewards: {
        exp: 54000,
        silver: 108000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 21,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
    {
      postImage: "photo-221829804_457239193",
      classType: "entity",
      name: "Обскур",
      attack: 16000,
      allHp: 150000000,
      currentHp: 150000000,
      rewards: {
        exp: 144000,
        silver: 288000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 29,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
  ],
  [
    {
      postImage: "photo-221829804_457239186",
      classType: "man",
      name: "Лже-маг",
      attack: 8000,
      allHp: 50000000,
      currentHp: 50000000,
      rewards: {
        exp: 50000,
        silver: 100000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 13,
      bossEvasionChance: 13,
      string:
        "Один из гнусных приспешников злых сил, украл образ влиятельного борца с темными магами с помощью зелья перевоплощения и теперь следит за деятельностью Школы Магии, чтобы в нужный момент нанести неожиданный удар в спину.",
    },
    {
      postImage: "photo-221829804_457239186",
      classType: "man",
      name: "Лже-маг",
      attack: 16000,
      allHp: 250000000,
      currentHp: 250000000,
      rewards: {
        exp: 150000,
        silver: 300000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 22,
      bossEvasionChance: 22,
      string:
        "Один из гнусных приспешников злых сил, украл образ влиятельного борца с темными магами с помощью зелья перевоплощения и теперь следит за деятельностью Школы Магии, чтобы в нужный момент нанести неожиданный удар в спину.",
    },
    {
      postImage: "photo-221829804_457239186",
      classType: "man",
      name: "Лже-маг",
      attack: 32000,
      allHp: 750000000,
      currentHp: 750000000,
      rewards: {
        exp: 400000,
        silver: 800000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 31,
      bossEvasionChance: 31,
      string:
        "Один из гнусных приспешников злых сил, украл образ влиятельного борца с темными магами с помощью зелья перевоплощения и теперь следит за деятельностью Школы Магии, чтобы в нужный момент нанести неожиданный удар в спину.",
    },
  ],
];

export const bosses2Array = [
  [
    {
      postImage: "photo-221829804_457239433",
      classType: "entity",
      name: "Кошмар",
      attack: 2000,
      allHp: 1000000,
      currentHp: 1000000,
      rewards: {
        exp: 5000,
        silver: 10000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 11,
      bossEvasionChance: 9,
      string:
        "Самое загадочное существо, обитающее в темных закоулках замка. Никто не знает его истинного обличия, так как Кошмар принимает форму того, чего больше всего в жизни боится противник перед ним.",
    },
    {
      postImage: "photo-221829804_457239433",
      classType: "entity",
      name: "Кошмар",
      attack: 4000,
      allHp: 5000000,
      currentHp: 5000000,
      rewards: {
        exp: 15000,
        silver: 30000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 17,
      bossEvasionChance: 9,
      string:
        "Самое загадочное существо, обитающее в темных закоулках замка. Никто не знает его истинного обличия, так как Кошмар принимает форму того, чего больше всего в жизни боится противник перед ним.",
    },
    {
      postImage: "photo-221829804_457239433",
      classType: "entity",
      name: "Кошмар",
      attack: 8000,
      allHp: 15000000,
      currentHp: 15000000,
      rewards: {
        exp: 40000,
        silver: 80000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 23,
      bossEvasionChance: 9,
      string:
        "Самое загадочное существо, обитающее в темных закоулках замка. Никто не знает его истинного обличия, так как Кошмар принимает форму того, чего больше всего в жизни боится противник перед ним.",
    },
  ],

  [
    {
      postImage: "photo-221829804_457239432",
      classType: "ghost",
      name: "Зловещий Призрак",
      attack: 4000,
      allHp: 5000000,
      currentHp: 5000000,
      rewards: {
        exp: 10000,
        silver: 20000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 10,
      bossEvasionChance: 12,
      string:
        "Жуткий призрак факультета Хитрости с выпученными пустыми глазами, вытянутым костлявым лицом, и в одеждах, запачканных кровью. Даже другие привидения школы стараются избегать его, что уж говорить о студентах...",
    },
    {
      postImage: "photo-221829804_457239432",
      classType: "ghost",
      name: "Зловещий Призрак",
      attack: 8000,
      allHp: 25000000,
      currentHp: 25000000,
      rewards: {
        exp: 30000,
        silver: 60000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 10,
      bossEvasionChance: 19,
      string:
        "Жуткий призрак факультета Хитрости с выпученными пустыми глазами, вытянутым костлявым лицом, и в одеждах, запачканных кровью. Даже другие привидения школы стараются избегать его, что уж говорить о студентах...",
    },

    {
      postImage: "photo-221829804_457239432",
      classType: "ghost",
      name: "Зловещий Призрак",
      attack: 16000,
      allHp: 75000000,
      currentHp: 75000000,
      rewards: {
        exp: 80000,
        silver: 160000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 10,
      bossEvasionChance: 26,
      string:
        "Жуткий призрак факультета Хитрости с выпученными пустыми глазами, вытянутым костлявым лицом, и в одеждах, запачканных кровью. Даже другие привидения школы стараются избегать его, что уж говорить о студентах...",
    },
  ],

  [
    {
      postImage: "photo-221829804_457239193",
      classType: "entity",
      name: "Обскур",
      attack: 8000,
      allHp: 25000000,
      currentHp: 25000000,
      rewards: {
        exp: 18000,
        silver: 36000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 13,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
    {
      postImage: "photo-221829804_457239193",
      classType: "entity",
      name: "Обскур",
      attack: 16000,
      allHp: 125000000,
      currentHp: 125000000,
      rewards: {
        exp: 54000,
        silver: 108000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 21,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
    {
      postImage: "photo-221829804_457239193",
      classType: "entity",
      name: "Обскур",
      attack: 32000,
      allHp: 375000000,
      currentHp: 375000000,
      rewards: {
        exp: 144000,
        silver: 288000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 29,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
  ],

  [
    {
      postImage: "photo-221829804_457239193",
      classType: "entity",
      name: "Обскур",
      attack: 16000,
      allHp: 100000000,
      currentHp: 100000000,
      rewards: {
        exp: 18000,
        silver: 36000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 13,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
    {
      postImage: "photo-221829804_457239193",
      classType: "entity",
      name: "Обскур",
      attack: 16000,
      allHp: 500000000,
      currentHp: 500000000,
      rewards: {
        exp: 54000,
        silver: 108000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 21,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
    {
      postImage: "photo-221829804_457239193",
      classType: "entity",
      name: "Обскур",
      attack: 32000,
      allHp: 1500000000,
      currentHp: 1500000000,
      rewards: {
        exp: 144000,
        silver: 288000,
      },
      time: {
        maxTime: 8,
      },
      soulsNeed: 2,
      bossKritChance: 29,
      bossEvasionChance: 11,
      string:
        "Молодой волшебник, который вынужден подавлять свои силы. Однажды он теряет контроль и принимает форму огромного разрушительного сгустка темной энергии, несущего смерть всему на своем пути.",
    },
  ],
];

export const clothes1 = {
  pants1: {
    category: "pants",
    src: "1.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 2,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  pants2: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 5,
    },
    category: "pants",
    src: "2.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 3,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  pants3: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 10,
    },
    category: "pants",
    src: "3.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 4000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 3,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  pants4: {
    lvlNeed: 5,
    category: "pants",
    src: "4.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 5000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 3,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  pants5: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 15,
    },
    category: "pants",
    src: "5.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 4,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  pants6: {
    lvlDiceNeed: 10,
    category: "pants",
    src: "6.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 8000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  pants7: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 10,
    },
    category: "pants",
    src: "7.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  pants8: {
    characSum: 1000,
    category: "pants",
    src: "8.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  pants9: {
    lvlNeed: 10,
    category: "pants",
    src: "9.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  pants10: {
    characSum: 2000,
    category: "pants",
    src: "10.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 4,
      protection: 5,
      accuracy: 4,
      evasion: 4,
      speed: 4,
    },
  },
  pants11: {
    lvlDiceNeed: 50,
    category: "pants",
    src: "11.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 16000,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 4,
      protection: 5,
      accuracy: 4,
      evasion: 4,
      speed: 4,
    },
  },
  pants12: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 20,
    },
    category: "pants",
    src: "12.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 8,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  pants13: {
    lvlDiceNeed: 100,
    category: "pants",
    src: "13.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 9,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  pants14: {
    lvlNeed: 15,
    category: "pants",
    src: "14.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 9,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  pants15: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 10,
    },
    category: "pants",
    src: "15.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 9,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  pants16: {
    characSum: 4000,
    category: "pants",
    src: "16.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  pants17: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 20,
    },
    category: "pants",
    src: "17.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 28000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 11,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  pants18: {
    lvlDiceNeed: 250,
    category: "pants",
    src: "18.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  pants19: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 10,
    },
    category: "pants",
    src: "19.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 34000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  pants20: {
    lvlNeed: 25,
    category: "pants",
    src: "20.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  pants21: {
    characSum: 8000,
    category: "pants",
    src: "21.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 14,
      accuracy: 12,
      evasion: 12,
      speed: 12,
    },
  },
  pants22: {
    lvlDiceNeed: 500,
    category: "pants",
    src: "22.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 15,
      accuracy: 12,
      evasion: 12,
      speed: 12,
    },
  },
  pants23: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 20,
    },
    category: "pants",
    src: "23.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 45000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 17,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  pants24: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 10,
    },
    category: "pants",
    src: "24.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pants25: {
    characSum: 12000,
    category: "pants",
    src: "25.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pants26: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 5,
    },
    category: "pants",
    src: "26.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 20,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  pants27: {
    lvlDiceNeed: 800,
    category: "pants",
    src: "27.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 65000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 20,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  pants28: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 2,
    },
    category: "pants",
    src: "28.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 25,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  pants29: {
    lvlDiceNeed: 1200,
    category: "pants",
    src: "29.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 86000,
    },
    characteristic: {
      health: 24,
      force: 24,
      power: 24,
      protection: 29,
      accuracy: 24,
      evasion: 24,
      speed: 24,
    },
  },
  pants30: {
    lvlDiceNeed: 2000,
    category: "pants",
    src: "30.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  pantsTreasure1: {
    category: "pants",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2114,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  pantsTreasure2: {
    category: "pants",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2124,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  pantsTreasure3: {
    category: "pants",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2134,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  pantsTreasure4: {
    category: "pants",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2144,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  pantsTreasure5: {
    category: "pants",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1914,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure6: {
    category: "pants",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1924,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure7: {
    category: "pants",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1934,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure8: {
    category: "pants",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1944,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure9: {
    category: "pants",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1954,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure10: {
    category: "pants",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1964,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure11: {
    category: "pants",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1974,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure12: {
    category: "pants",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1984,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  pantsTreasure13: {
    category: "pants",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1014,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },

  pantsTreasure14: {
    category: "pants",
    src: "Treasure/Treasure14.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1024,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },

  pantsTreasure15: {
    category: "pants",
    src: "Treasure/Treasure15.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1034,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pantsTreasure16: {
    category: "pants",
    src: "Treasure/Treasure16.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1044,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pantsTreasure17: {
    category: "pants",
    src: "Treasure/Treasure17.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1054,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pantsTreasure18: {
    category: "pants",
    src: "Treasure/Treasure18.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1064,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  pantsTreasure19: {
    category: "pants",
    src: "Treasure/Treasure19.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1074,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  pantsTreasure20: {
    category: "pants",
    src: "Treasure/Treasure20.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1084,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  pantsTreasure21: {
    category: "pants",
    src: "Treasure/Treasure21.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0214,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 40,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  pantsTreasure22: {
    category: "pants",
    src: "Treasure/Treasure22.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0224,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 40,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  pantsTreasure23: {
    category: "pants",
    src: "Treasure/Treasure23.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0234,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 40,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  pantsTreasure24: {
    category: "pants",
    src: "Treasure/Treasure24.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0234,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 40,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  pantsEvent1: {
    category: "pants",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Спаси лето",
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirt1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "shirt",
    src: "1.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 2,
      protection: 1,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  shirt2: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 6,
    },
    category: "shirt",
    src: "2.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 3,
      protection: 1,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  shirt3: {
    arenaWinsNeed: 100,
    category: "shirt",
    src: "3.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 4,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt4: {
    arenaDefWinsNeed: 5,
    category: "shirt",
    src: "4.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 3,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  shirt5: {
    collectionsNeed: 25,
    category: "shirt",
    src: "5.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 3,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt6: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 18,
    },
    category: "shirt",
    src: "6.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 4000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 3,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt7: {
    lvlNeed: 8,
    category: "shirt",
    src: "7.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 5000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 4,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt8: {
    arenaWinsNeed: 300,
    category: "shirt",
    src: "8.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 5000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 4,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt9: {
    collectionsNeed: 80,
    category: "shirt",
    src: "9.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 4,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  shirt10: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 25,
    },
    category: "shirt",
    src: "10.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 8000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 5,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  shirt11: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 8,
    },
    category: "shirt",
    src: "11.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 4,
      protection: 3,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  shirt12: {
    lvlNeed: 16,
    category: "shirt",
    src: "12.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 5,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  shirt13: {
    arenaWinsNeed: 800,
    category: "shirt",
    src: "13.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 5,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  shirt14: {
    arenaDefWinsNeed: 25,
    category: "shirt",
    src: "14.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 5,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  shirt15: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 15,
    },
    category: "shirt",
    src: "15.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 12000,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 5,
      protection: 4,
      accuracy: 4,
      evasion: 4,
      speed: 4,
    },
  },
  shirt16: {
    collectionsNeed: 250,
    category: "shirt",
    src: "16.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  shirt17: {
    lvlNeed: 25,
    category: "shirt",
    src: "17.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 15000,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 6,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  shirt18: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 7,
    },
    category: "shirt",
    src: "18.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 6,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  shirt19: {
    lvlNeed: 30,
    category: "shirt",
    src: "19.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 8,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  shirt20: {
    arenaWinsNeed: 1500,
    category: "shirt",
    src: "20.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 8,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  shirt21: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 13,
    },
    category: "shirt",
    src: "21.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 8,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  shirt22: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 20,
    },
    category: "shirt",
    src: "22.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  shirt23: {
    bossNeed: {
      name: "Кошмар",
      number: 4,
      count: 12,
    },
    category: "shirt",
    src: "23.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 25000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  shirt24: {
    arenaDefWinsNeed: 50,
    category: "shirt",
    src: "24.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },

    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  shirt25: {
    collectionsNeed: 800,
    category: "shirt",
    src: "25.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 28000,
    },
    characteristic: {
      health: 9,
      force: 9,
      power: 9,
      protection: 12,
      accuracy: 9,
      evasion: 9,
      speed: 9,
    },
  },
  shirt26: {
    lvlNeed: 35,
    category: "shirt",
    src: "26.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  shirt27: {
    bossNeed: {
      name: "Кошмар",
      number: 4,
      count: 18,
    },
    category: "shirt",
    src: "27.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 32000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  shirt28: {
    arenaWinsNeed: 3000,
    category: "shirt",
    src: "28.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 34000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  shirt29: {
    arenaDefWinsNeed: 100,
    category: "shirt",
    src: "29.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  shirt30: {
    bossNeed: {
      name: "Кошмар",
      number: 4,
      count: 24,
    },
    category: "shirt",
    src: "30.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  shirt31: {
    lvlNeed: 40,
    category: "shirt",
    src: "31.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000,
    },
    characteristic: {
      health: 13,
      force: 13,
      power: 13,
      protection: 15,
      accuracy: 13,
      evasion: 13,
      speed: 13,
    },
  },
  shirt32: {
    arenaDefWinsNeed: 250,
    category: "shirt",
    src: "32.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 42000,
    },
    characteristic: {
      health: 13,
      force: 13,
      power: 13,
      protection: 16,
      accuracy: 13,
      evasion: 13,
      speed: 13,
    },
  },
  shirt33: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 9,
    },
    category: "shirt",
    src: "33.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 44000,
    },
    characteristic: {
      health: 13,
      force: 13,
      power: 13,
      protection: 16,
      accuracy: 13,
      evasion: 13,
      speed: 13,
    },
  },
  shirt34: {
    lvlNeed: 50,
    category: "shirt",
    src: "34.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 46000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 17,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  shirt35: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 16,
    },
    category: "shirt",
    src: "35.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 17,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  shirt36: {
    arenaWinsNeed: 6000,
    category: "shirt",
    src: "36.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 17,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  shirt37: {
    collectionsNeed: 2500,
    category: "shirt",
    src: "37.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 18,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  shirt38: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 22,
    },
    category: "shirt",
    src: "38.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 55000,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  shirt39: {
    arenaDefWinsNeed: 500,
    category: "shirt",
    src: "39.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 55000,
    },
    characteristic: {
      health: 16,
      force: 16,
      power: 16,
      protection: 20,
      accuracy: 16,
      evasion: 16,
      speed: 16,
    },
  },
  shirt40: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 4,
    },
    category: "shirt",
    src: "40.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 16,
      force: 16,
      power: 16,
      protection: 19,
      accuracy: 16,
      evasion: 16,
      speed: 16,
    },
  },
  shirt41: {
    lvlNeed: 60,
    category: "shirt",
    src: "41.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 20,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  shirt42: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 8,
    },
    category: "shirt",
    src: "42.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 65000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 20,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  shirt43: {
    lvlNeed: 65,
    category: "shirt",
    src: "43.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 68000,
    },
    characteristic: {
      health: 18,
      force: 18,
      power: 18,
      protection: 22,
      accuracy: 18,
      evasion: 18,
      speed: 18,
    },
  },
  shirt44: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 12,
    },
    category: "shirt",
    src: "44.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 24,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  shirt45: {
    arenaWinsNeed: 10000,
    category: "shirt",
    src: "45.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 72000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 24,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  shirt46: {
    arenaDefWinsNeed: 1000,
    category: "shirt",
    src: "46.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 74000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 24,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  shirt47: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 1,
    },
    category: "shirt",
    src: "47.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 76000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 24,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  shirt48: {
    lvlNeed: 70,
    category: "shirt",
    src: "48.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 78000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 23,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  shirt49: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 3,
    },
    category: "shirt",
    src: "49.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 25,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  shirt50: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 13,
    },
    category: "shirt",
    src: "50.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 84000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 27,
      accuracy: 22,
      evasion: 22,
      speed: 22,
    },
  },
  shirt51: {
    arenaWinsNeed: 20000,
    category: "shirt",
    src: "51.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 88000,
    },
    characteristic: {
      health: 24,
      force: 24,
      power: 24,
      protection: 28,
      accuracy: 24,
      evasion: 24,
      speed: 24,
    },
  },
  shirt52: {
    collectionsNeed: 10000,
    category: "shirt",
    src: "52.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 95000,
    },
    characteristic: {
      health: 26,
      force: 26,
      power: 26,
      protection: 30,
      accuracy: 26,
      evasion: 26,
      speed: 26,
    },
  },
  shirt53: {
    arenaDefWinsNeed: 5000,
    category: "shirt",
    src: "53.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 38,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },

  shirtTreasure1: {
    category: "shirt",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2113,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  shirtTreasure2: {
    category: "shirt",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2123,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  shirtTreasure3: {
    category: "shirt",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2133,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  shirtTreasure4: {
    category: "shirt",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2133,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  shirtTreasure5: {
    category: "shirt",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1913,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure6: {
    category: "shirt",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1923,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure7: {
    category: "shirt",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1933,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure8: {
    category: "shirt",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1943,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure9: {
    category: "shirt",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1953,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure10: {
    category: "shirt",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1963,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure11: {
    category: "shirt",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1973,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure12: {
    category: "shirt",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1983,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 9,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  shirtTreasure13: {
    category: "shirt",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1013,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirtTreasure14: {
    category: "shirt",
    src: "Treasure/Treasure14.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1023,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirtTreasure15: {
    category: "shirt",
    src: "Treasure/Treasure15.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1033,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirtTreasure16: {
    category: "shirt",
    src: "Treasure/Treasure16.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1043,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirtTreasure17: {
    category: "shirt",
    src: "Treasure/Treasure17.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1053,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shirtTreasure18: {
    category: "shirt",
    src: "Treasure/Treasure18.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0313,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure19: {
    category: "shirt",
    src: "Treasure/Treasure19.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0323,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure20: {
    category: "shirt",
    src: "Treasure/Treasure20.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0333,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure21: {
    category: "shirt",
    src: "Treasure/Treasure21.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0343,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure22: {
    category: "shirt",
    src: "Treasure/Treasure22.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0353,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure23: {
    category: "shirt",
    src: "Treasure/Treasure23.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0363,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  shirtTreasure24: {
    category: "shirt",
    src: "Treasure/Treasure24.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0113,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  shirtTreasure25: {
    category: "shirt",
    src: "Treasure/Treasure25.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0123,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  shirtTreasure26: {
    category: "shirt",
    src: "Treasure/Treasure26.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0133,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  shirtTreasure27: {
    category: "shirt",
    src: "Treasure/Treasure27.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0143,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  shirtEvent1: {
    category: "shirt",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Спаси лето",
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  shoes1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "shoes",
    src: "1.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 1,
      accuracy: 1,
      evasion: 2,
      speed: 2,
    },
  },
  shoes2: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 5,
    },
    category: "shoes",
    src: "2.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 1,
      accuracy: 1,
      evasion: 3,
      speed: 3,
    },
  },
  shoes3: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 13,
    },
    category: "shoes",
    src: "3.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 5000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 2,
      accuracy: 2,
      evasion: 3,
      speed: 3,
    },
  },
  shoes4: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 7,
    },
    category: "shoes",
    src: "4.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 7000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 2,
      accuracy: 2,
      evasion: 4,
      speed: 4,
    },
  },
  shoes5: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 13,
    },
    category: "shoes",
    src: "5.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 3,
      accuracy: 3,
      evasion: 4,
      speed: 4,
    },
  },
  shoes6: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 9,
    },
    category: "shoes",
    src: "6.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 8,
      speed: 8,
    },
  },
  shoes7: {
    lvlNeed: 10,
    category: "shoes",
    src: "7.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },
  shoes8: {
    candyGetNeed: 100,
    category: "shoes",
    src: "8.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },
  shoes9: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 12,
    },
    category: "shoes",
    src: "9.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 7,
      speed: 7,
    },
  },
  shoes10: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 5,
    },
    category: "shoes",
    src: "10.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 24000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 8,
      speed: 8,
    },
  },
  shoes11: {
    lvlNeed: 20,
    category: "shoes",
    src: "11.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 12,
      speed: 12,
    },
  },
  shoes12: {
    candyGetNeed: 500,
    category: "shoes",
    src: "12.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 12,
      speed: 12,
    },
  },
  shoes13: {
    characSum: 10000,
    category: "shoes",
    src: "13.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 12,
      speed: 12,
    },
  },
  shoes14: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 9,
    },
    category: "shoes",
    src: "14.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 34000,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 11,
      accuracy: 11,
      evasion: 14,
      speed: 14,
    },
  },
  shoes15: {
    lvlNeed: 30,
    category: "shoes",
    src: "15.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 14,
      speed: 14,
    },
  },
  shoes16: {
    candyGetNeed: 1000,
    category: "shoes",
    src: "16.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 16,
      speed: 16,
    },
  },
  shoes17: {
    characSum: 20000,
    category: "shoes",
    src: "17.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 16,
      speed: 16,
    },
  },
  shoes18: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 7,
    },
    category: "shoes",
    src: "18.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 45000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 17,
      speed: 17,
    },
  },
  shoes19: {
    lvlNeed: 35,
    category: "shoes",
    src: "19.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 17,
      speed: 17,
    },
  },
  shoes20: {
    candyGetNeed: 2500,
    category: "shoes",
    src: "20.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 17,
      speed: 17,
    },
  },
  shoes21: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 5,
    },
    category: "shoes",
    src: "21.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 55000,
    },
    characteristic: {
      health: 16,
      force: 16,
      power: 16,
      protection: 16,
      accuracy: 16,
      evasion: 20,
      speed: 20,
    },
  },
  shoes22: {
    lvlNeed: 40,
    category: "shoes",
    src: "22.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 17,
      accuracy: 17,
      evasion: 20,
      speed: 20,
    },
  },
  shoes23: {
    candyGetNeed: 5000,
    category: "shoes",
    src: "23.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 17,
      accuracy: 17,
      evasion: 20,
      speed: 20,
    },
  },
  shoes24: {
    lvlNeed: 45,
    category: "shoes",
    src: "24.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 19,
      accuracy: 19,
      evasion: 22,
      speed: 22,
    },
  },
  shoes25: {
    characSum: 50000,
    category: "shoes",
    src: "25.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 19,
      accuracy: 19,
      evasion: 22,
      speed: 22,
    },
  },
  shoes26: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 4,
    },
    category: "shoes",
    src: "26.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 75000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 19,
      accuracy: 19,
      evasion: 23,
      speed: 23,
    },
  },
  shoes27: {
    lvlNeed: 55,
    category: "shoes",
    src: "27.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 23,
      speed: 23,
    },
  },
  shoes28: {
    candyGetNeed: 10000,
    category: "shoes",
    src: "28.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 23,
      speed: 23,
    },
  },
  shoes29: {
    lvlNeed: 60,
    category: "shoes",
    src: "29.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 95000,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 29,
      speed: 29,
    },
  },
  shoes30: {
    lvlNeed: 65,
    category: "shoes",
    src: "30.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },
  shoes31: {
    characSum: 100000,
    category: "shoes",
    src: "31.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 31,
      accuracy: 31,
      evasion: 36,
      speed: 36,
    },
  },

  shoesTreasure1: {
    category: "shoes",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2115,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 7,
    },
  },

  shoesTreasure2: {
    category: "shoes",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2125,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 7,
    },
  },

  shoesTreasure3: {
    category: "shoes",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1955,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },

  shoesTreasure4: {
    category: "shoes",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1945,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },

  shoesTreasure5: {
    category: "shoes",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1935,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },

  shoesTreasure6: {
    category: "shoes",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1925,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },

  shoesTreasure7: {
    category: "shoes",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1915,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 9,
    },
  },

  shoesTreasure8: {
    category: "shoes",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1015,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  shoesTreasure9: {
    category: "shoes",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1025,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  shoesTreasure10: {
    category: "shoes",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1035,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  shoesTreasure11: {
    category: "shoes",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1045,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  shoesTreasure12: {
    category: "shoes",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1055,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  shoesTreasure13: {
    category: "shoes",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0515,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 24,
      speed: 24,
    },
  },

  shoesTreasure14: {
    category: "shoes",
    src: "Treasure/Treasure14.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0315,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },

  shoesTreasure15: {
    category: "shoes",
    src: "Treasure/Treasure15.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0325,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },

  shoesTreasure16: {
    category: "shoes",
    src: "Treasure/Treasure16.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0335,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },

  shoesTreasure17: {
    category: "shoes",
    src: "Treasure/Treasure17.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0115,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 48,
      speed: 48,
    },
  },

  shoesTreasure18: {
    category: "shoes",
    src: "Treasure/Treasure18.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0125,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 48,
      speed: 48,
    },
  },

  shoesTreasure19: {
    category: "shoes",
    src: "Treasure/Treasure19.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0135,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 48,
      speed: 48,
    },
  },

  shoesEvent1: {
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Спаси лето",
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 20,
    },
  },

  head1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "head",
    src: "1.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 1,
      accuracy: 2,
      evasion: 1,
      speed: 1,
    },
  },
  head2: {
    bossNeed: {
      name: "Полтергейст",
      number: 1,
      count: 8,
    },
    category: "head",
    src: "2.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 1,
      accuracy: 3,
      evasion: 1,
      speed: 1,
    },
  },
  head3: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 8,
    },
    category: "head",
    src: "3.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 5000,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 2,
      accuracy: 3,
      evasion: 2,
      speed: 2,
    },
  },
  head4: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 11,
    },
    category: "head",
    src: "4.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 3,
      accuracy: 3,
      evasion: 4,
      speed: 3,
    },
  },
  head5: {
    game21Need: 50,
    category: "head",
    src: "5.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 8000,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 4,
      protection: 4,
      accuracy: 4,
      evasion: 5,
      speed: 4,
    },
  },
  head6: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 16,
    },
    category: "head",
    src: "6.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 10000,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 4,
      protection: 4,
      accuracy: 4,
      evasion: 5,
      speed: 4,
    },
  },
  head7: {
    classroomsNeed: 100,
    category: "head",
    src: "7.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 12000,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 6,
      speed: 5,
    },
  },
  head8: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 9,
    },
    category: "head",
    src: "8.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 14000,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 6,
      speed: 5,
    },
  },
  head9: {
    swap: {
      man: true,
      woman: true,
    },
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 17,
    },
    category: "head",
    src: "9.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 16000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 8,
      speed: 6,
    },
  },
  head10: {
    swap: {
      man: true,
      woman: true,
    },
    game21Need: 100,
    category: "head",
    src: "10.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 8,
      speed: 7,
    },
  },
  head11: {
    swap: {
      man: true,
      woman: true,
    },
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 10,
    },
    category: "head",
    src: "11.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  head12: {
    swap: {
      man: true,
      woman: true,
    },
    game21Need: 250,
    category: "head",
    src: "12.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  head13: {
    swap: {
      man: true,
      woman: true,
    },
    classroomsNeed: 500,
    category: "head",
    src: "13.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  head14: {
    swap: {
      man: true,
      woman: true,
    },
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 10,
    },
    category: "head",
    src: "14.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 12,
      speed: 10,
    },
  },
  head15: {
    game21Need: 500,
    category: "head",
    src: "15.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 31000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 12,
      speed: 10,
    },
  },
  head16: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 15,
    },
    category: "head",
    src: "16.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 35000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 14,
      speed: 12,
    },
  },
  head17: {
    classroomsNeed: 1000,
    category: "head",
    src: "17.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 15,
      speed: 12,
    },
  },
  head18: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 20,
    },
    category: "head",
    src: "18.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 16,
      speed: 14,
    },
  },
  head19: {
    game21Need: 1000,
    category: "head",
    src: "19.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 44000,
    },
    characteristic: {
      health: 13,
      force: 13,
      power: 13,
      protection: 13,
      accuracy: 13,
      evasion: 15,
      speed: 13,
    },
  },
  head20: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 7,
    },
    category: "head",
    src: "20.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 45000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 16,
      speed: 14,
    },
  },
  head21: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 11,
    },
    category: "head",
    src: "21.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 16,
      speed: 14,
    },
  },
  head22: {
    game21Need: 2500,
    category: "head",
    src: "22.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 58000,
    },
    characteristic: {
      health: 16,
      force: 16,
      power: 16,
      protection: 16,
      accuracy: 16,
      evasion: 19,
      speed: 16,
    },
  },
  head23: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 3,
    },
    category: "head",
    src: "23.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 17,
      accuracy: 17,
      evasion: 20,
      speed: 17,
    },
  },
  head24: {
    swap: {
      man: true,
      woman: true,
    },
    classroomsNeed: 5000,
    category: "head",
    src: "24.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 65000,
    },
    characteristic: {
      health: 18,
      force: 18,
      power: 18,
      protection: 18,
      accuracy: 18,
      evasion: 21,
      speed: 18,
    },
  },
  head25: {
    swap: {
      man: true,
      woman: true,
    },
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 5,
    },
    category: "head",
    src: "25.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 22,
      accuracy: 22,
      evasion: 25,
      speed: 22,
    },
  },
  head26: {
    swap: {
      man: true,
      woman: true,
    },
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 11,
    },
    category: "head",
    src: "26.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 23,
      force: 23,
      power: 23,
      protection: 23,
      accuracy: 23,
      evasion: 26,
      speed: 23,
    },
  },
  head27: {
    swap: {
      man: true,
      woman: true,
    },
    game21Need: 5000,
    category: "head",
    src: "27.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 88000,
    },
    characteristic: {
      health: 24,
      force: 24,
      power: 24,
      protection: 24,
      accuracy: 24,
      evasion: 28,
      speed: 24,
    },
  },
  head28: {
    swap: {
      man: true,
      woman: true,
    },
    classroomsNeed: 10000,
    category: "head",
    src: "28.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 34,
      speed: 30,
    },
  },
  head29: {
    swap: {
      man: true,
      woman: true,
    },
    game21Need: 10000,
    category: "head",
    src: "29.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 32,
      force: 32,
      power: 32,
      protection: 32,
      accuracy: 32,
      evasion: 37,
      speed: 32,
    },
  },

  headTreasure1: {
    category: "head",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2116,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },

  headTreasure2: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2116,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },

  headTreasure3: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2126,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },

  headTreasure4: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2136,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },

  headTreasure5: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1916,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 7,
    },
  },

  headTreasure6: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1916,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 9,
      speed: 7,
    },
  },

  headTreasure7: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1026,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 15,
    },
  },

  headTreasure8: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1036,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 15,
    },
  },

  headTreasure9: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1046,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 15,
    },
  },

  headTreasure10: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0516,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 24,
      speed: 20,
    },
  },

  headTreasure11: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0316,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },

  headTreasure12: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0326,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },

  headTreasure13: {
    category: "head",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0116,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 45,
      speed: 40,
    },
  },

  headEvent1: {
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Спаси лето",
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 20,
      speed: 15,
    },
  },

  wand1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "wand",
    src: "1.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 10,
    },
    characteristic: {
      health: 1,
      force: 2,
      power: 2,
      protection: 1,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  wand2: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 12,
    },
    category: "wand",
    src: "2.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 15,
    },
    characteristic: {
      health: 2,
      force: 3,
      power: 3,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  wand3: {
    collectionsMoveNeed: 5,
    category: "wand",
    src: "3.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 20,
    },
    characteristic: {
      health: 3,
      force: 4,
      power: 4,
      protection: 3,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  wand4: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 20,
    },
    category: "wand",
    src: "4.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 20,
    },
    characteristic: {
      health: 3,
      force: 4,
      power: 4,
      protection: 3,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  wand5: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 35,
    },
    category: "wand",
    src: "5.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 25,
    },
    characteristic: {
      health: 4,
      force: 5,
      power: 5,
      protection: 4,
      accuracy: 4,
      evasion: 4,
      speed: 4,
    },
  },
  wand6: {
    collectionsMoveNeed: 25,
    category: "wand",
    src: "6.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 30,
    },
    characteristic: {
      health: 5,
      force: 7,
      power: 7,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  wand7: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 10,
    },
    category: "wand",
    src: "7.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 50,
    },
    characteristic: {
      health: 6,
      force: 7,
      power: 7,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  wand8: {
    collectionsMoveNeed: 100,
    category: "wand",
    src: "8.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 50,
    },
    characteristic: {
      health: 6,
      force: 7,
      power: 7,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  wand9: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 17,
    },
    category: "wand",
    src: "9.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 55,
    },
    characteristic: {
      health: 6,
      force: 7,
      power: 7,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  wand10: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 28,
    },
    category: "wand",
    src: "10.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 70,
    },
    characteristic: {
      health: 7,
      force: 9,
      power: 9,
      protection: 7,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  wand11: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 35,
    },
    category: "wand",
    src: "11.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 75,
    },
    characteristic: {
      health: 7,
      force: 9,
      power: 9,
      protection: 7,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  wand12: {
    collectionsMoveNeed: 250,
    category: "wand",
    src: "12.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 80,
    },
    characteristic: {
      health: 8,
      force: 10,
      power: 10,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  wand13: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 13,
    },
    category: "wand",
    src: "13.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 85,
    },
    characteristic: {
      health: 8,
      force: 10,
      power: 10,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  wand14: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 21,
    },
    category: "wand",
    src: "14.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 90,
    },
    characteristic: {
      health: 8,
      force: 10,
      power: 10,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  wand15: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 33,
    },
    category: "wand",
    src: "15.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 100,
    },
    characteristic: {
      health: 10,
      force: 12,
      power: 12,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  wand16: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 9,
    },
    category: "wand",
    src: "16.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 120,
    },
    characteristic: {
      health: 11,
      force: 13,
      power: 13,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  wand17: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 16,
    },
    category: "wand",
    src: "17.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 140,
    },
    characteristic: {
      health: 12,
      force: 15,
      power: 15,
      protection: 12,
      accuracy: 12,
      evasion: 12,
      speed: 12,
    },
  },
  wand18: {
    collectionsMoveNeed: 500,
    category: "wand",
    src: "18.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 150,
    },
    characteristic: {
      health: 14,
      force: 17,
      power: 17,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  wand19: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 25,
    },
    category: "wand",
    src: "19.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 160,
    },
    characteristic: {
      health: 14,
      force: 17,
      power: 17,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  wand20: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 8,
    },
    category: "wand",
    src: "20.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 200,
    },
    characteristic: {
      health: 17,
      force: 20,
      power: 20,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  wand21: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 14,
    },
    category: "wand",
    src: "21.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 220,
    },
    characteristic: {
      health: 18,
      force: 21,
      power: 21,
      protection: 18,
      accuracy: 18,
      evasion: 18,
      speed: 18,
    },
  },
  wand22: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 19,
    },
    category: "wand",
    src: "22.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 240,
    },
    characteristic: {
      health: 19,
      force: 22,
      power: 22,
      protection: 19,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  wand23: {
    collectionsMoveNeed: 1500,
    category: "wand",
    src: "23.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 250,
    },
    characteristic: {
      health: 19,
      force: 22,
      power: 22,
      protection: 19,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  wand24: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 6,
    },
    category: "wand",
    src: "24.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 280,
    },
    characteristic: {
      health: 19,
      force: 22,
      power: 22,
      protection: 19,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  wand25: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 11,
    },
    category: "wand",
    src: "25.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 320,
    },
    characteristic: {
      health: 20,
      force: 24,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },

  wand26: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 17,
    },
    category: "wand",
    src: "26.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 360,
    },
    characteristic: {
      health: 21,
      force: 25,
      power: 25,
      protection: 21,
      accuracy: 21,
      evasion: 21,
      speed: 21,
    },
  },
  wand27: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 6,
    },
    category: "wand",
    src: "27.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 450,
    },
    characteristic: {
      health: 23,
      force: 28,
      power: 28,
      protection: 23,
      accuracy: 23,
      evasion: 23,
      speed: 23,
    },
  },
  wand28: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 8,
    },
    category: "wand",
    src: "28.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 500,
    },
    characteristic: {
      health: 25,
      force: 30,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  wand29: {
    collectionsMoveNeed: 5000,
    category: "wand",
    src: "29.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 600,
    },
    characteristic: {
      health: 30,
      force: 35,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  wandTreasure1: {
    category: "wand",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2131,
    },
    characteristic: {
      health: 5,
      force: 7,
      power: 7,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  wandTreasure2: {
    category: "wand",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2121,
    },
    characteristic: {
      health: 5,
      force: 7,
      power: 7,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  wandTreasure3: {
    category: "wand",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2111,
    },
    characteristic: {
      health: 5,
      force: 7,
      power: 7,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  wandTreasure4: {
    category: "wand",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1021,
    },
    characteristic: {
      health: 11,
      force: 13,
      power: 13,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  wandTreasure5: {
    category: "wand",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1011,
    },
    characteristic: {
      health: 11,
      force: 13,
      power: 13,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  wandTreasure6: {
    category: "wand",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0521,
    },
    characteristic: {
      health: 20,
      force: 24,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  wandTreasure7: {
    category: "wand",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0511,
    },
    characteristic: {
      health: 20,
      force: 24,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  wandTreasure8: {
    category: "wand",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0321,
    },
    characteristic: {
      health: 30,
      force: 35,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  wandTreasure9: {
    category: "wand",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0311,
    },
    characteristic: {
      health: 30,
      force: 35,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  wandTreasure10: {
    category: "wand",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0231,
    },
    characteristic: {
      health: 35,
      force: 40,
      power: 40,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },
  wandTreasure11: {
    category: "wand",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0221,
    },
    characteristic: {
      health: 35,
      force: 40,
      power: 40,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },
  wandTreasure12: {
    category: "wand",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0211,
    },
    characteristic: {
      health: 35,
      force: 40,
      power: 40,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },
  wandTreasure13: {
    category: "wand",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0121,
    },
    characteristic: {
      health: 40,
      force: 50,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  wandTreasure14: {
    category: "wand",
    src: "Treasure/Treasure14.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0111,
    },
    characteristic: {
      health: 40,
      force: 50,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  wandTreasure15: {
    category: "wand",
    src: "Treasure/Treasure15.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.00511,
    },
    characteristic: {
      health: 50,
      force: 60,
      power: 60,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },

  wandPrize1: {
    category: "wand",
    src: "Prize/Prize1.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 10,
      force: 300,
      power: 300,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  wandPrize2: {
    category: "wand",
    src: "Prize/Prize2.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 5,
      force: 150,
      power: 150,
      protection: 5,
      accuracy: 5,
      evasion: 150,
      speed: 5,
    },
  },
  wandPrize3: {
    category: "wand",
    src: "Prize/Prize3.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 20,
      force: 200,
      power: 200,
      protection: 20,
      accuracy: 20,
      evasion: 200,
      speed: 20,
    },
  },
  wandPrize4: {
    category: "wand",
    src: "Prize/Prize4.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 30,
      force: 300,
      power: 300,
      protection: 30,
      accuracy: 30,
      evasion: 300,
      speed: 30,
    },
  },
  wandPrize5: {
    category: "wand",
    src: "Prize/Prize5.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 23,
      force: 500,
      power: 23,
      protection: 500,
      accuracy: 23,
      evasion: 23,
      speed: 23,
    },
  },

  wandEvent1: {
    category: "wand",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Спаси лето",
    characteristic: {
      health: 15,
      force: 20,
      power: 20,
      protection: 15,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  hand1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "hand",
    src: "1.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 2000,
    },
    characteristic: {
      health: 2,
      force: 1,
      power: 1,
      protection: 1,
      accuracy: 1,
      evasion: 1,
      speed: 1,
    },
  },
  hand2: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 12,
    },
    category: "hand",
    src: "2.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 3000,
    },
    characteristic: {
      health: 3,
      force: 2,
      power: 2,
      protection: 2,
      accuracy: 2,
      evasion: 2,
      speed: 2,
    },
  },
  hand3: {
    collectionsGetNeed: 5,
    category: "hand",
    src: "3.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 4000,
    },
    characteristic: {
      health: 4,
      force: 3,
      power: 3,
      protection: 3,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  hand4: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 22,
    },
    category: "hand",
    src: "4.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 6000,
    },
    characteristic: {
      health: 4,
      force: 3,
      power: 3,
      protection: 3,
      accuracy: 3,
      evasion: 3,
      speed: 3,
    },
  },
  hand5: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 32,
    },
    category: "hand",
    src: "5.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 8000,
    },
    characteristic: {
      health: 5,
      force: 4,
      power: 4,
      protection: 4,
      accuracy: 4,
      evasion: 4,
      speed: 4,
    },
  },
  hand6: {
    collectionsGetNeed: 25,
    category: "hand",
    src: "6.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 12000,
    },
    characteristic: {
      health: 7,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },
  hand7: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 9,
    },
    category: "hand",
    src: "7.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 16000,
    },
    characteristic: {
      health: 7,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  hand8: {
    collectionsGetNeed: 100,
    category: "hand",
    src: "8.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 18000,
    },
    characteristic: {
      health: 7,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  hand9: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 15,
    },
    category: "hand",
    src: "9.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 7,
      force: 6,
      power: 6,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  hand10: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 24,
    },
    category: "hand",
    src: "10.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 22000,
    },
    characteristic: {
      health: 9,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  hand11: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 32,
    },
    category: "hand",
    src: "11.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 24000,
    },
    characteristic: {
      health: 9,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },
  hand12: {
    collectionsGetNeed: 250,
    category: "hand",
    src: "12.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 28000,
    },
    characteristic: {
      health: 10,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  hand13: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 14,
    },
    category: "hand",
    src: "13.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  hand14: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 24,
    },
    category: "hand",
    src: "14.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 32000,
    },
    characteristic: {
      health: 10,
      force: 8,
      power: 8,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  hand15: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 36,
    },
    category: "hand",
    src: "15.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 36000,
    },
    characteristic: {
      health: 12,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  hand16: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 9,
    },
    category: "hand",
    src: "16.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 38000,
    },
    characteristic: {
      health: 13,
      force: 11,
      power: 11,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },
  hand17: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 16,
    },
    category: "hand",
    src: "17.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 42000,
    },
    characteristic: {
      health: 15,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 12,
      speed: 12,
    },
  },
  hand18: {
    collectionsGetNeed: 500,
    category: "hand",
    src: "18.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 50000,
    },
    characteristic: {
      health: 17,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  hand19: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 30,
    },
    category: "hand",
    src: "19.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 54000,
    },
    characteristic: {
      health: 17,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  hand20: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 12,
    },
    category: "hand",
    src: "20.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 62000,
    },
    characteristic: {
      health: 20,
      force: 17,
      power: 17,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  hand21: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 21,
    },
    category: "hand",
    src: "21.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 68000,
    },
    characteristic: {
      health: 22,
      force: 19,
      power: 19,
      protection: 19,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  hand22: {
    collectionsGetNeed: 1500,
    category: "hand",
    src: "22.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 72000,
    },
    characteristic: {
      health: 22,
      force: 19,
      power: 19,
      protection: 19,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  hand23: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 13,
    },
    category: "hand",
    src: "23.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 76000,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },

  hand24: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 25,
    },
    category: "hand",
    src: "24.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 80000,
    },
    characteristic: {
      health: 25,
      force: 21,
      power: 21,
      protection: 21,
      accuracy: 21,
      evasion: 21,
      speed: 21,
    },
  },
  hand25: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 13,
    },
    category: "hand",
    src: "25.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 90000,
    },
    characteristic: {
      health: 30,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },

  hand26: {
    collectionsGetNeed: 5000,
    category: "hand",
    src: "26.png",
    lvl: 0,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 35,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  handTreasure1: {
    category: "hand",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2137,
    },
    characteristic: {
      health: 7,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  handTreasure2: {
    category: "hand",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2127,
    },
    characteristic: {
      health: 7,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  handTreasure3: {
    category: "hand",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2117,
    },
    characteristic: {
      health: 7,
      force: 5,
      power: 5,
      protection: 5,
      accuracy: 5,
      evasion: 5,
      speed: 5,
    },
  },

  handTreasure4: {
    category: "hand",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1917,
    },
    characteristic: {
      health: 9,
      force: 7,
      power: 7,
      protection: 7,
      accuracy: 7,
      evasion: 7,
      speed: 7,
    },
  },

  handTreasure5: {
    category: "hand",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1517,
    },
    characteristic: {
      health: 12,
      force: 9,
      power: 9,
      protection: 9,
      accuracy: 9,
      evasion: 9,
      speed: 9,
    },
  },

  handTreasure6: {
    category: "hand",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1027,
    },
    characteristic: {
      health: 14,
      force: 11,
      power: 11,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },

  handTreasure7: {
    category: "hand",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1017,
    },
    characteristic: {
      health: 14,
      force: 11,
      power: 11,
      protection: 11,
      accuracy: 11,
      evasion: 11,
      speed: 11,
    },
  },

  handTreasure8: {
    category: "hand",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0537,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },

  handTreasure9: {
    category: "hand",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0527,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },

  handTreasure10: {
    category: "hand",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0517,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },

  handTreasure11: {
    category: "hand",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0147,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  handTreasure12: {
    category: "hand",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0137,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  handTreasure13: {
    category: "hand",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0127,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  handTreasure14: {
    category: "hand",
    src: "Treasure/Treasure14.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0117,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },

  handEvent1: {
    category: "hand",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Спаси лето",
    characteristic: {
      health: 20,
      force: 15,
      power: 15,
      protection: 15,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },

  broom1: {
    bossNeed: {
      name: "Пикси",
      number: 0,
      count: 0,
    },
    category: "broom",
    src: "1.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 10,
    },
    characteristic: {
      health: 1,
      force: 1,
      power: 1,
      protection: 2,
      accuracy: 1,
      evasion: 2,
      speed: 1,
    },
  },
  broom2: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 12,
    },
    category: "broom",
    src: "2.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 15,
    },
    characteristic: {
      health: 2,
      force: 2,
      power: 2,
      protection: 3,
      accuracy: 2,
      evasion: 3,
      speed: 2,
    },
  },
  broom3: {
    candyMoveNeed: 10,
    category: "broom",
    src: "3.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 20,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 4,
      speed: 3,
    },
  },
  broom4: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 20,
    },
    category: "broom",
    src: "4.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 20,
    },
    characteristic: {
      health: 3,
      force: 3,
      power: 3,
      protection: 4,
      accuracy: 3,
      evasion: 4,
      speed: 3,
    },
  },
  broom5: {
    bossNeed: {
      name: "Полтергейста",
      number: 1,
      count: 35,
    },
    category: "broom",
    src: "5.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 25,
    },
    characteristic: {
      health: 4,
      force: 4,
      power: 4,
      protection: 5,
      accuracy: 4,
      evasion: 5,
      speed: 4,
    },
  },
  broom6: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 12,
    },
    category: "broom",
    src: "6.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 50,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 6,
      protection: 7,
      accuracy: 6,
      evasion: 7,
      speed: 6,
    },
  },
  broom7: {
    candyMoveNeed: 50,
    category: "broom",
    src: "7.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 50,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 8,
      accuracy: 7,
      evasion: 8,
      speed: 7,
    },
  },
  broom8: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 18,
    },
    category: "broom",
    src: "8.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 55,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 8,
      accuracy: 7,
      evasion: 8,
      speed: 7,
    },
  },
  broom9: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 29,
    },
    category: "broom",
    src: "9.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 70,
    },
    characteristic: {
      health: 7,
      force: 7,
      power: 7,
      protection: 8,
      accuracy: 7,
      evasion: 8,
      speed: 7,
    },
  },
  broom10: {
    bossNeed: {
      name: "Плаксу Миртл",
      number: 2,
      count: 36,
    },
    category: "broom",
    src: "10.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 75,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  broom11: {
    candyMoveNeed: 200,
    category: "broom",
    src: "11.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 80,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  broom12: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 14,
    },
    category: "broom",
    src: "12.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 85,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 10,
      speed: 8,
    },
  },
  broom13: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 22,
    },
    category: "broom",
    src: "13.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 90,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 12,
      accuracy: 10,
      evasion: 12,
      speed: 10,
    },
  },
  broom14: {
    bossNeed: {
      name: "Жуткого Завхоза",
      number: 3,
      count: 34,
    },
    category: "broom",
    src: "14.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 100,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 12,
      accuracy: 10,
      evasion: 12,
      speed: 10,
    },
  },
  broom15: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 10,
    },
    category: "broom",
    src: "15.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 120,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 13,
      accuracy: 11,
      evasion: 13,
      speed: 11,
    },
  },
  broom16: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 17,
    },
    category: "broom",
    src: "16.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 140,
    },
    characteristic: {
      health: 13,
      force: 13,
      power: 13,
      protection: 15,
      accuracy: 13,
      evasion: 15,
      speed: 13,
    },
  },
  broom17: {
    candyMoveNeed: 600,
    category: "broom",
    src: "17.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 150,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 16,
      accuracy: 14,
      evasion: 16,
      speed: 14,
    },
  },
  broom18: {
    bossNeed: {
      name: "Кошмара",
      number: 4,
      count: 26,
    },
    category: "broom",
    src: "18.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 160,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 16,
      accuracy: 14,
      evasion: 16,
      speed: 14,
    },
  },
  broom19: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 9,
    },
    category: "broom",
    src: "19.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 200,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 17,
      protection: 20,
      accuracy: 17,
      evasion: 20,
      speed: 17,
    },
  },
  broom20: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 16,
    },
    category: "broom",
    src: "20.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 220,
    },
    characteristic: {
      health: 18,
      force: 18,
      power: 18,
      protection: 20,
      accuracy: 18,
      evasion: 20,
      speed: 18,
    },
  },
  broom21: {
    bossNeed: {
      name: "Зловещего Призрака",
      number: 5,
      count: 20,
    },
    category: "broom",
    src: "21.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 240,
    },
    characteristic: {
      health: 18,
      force: 18,
      power: 18,
      protection: 20,
      accuracy: 18,
      evasion: 20,
      speed: 18,
    },
  },
  broom22: {
    candyMoveNeed: 1500,
    category: "broom",
    src: "22.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 250,
    },
    characteristic: {
      health: 18,
      force: 18,
      power: 18,
      protection: 20,
      accuracy: 18,
      evasion: 20,
      speed: 18,
    },
  },
  broom23: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 7,
    },
    category: "broom",
    src: "23.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 280,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 22,
      accuracy: 19,
      evasion: 22,
      speed: 19,
    },
  },
  broom24: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 12,
    },
    category: "broom",
    src: "24.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 320,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 22,
      accuracy: 19,
      evasion: 22,
      speed: 19,
    },
  },

  broom25: {
    bossNeed: {
      name: "Обскура",
      number: 6,
      count: 18,
    },
    category: "broom",
    src: "25.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 360,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 23,
      accuracy: 20,
      evasion: 23,
      speed: 20,
    },
  },
  broom26: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 7,
    },
    category: "broom",
    src: "26.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 450,
    },
    characteristic: {
      health: 21,
      force: 21,
      power: 21,
      protection: 27,
      accuracy: 21,
      evasion: 27,
      speed: 21,
    },
  },
  broom27: {
    bossNeed: {
      name: "Лже-мага",
      number: 7,
      count: 9,
    },
    category: "broom",
    src: "27.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 500,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 28,
      accuracy: 22,
      evasion: 28,
      speed: 22,
    },
  },

  broom28: {
    lvlNeed: 60,
    category: "broom",
    src: "28.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 550,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 30,
      speed: 25,
    },
  },

  broom29: {
    candyMoveNeed: 5000,
    category: "broom",
    src: "29.png",
    lvl: 0,
    price: {
      property: "gold",
      name: "золота",
      count: 600,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },

  broomTreasure1: {
    category: "broom",
    src: "Treasure/Treasure1.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2132,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },
  broomTreasure2: {
    category: "broom",
    src: "Treasure/Treasure2.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2122,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },
  broomTreasure3: {
    category: "broom",
    src: "Treasure/Treasure3.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.2112,
    },
    characteristic: {
      health: 5,
      force: 5,
      power: 5,
      protection: 7,
      accuracy: 5,
      evasion: 7,
      speed: 5,
    },
  },
  broomTreasure4: {
    category: "broom",
    src: "Treasure/Treasure4.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1032,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 14,
      speed: 11,
    },
  },
  broomTreasure5: {
    category: "broom",
    src: "Treasure/Treasure5.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1022,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 14,
      speed: 11,
    },
  },
  broomTreasure6: {
    category: "broom",
    src: "Treasure/Treasure6.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.1012,
    },
    characteristic: {
      health: 11,
      force: 11,
      power: 11,
      protection: 14,
      accuracy: 11,
      evasion: 14,
      speed: 11,
    },
  },
  broomTreasure7: {
    category: "broom",
    src: "Treasure/Treasure7.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0342,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  broomTreasure8: {
    category: "broom",
    src: "Treasure/Treasure8.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0332,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  broomTreasure9: {
    category: "broom",
    src: "Treasure/Treasure9.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0322,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  broomTreasure10: {
    category: "broom",
    src: "Treasure/Treasure10.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0312,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  broomTreasure11: {
    category: "broom",
    src: "Treasure/Treasure11.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0112,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 50,
      speed: 40,
    },
  },
  broomTreasure12: {
    category: "broom",
    src: "Treasure/Treasure12.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.0112,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 50,
      speed: 40,
    },
  },
  broomTreasure13: {
    category: "broom",
    src: "Treasure/Treasure13.png",
    lvl: 0,
    treasure: {
      lvl: 0,
      chance: 0.00512,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 60,
      speed: 50,
    },
  },

  broomPrize1: {
    category: "broom",
    src: "Prize/Prize1.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 500,
      evasion: 500,
      speed: 30,
    },
  },

  broomPrize2: {
    category: "broom",
    src: "Prize/Prize2.png",
    lvl: 0,
    isPrize: true,
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 550,
      accuracy: 40,
      evasion: 550,
      speed: 40,
    },
  },
  broomEvent1: {
    category: "broom",
    src: "Event/Event1.png",
    lvl: 0,
    event: "Спаси лето",
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 20,
      accuracy: 15,
      evasion: 20,
      speed: 15,
    },
  },
};

export const clothes2 = {
  "shirt1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "1.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 8,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  "shirt2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "2.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 8,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  "shirt3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "3.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 8,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  "shirt4-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "4.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 20000,
    },
    characteristic: {
      health: 6,
      force: 6,
      power: 8,
      protection: 6,
      accuracy: 6,
      evasion: 6,
      speed: 6,
    },
  },
  "shirt5-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "shirt",
    src: "5.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 10,
      protection: 8,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  "shirt6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "6.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 20,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  "shirt7-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "7.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 20,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  "shirt8-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "8.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 20,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  "shirt9-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "9.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 60000,
    },
    characteristic: {
      health: 17,
      force: 17,
      power: 20,
      protection: 17,
      accuracy: 17,
      evasion: 17,
      speed: 17,
    },
  },
  "shirt10-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "10.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 104000,
    },
    characteristic: {
      health: 29,
      force: 29,
      power: 34,
      protection: 29,
      accuracy: 29,
      evasion: 29,
      speed: 29,
    },
  },
  "shirt11-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "11.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 104000,
    },
    characteristic: {
      health: 29,
      force: 29,
      power: 34,
      protection: 29,
      accuracy: 29,
      evasion: 29,
      speed: 29,
    },
  },
  "shirt12-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "12.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 104000,
    },
    characteristic: {
      health: 29,
      force: 29,
      power: 34,
      protection: 29,
      accuracy: 29,
      evasion: 29,
      speed: 29,
    },
  },
  "shirt13-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "13.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 104000,
    },
    characteristic: {
      health: 29,
      force: 29,
      power: 34,
      protection: 29,
      accuracy: 29,
      evasion: 29,
      speed: 29,
    },
  },
  "shirt14-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "14.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 145000,
    },
    characteristic: {
      health: 42,
      force: 42,
      power: 49,
      protection: 42,
      accuracy: 42,
      evasion: 42,
      speed: 42,
    },
  },
  "shirt15-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "15.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 145000,
    },
    characteristic: {
      health: 42,
      force: 42,
      power: 49,
      protection: 42,
      accuracy: 42,
      evasion: 42,
      speed: 42,
    },
  },
  "shirt16-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "16.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 145000,
    },
    characteristic: {
      health: 42,
      force: 42,
      power: 49,
      protection: 42,
      accuracy: 42,
      evasion: 42,
      speed: 42,
    },
  },
  "shirt17-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "17.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 145000,
    },
    characteristic: {
      health: 42,
      force: 42,
      power: 49,
      protection: 42,
      accuracy: 42,
      evasion: 42,
      speed: 42,
    },
  },
  "shirt18-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "shirt",
    src: "18.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 150000,
    },
    characteristic: {
      health: 44,
      force: 44,
      power: 49,
      protection: 44,
      accuracy: 44,
      evasion: 44,
      speed: 44,
    },
  },
  "shirt19-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "19.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 185000,
    },
    characteristic: {
      health: 55,
      force: 55,
      power: 63,
      protection: 55,
      accuracy: 55,
      evasion: 55,
      speed: 55,
    },
  },
  "shirt20-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "20.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 185000,
    },
    characteristic: {
      health: 55,
      force: 55,
      power: 63,
      protection: 55,
      accuracy: 55,
      evasion: 55,
      speed: 55,
    },
  },
  "shirt21-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "21.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 185000,
    },
    characteristic: {
      health: 55,
      force: 55,
      power: 63,
      protection: 55,
      accuracy: 55,
      evasion: 55,
      speed: 55,
    },
  },
  "shirt22-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "22.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 185000,
    },
    characteristic: {
      health: 55,
      force: 55,
      power: 63,
      protection: 55,
      accuracy: 55,
      evasion: 55,
      speed: 55,
    },
  },
  "shirt23-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "23.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 228000,
    },
    characteristic: {
      health: 68,
      force: 68,
      power: 76,
      protection: 68,
      accuracy: 68,
      evasion: 68,
      speed: 68,
    },
  },
  "shirt24-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "24.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 228000,
    },
    characteristic: {
      health: 68,
      force: 68,
      power: 76,
      protection: 68,
      accuracy: 68,
      evasion: 68,
      speed: 68,
    },
  },
  "shirt25-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "25.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 228000,
    },
    characteristic: {
      health: 68,
      force: 68,
      power: 76,
      protection: 68,
      accuracy: 68,
      evasion: 68,
      speed: 68,
    },
  },
  "shirt26-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "26.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 228000,
    },
    characteristic: {
      health: 68,
      force: 68,
      power: 76,
      protection: 68,
      accuracy: 68,
      evasion: 68,
      speed: 68,
    },
  },
  "shirt27-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "27.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 235000,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "shirt28-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "28.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 235000,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "shirt29-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "29.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 235000,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "shirt30-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "30.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 235000,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },
  "shirt31-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.04,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "31.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 276000,
    },
    characteristic: {
      health: 93,
      force: 93,
      power: 103,
      protection: 93,
      accuracy: 93,
      evasion: 93,
      speed: 93,
    },
  },
  "shirt32-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.03,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "32.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 276000,
    },
    characteristic: {
      health: 93,
      force: 93,
      power: 103,
      protection: 93,
      accuracy: 93,
      evasion: 93,
      speed: 93,
    },
  },
  "shirt33-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.02,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "33.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 276000,
    },
    characteristic: {
      health: 93,
      force: 93,
      power: 103,
      protection: 93,
      accuracy: 93,
      evasion: 93,
      speed: 93,
    },
  },
  "shirt34-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 0,
    },
    category: "shirt",
    src: "34.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 276000,
    },
    characteristic: {
      health: 93,
      force: 93,
      power: 103,
      protection: 93,
      accuracy: 93,
      evasion: 93,
      speed: 93,
    },
  },

  "shirt35-2": {
    game21Comb: {
      name: "Два Туза",
      count: 20,
      property: "ace",
    },
    category: "shirt",
    src: "35.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 28,
      force: 28,
      power: 33,
      protection: 28,
      accuracy: 28,
      evasion: 28,
      speed: 28,
    },
  },

  "shirt36-2": {
    diceGameTournament: true,
    category: "shirt",
    src: "36.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 28,
      force: 28,
      power: 33,
      protection: 28,
      accuracy: 28,
      evasion: 28,
      speed: 28,
    },
  },

  "shirt37-2": {
    diceGameTournament: true,
    category: "shirt",
    src: "37.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 100000,
    },
    characteristic: {
      health: 28,
      force: 28,
      power: 33,
      protection: 28,
      accuracy: 28,
      evasion: 28,
      speed: 28,
    },
  },

  "shirt38-2": {
    diceGameTournament: true,
    category: "shirt",
    src: "38.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  "shirt39-2": {
    diceGameTournament: true,
    category: "shirt",
    src: "39.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  "shirt40-2": {
    diceGameTournament: true,
    category: "shirt",
    src: "40.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 32,
      force: 32,
      power: 37,
      protection: 32,
      accuracy: 32,
      evasion: 32,
      speed: 32,
    },
  },

  "shirtEvent1-2": {
    event: "Шут",
    category: "shirt",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },

  "shirtEvent2-2": {
    event: "Турнир кости/'21'",
    category: "shirt",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 115,
      protection: 100,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "pants1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "1.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  "pants2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "2.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  "pants3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "3.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 26000,
    },
    characteristic: {
      health: 8,
      force: 8,
      power: 8,
      protection: 10,
      accuracy: 8,
      evasion: 8,
      speed: 8,
    },
  },
  "pants4-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "pants",
    src: "4.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 10,
      force: 10,
      power: 10,
      protection: 13,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  "pants5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "5.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 23,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  "pants6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "6.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 23,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  "pants7-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "7.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 23,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  "pants8-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "8.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 70000,
    },
    characteristic: {
      health: 19,
      force: 19,
      power: 19,
      protection: 23,
      accuracy: 19,
      evasion: 19,
      speed: 19,
    },
  },
  "pants9-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "pants",
    src: "9.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 75000,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 25,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "pants10-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "10.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },
  "pants11-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "11.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },
  "pants12-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "12.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },
  "pants13-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "13.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },
  "pants14-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "14.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 150000,
    },
    characteristic: {
      health: 44,
      force: 44,
      power: 44,
      protection: 49,
      accuracy: 44,
      evasion: 44,
      speed: 44,
    },
  },
  "pants15-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "15.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 150000,
    },
    characteristic: {
      health: 44,
      force: 44,
      power: 44,
      protection: 49,
      accuracy: 44,
      evasion: 44,
      speed: 44,
    },
  },
  "pants16-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "16.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 150000,
    },
    characteristic: {
      health: 44,
      force: 44,
      power: 44,
      protection: 49,
      accuracy: 44,
      evasion: 44,
      speed: 44,
    },
  },
  "pants17-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "pants",
    src: "17.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 158000,
    },
    characteristic: {
      health: 46,
      force: 46,
      power: 46,
      protection: 53,
      accuracy: 46,
      evasion: 46,
      speed: 46,
    },
  },
  "pants18-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.05,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "18.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 190000,
    },
    characteristic: {
      health: 57,
      force: 57,
      power: 57,
      protection: 65,
      accuracy: 57,
      evasion: 57,
      speed: 57,
    },
  },
  "pants19-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "19.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 190000,
    },
    characteristic: {
      health: 57,
      force: 57,
      power: 57,
      protection: 65,
      accuracy: 57,
      evasion: 57,
      speed: 57,
    },
  },
  "pants20-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "20.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 190000,
    },
    characteristic: {
      health: 57,
      force: 57,
      power: 57,
      protection: 65,
      accuracy: 57,
      evasion: 57,
      speed: 57,
    },
  },
  "pants21-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "21.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 190000,
    },
    characteristic: {
      health: 57,
      force: 57,
      power: 57,
      protection: 65,
      accuracy: 57,
      evasion: 57,
      speed: 57,
    },
  },
  "pants22-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "pants",
    src: "22.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 195000,
    },
    characteristic: {
      health: 59,
      force: 59,
      power: 59,
      protection: 67,
      accuracy: 59,
      evasion: 59,
      speed: 59,
    },
  },
  "pants23-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.06,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "23.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 236000,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 79,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "pants24-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.05,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "24.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 236000,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 79,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "pants25-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "25.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 236000,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 79,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "pants26-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.03,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "26.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 236000,
    },
    characteristic: {
      health: 70,
      force: 70,
      power: 70,
      protection: 79,
      accuracy: 70,
      evasion: 70,
      speed: 70,
    },
  },
  "pants27-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.07,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "27.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 82,
      force: 82,
      power: 82,
      protection: 92,
      accuracy: 82,
      evasion: 82,
      speed: 82,
    },
  },
  "pants28-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.06,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "28.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 82,
      force: 82,
      power: 82,
      protection: 92,
      accuracy: 82,
      evasion: 82,
      speed: 82,
    },
  },
  "pants29-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.05,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "29.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 82,
      force: 82,
      power: 82,
      protection: 92,
      accuracy: 82,
      evasion: 82,
      speed: 82,
    },
  },
  "pants30-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "30.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 82,
      force: 82,
      power: 82,
      protection: 92,
      accuracy: 82,
      evasion: 82,
      speed: 82,
    },
  },
  "pants31-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.08,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "31.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 285000,
    },
    characteristic: {
      health: 95,
      force: 95,
      power: 95,
      protection: 105,
      accuracy: 95,
      evasion: 95,
      speed: 95,
    },
  },
  "pants32-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.07,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "32.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 285000,
    },
    characteristic: {
      health: 95,
      force: 95,
      power: 95,
      protection: 105,
      accuracy: 95,
      evasion: 95,
      speed: 95,
    },
  },
  "pants33-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.06,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "33.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 285000,
    },
    characteristic: {
      health: 95,
      force: 95,
      power: 95,
      protection: 105,
      accuracy: 95,
      evasion: 95,
      speed: 95,
    },
  },
  "pants34-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.05,
      isSolo: true,
      stage: 0,
    },
    category: "pants",
    src: "34.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 285000,
    },
    characteristic: {
      health: 95,
      force: 95,
      power: 95,
      protection: 105,
      accuracy: 95,
      evasion: 95,
      speed: 95,
    },
  },

  "pants35-2": {
    game21Comb: {
      name: "678",
      count: 20,
      property: "678",
    },
    category: "pants",
    src: "35.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },

  "pants36-2": {
    diceGameTournament: true,
    category: "pants",
    src: "36.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },

  "pants37-2": {
    diceGameTournament: true,
    category: "pants",
    src: "37.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 31,
      force: 31,
      power: 31,
      protection: 36,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },

  "pants38-2": {
    diceGameTournament: true,
    category: "pants",
    src: "38.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 37,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },

  "pants39-2": {
    diceGameTournament: true,
    category: "pants",
    src: "39.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 37,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },

  "pants40-2": {
    diceGameTournament: true,
    category: "pants",
    src: "40.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 39,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "pantsEvent1-2": {
    event: "Шут",
    category: "pants",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 80,
      protection: 90,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },

  "pantsEvent2-2": {
    event: "Турнир кости/'21'",
    category: "pants",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 115,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "hand1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "1.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 13,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  "hand2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "2.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 13,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  "hand3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "3.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 30000,
    },
    characteristic: {
      health: 13,
      force: 10,
      power: 10,
      protection: 10,
      accuracy: 10,
      evasion: 10,
      speed: 10,
    },
  },
  "hand4-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "4.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 75000,
    },
    characteristic: {
      health: 25,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "hand5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "5.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 75000,
    },
    characteristic: {
      health: 25,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "hand6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "6.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 75000,
    },
    characteristic: {
      health: 25,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "hand7-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "7.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 116000,
    },
    characteristic: {
      health: 39,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },
  "hand8-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "8.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 116000,
    },
    characteristic: {
      health: 39,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },
  "hand9-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "9.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 116000,
    },
    characteristic: {
      health: 39,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },
  "hand10-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "10.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 116000,
    },
    characteristic: {
      health: 39,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },
  "hand11-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "11.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 158000,
    },
    characteristic: {
      health: 53,
      force: 46,
      power: 46,
      protection: 46,
      accuracy: 46,
      evasion: 46,
      speed: 46,
    },
  },
  "hand12-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "12.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 158000,
    },
    characteristic: {
      health: 53,
      force: 46,
      power: 46,
      protection: 46,
      accuracy: 46,
      evasion: 46,
      speed: 46,
    },
  },
  "hand13-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "13.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 158000,
    },
    characteristic: {
      health: 53,
      force: 46,
      power: 46,
      protection: 46,
      accuracy: 46,
      evasion: 46,
      speed: 46,
    },
  },
  "hand14-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "14.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 195000,
    },
    characteristic: {
      health: 67,
      force: 59,
      power: 59,
      protection: 59,
      accuracy: 59,
      evasion: 59,
      speed: 59,
    },
  },
  "hand15-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "15.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 195000,
    },
    characteristic: {
      health: 67,
      force: 59,
      power: 59,
      protection: 59,
      accuracy: 59,
      evasion: 59,
      speed: 59,
    },
  },
  "hand16-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "16.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 195000,
    },
    characteristic: {
      health: 67,
      force: 59,
      power: 59,
      protection: 59,
      accuracy: 59,
      evasion: 59,
      speed: 59,
    },
  },
  "hand17-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "17.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 81,
      force: 72,
      power: 72,
      protection: 72,
      accuracy: 72,
      evasion: 72,
      speed: 72,
    },
  },
  "hand18-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "18.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 81,
      force: 72,
      power: 72,
      protection: 72,
      accuracy: 72,
      evasion: 72,
      speed: 72,
    },
  },
  "hand19-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "19.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 81,
      force: 72,
      power: 72,
      protection: 72,
      accuracy: 72,
      evasion: 72,
      speed: 72,
    },
  },
  "hand20-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "20.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 245000,
    },
    characteristic: {
      health: 81,
      force: 72,
      power: 72,
      protection: 72,
      accuracy: 72,
      evasion: 72,
      speed: 72,
    },
  },
  "hand21-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "21.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 250000,
    },
    characteristic: {
      health: 94,
      force: 84,
      power: 84,
      protection: 84,
      accuracy: 84,
      evasion: 84,
      speed: 84,
    },
  },
  "hand22-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "22.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 250000,
    },
    characteristic: {
      health: 94,
      force: 84,
      power: 84,
      protection: 84,
      accuracy: 84,
      evasion: 84,
      speed: 84,
    },
  },
  "hand23-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "23.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 250000,
    },
    characteristic: {
      health: 94,
      force: 84,
      power: 84,
      protection: 84,
      accuracy: 84,
      evasion: 84,
      speed: 84,
    },
  },
  "hand24-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "24.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 250000,
    },
    characteristic: {
      health: 94,
      force: 84,
      power: 84,
      protection: 84,
      accuracy: 84,
      evasion: 84,
      speed: 84,
    },
  },
  "hand25-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.04,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "25.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 295000,
    },
    characteristic: {
      health: 108,
      force: 98,
      power: 98,
      protection: 98,
      accuracy: 98,
      evasion: 98,
      speed: 98,
    },
  },
  "hand26-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.03,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "26.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 295000,
    },
    characteristic: {
      health: 108,
      force: 98,
      power: 98,
      protection: 98,
      accuracy: 98,
      evasion: 98,
      speed: 98,
    },
  },
  "hand27-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.02,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "27.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 295000,
    },
    characteristic: {
      health: 108,
      force: 98,
      power: 98,
      protection: 98,
      accuracy: 98,
      evasion: 98,
      speed: 98,
    },
  },
  "hand28-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 1,
    },
    category: "hand",
    src: "28.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 295000,
    },
    characteristic: {
      health: 108,
      force: 98,
      power: 98,
      protection: 98,
      accuracy: 98,
      evasion: 98,
      speed: 98,
    },
  },

  "hand29-2": {
    game21Comb: {
      name: "777",
      count: 20,
      property: "777",
    },
    category: "hand",
    src: "29.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 36,
      force: 31,
      power: 31,
      protection: 31,
      accuracy: 31,
      evasion: 31,
      speed: 31,
    },
  },

  "hand30-2": {
    game21Tournament: true,
    category: "hand",
    src: "30.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 38,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 33,
      speed: 33,
    },
  },

  "hand31-2": {
    game21Tournament: true,
    category: "hand",
    src: "31.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 40,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "hand32-2": {
    game21Tournament: true,
    category: "hand",
    src: "32.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 40,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "hand33-2": {
    game21Tournament: true,
    category: "hand",
    src: "33.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 40,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "hand34-2": {
    game21Tournament: true,
    category: "hand",
    src: "34.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 40,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "hand35-2": {
    game21Tournament: true,
    category: "hand",
    src: "35.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 140000,
    },
    characteristic: {
      health: 43,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 38,
      evasion: 38,
      speed: 38,
    },
  },

  "handEvent1-2": {
    event: "Шут",
    category: "hand",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 90,
      force: 80,
      power: 80,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },

  "handEvent2-2": {
    event: "Турнир кости/'21'",
    category: "hand",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 115,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "shoes1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "1.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 14,
      speed: 14,
    },
  },
  "shoes2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "2.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 14,
      speed: 14,
    },
  },
  "shoes3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "3.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 12,
      evasion: 14,
      speed: 14,
    },
  },
  "shoes4-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "4.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 84000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 22,
      accuracy: 22,
      evasion: 26,
      speed: 26,
    },
  },
  "shoes5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "5.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 84000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 22,
      accuracy: 22,
      evasion: 26,
      speed: 26,
    },
  },
  "shoes6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "6.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 84000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 22,
      accuracy: 22,
      evasion: 26,
      speed: 26,
    },
  },
  "shoes7-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "7.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 125000,
    },
    characteristic: {
      health: 36,
      force: 36,
      power: 36,
      protection: 36,
      accuracy: 36,
      evasion: 42,
      speed: 42,
    },
  },
  "shoes8-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "8.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 125000,
    },
    characteristic: {
      health: 36,
      force: 36,
      power: 36,
      protection: 36,
      accuracy: 36,
      evasion: 42,
      speed: 42,
    },
  },
  "shoes9-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "9.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 125000,
    },
    characteristic: {
      health: 36,
      force: 36,
      power: 36,
      protection: 36,
      accuracy: 36,
      evasion: 42,
      speed: 42,
    },
  },
  "shoes10-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "10.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 125000,
    },
    characteristic: {
      health: 36,
      force: 36,
      power: 36,
      protection: 36,
      accuracy: 36,
      evasion: 42,
      speed: 42,
    },
  },
  "shoes11-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "11.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 164000,
    },
    characteristic: {
      health: 48,
      force: 48,
      power: 48,
      protection: 48,
      accuracy: 48,
      evasion: 55,
      speed: 55,
    },
  },
  "shoes12-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "12.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 164000,
    },
    characteristic: {
      health: 48,
      force: 48,
      power: 48,
      protection: 48,
      accuracy: 48,
      evasion: 55,
      speed: 55,
    },
  },
  "shoes13-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "13.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 164000,
    },
    characteristic: {
      health: 48,
      force: 48,
      power: 48,
      protection: 48,
      accuracy: 48,
      evasion: 55,
      speed: 55,
    },
  },
  "shoes14-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.05,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "14.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 205000,
    },
    characteristic: {
      health: 62,
      force: 62,
      power: 62,
      protection: 62,
      accuracy: 62,
      evasion: 70,
      speed: 70,
    },
  },
  "shoes15-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "15.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 205000,
    },
    characteristic: {
      health: 62,
      force: 62,
      power: 62,
      protection: 62,
      accuracy: 62,
      evasion: 70,
      speed: 70,
    },
  },
  "shoes16-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "16.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 205000,
    },
    characteristic: {
      health: 62,
      force: 62,
      power: 62,
      protection: 62,
      accuracy: 62,
      evasion: 70,
      speed: 70,
    },
  },
  "shoes17-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.06,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "17.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 252000,
    },
    characteristic: {
      health: 74,
      force: 74,
      power: 74,
      protection: 74,
      accuracy: 74,
      evasion: 83,
      speed: 83,
    },
  },
  "shoes18-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.05,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "18.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 252000,
    },
    characteristic: {
      health: 74,
      force: 74,
      power: 74,
      protection: 74,
      accuracy: 74,
      evasion: 83,
      speed: 83,
    },
  },
  "shoes19-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "19.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 252000,
    },
    characteristic: {
      health: 74,
      force: 74,
      power: 74,
      protection: 74,
      accuracy: 74,
      evasion: 83,
      speed: 83,
    },
  },
  "shoes20-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.03,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "20.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 252000,
    },
    characteristic: {
      health: 74,
      force: 74,
      power: 74,
      protection: 74,
      accuracy: 74,
      evasion: 83,
      speed: 83,
    },
  },
  "shoes21-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.07,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "21.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 86,
      force: 86,
      power: 86,
      protection: 86,
      accuracy: 86,
      evasion: 96,
      speed: 96,
    },
  },
  "shoes22-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.06,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "22.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 86,
      force: 86,
      power: 86,
      protection: 86,
      accuracy: 86,
      evasion: 96,
      speed: 96,
    },
  },
  "shoes23-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.05,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "23.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 86,
      force: 86,
      power: 86,
      protection: 86,
      accuracy: 86,
      evasion: 96,
      speed: 96,
    },
  },
  "shoes24-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "24.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 86,
      force: 86,
      power: 86,
      protection: 86,
      accuracy: 86,
      evasion: 96,
      speed: 96,
    },
  },
  "shoes25-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.08,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "25.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 300000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 110,
      speed: 110,
    },
  },
  "shoes26-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.07,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "26.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 300000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 110,
      speed: 110,
    },
  },
  "shoes27-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.06,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "27.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 300000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 110,
      speed: 110,
    },
  },
  "shoes28-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.05,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "shoes",
    src: "28.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 300000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 110,
      speed: 110,
    },
  },
  "shoes29-2": {
    game21Comb: {
      name: "5 картинок",
      count: 20,
      property: "img",
    },
    category: "shoes",
    src: "29.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 140000,
    },
    characteristic: {
      health: 34,
      force: 34,
      power: 34,
      protection: 34,
      accuracy: 34,
      evasion: 40,
      speed: 40,
    },
  },

  "shoes30-2": {
    game21Tournament: true,
    category: "shoes",
    src: "30.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },

  "shoes31-2": {
    game21Tournament: true,
    category: "shoes",
    src: "31.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 39,
      speed: 39,
    },
  },

  "shoes32-2": {
    game21Tournament: true,
    category: "shoes",
    src: "32.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 39,
      speed: 39,
    },
  },

  "shoes33-2": {
    game21Tournament: true,
    category: "shoes",
    src: "33.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 39,
      speed: 39,
    },
  },

  "shoes34-2": {
    game21Tournament: true,
    category: "shoes",
    src: "34.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 120000,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 33,
      accuracy: 33,
      evasion: 39,
      speed: 39,
    },
  },

  "shoes35-2": {
    game21Tournament: true,
    category: "shoes",
    src: "35.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 140000,
    },
    characteristic: {
      health: 34,
      force: 34,
      power: 34,
      protection: 34,
      accuracy: 34,
      evasion: 40,
      speed: 40,
    },
  },

  "shoesEvent1-2": {
    event: "Шут",
    category: "shoes",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 80,
      protection: 80,
      accuracy: 80,
      evasion: 90,
      speed: 90,
    },
  },

  "shoesEvent2-2": {
    swap: {
      man: false,
      woman: true,
    },
    event: "Турнир кости/'21'",
    category: "shoes",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 100,
      evasion: 115,
      speed: 115,
    },
  },

  "head1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "head",
    src: "1.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 40000,
    },
    characteristic: {
      health: 12,
      force: 12,
      power: 12,
      protection: 12,
      accuracy: 14,
      evasion: 12,
      speed: 12,
    },
  },
  "head2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "2.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 45000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 17,
      evasion: 14,
      speed: 14,
    },
  },
  "head3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "3.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 45000,
    },
    characteristic: {
      health: 14,
      force: 14,
      power: 14,
      protection: 14,
      accuracy: 17,
      evasion: 14,
      speed: 14,
    },
  },
  "head4-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "4.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 84000,
    },
    characteristic: {
      health: 22,
      force: 22,
      power: 22,
      protection: 22,
      accuracy: 26,
      evasion: 22,
      speed: 22,
    },
  },
  "head5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "5.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 90000,
    },
    characteristic: {
      health: 24,
      force: 24,
      power: 24,
      protection: 24,
      accuracy: 29,
      evasion: 24,
      speed: 24,
    },
  },
  "head6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "6.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 90000,
    },
    characteristic: {
      health: 24,
      force: 24,
      power: 24,
      protection: 24,
      accuracy: 29,
      evasion: 24,
      speed: 24,
    },
  },
  "head7-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "7.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 38,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 44,
      evasion: 38,
      speed: 38,
    },
  },
  "head8-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "8.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 38,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 44,
      evasion: 38,
      speed: 38,
    },
  },
  "head9-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "9.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 38,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 44,
      evasion: 38,
      speed: 38,
    },
  },
  "head10-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "10.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 38,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 44,
      evasion: 38,
      speed: 38,
    },
  },
  "head11-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 1,
    },
    category: "head",
    src: "11.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 164000,
    },
    characteristic: {
      health: 48,
      force: 48,
      power: 48,
      protection: 48,
      accuracy: 55,
      evasion: 48,
      speed: 48,
    },
  },
  "head12-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "12.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 170000,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 57,
      evasion: 50,
      speed: 50,
    },
  },
  "head13-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "13.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 170000,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 57,
      evasion: 50,
      speed: 50,
    },
  },
  "head14-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: true,
      stage: 1,
    },
    category: "head",
    src: "14.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 205000,
    },
    characteristic: {
      health: 62,
      force: 62,
      power: 62,
      protection: 62,
      accuracy: 70,
      evasion: 62,
      speed: 62,
    },
  },
  "head15-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "15.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 214000,
    },
    characteristic: {
      health: 64,
      force: 64,
      power: 64,
      protection: 64,
      accuracy: 72,
      evasion: 64,
      speed: 64,
    },
  },
  "head16-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "16.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 214000,
    },
    characteristic: {
      health: 64,
      force: 64,
      power: 64,
      protection: 64,
      accuracy: 72,
      evasion: 64,
      speed: 64,
    },
  },
  "head17-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "17.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 76,
      force: 76,
      power: 76,
      protection: 76,
      accuracy: 85,
      evasion: 76,
      speed: 76,
    },
  },
  "head18-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "18.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 76,
      force: 76,
      power: 76,
      protection: 76,
      accuracy: 85,
      evasion: 76,
      speed: 76,
    },
  },
  "head19-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "19.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 76,
      force: 76,
      power: 76,
      protection: 76,
      accuracy: 85,
      evasion: 76,
      speed: 76,
    },
  },
  "head20-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "20.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 258000,
    },
    characteristic: {
      health: 76,
      force: 76,
      power: 76,
      protection: 76,
      accuracy: 85,
      evasion: 76,
      speed: 76,
    },
  },
  "head21-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "21.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 260000,
    },
    characteristic: {
      health: 89,
      force: 89,
      power: 89,
      protection: 89,
      accuracy: 99,
      evasion: 89,
      speed: 89,
    },
  },
  "head22-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "22.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 260000,
    },
    characteristic: {
      health: 89,
      force: 89,
      power: 89,
      protection: 89,
      accuracy: 99,
      evasion: 89,
      speed: 89,
    },
  },
  "head23-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "23.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 260000,
    },
    characteristic: {
      health: 89,
      force: 89,
      power: 89,
      protection: 89,
      accuracy: 99,
      evasion: 89,
      speed: 89,
    },
  },
  "head24-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "24.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 260000,
    },
    characteristic: {
      health: 89,
      force: 89,
      power: 89,
      protection: 89,
      accuracy: 99,
      evasion: 89,
      speed: 89,
    },
  },
  "head25-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.04,
      isSolo: false,
      stage: 2,
    },
    category: "head",
    src: "25.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 310000,
    },
    characteristic: {
      health: 102,
      force: 102,
      power: 102,
      protection: 102,
      accuracy: 112,
      evasion: 102,
      speed: 102,
    },
  },
  "head26-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.03,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "26.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 310000,
    },
    characteristic: {
      health: 102,
      force: 102,
      power: 102,
      protection: 102,
      accuracy: 112,
      evasion: 102,
      speed: 102,
    },
  },
  "head27-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "27.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 310000,
    },
    characteristic: {
      health: 102,
      force: 102,
      power: 102,
      protection: 102,
      accuracy: 112,
      evasion: 102,
      speed: 102,
    },
  },
  "head28-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    swap: {
      man: true,
      woman: true,
    },
    category: "head",
    src: "28.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 310000,
    },
    characteristic: {
      health: 102,
      force: 102,
      power: 102,
      protection: 102,
      accuracy: 112,
      evasion: 102,
      speed: 102,
    },
  },
  "head29-2": {
    diceGameComb: {
      name: "4 четвёрки",
      count: 20,
      property: "4444",
    },
    category: "head",
    src: "29.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 90000,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },

  "head30-2": {
    game21Tournament: true,
    category: "head",
    src: "30.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 110000,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 36,
      evasion: 30,
      speed: 30,
    },
  },

  "head31-2": {
    game21Tournament: true,
    category: "head",
    src: "31.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 41,
      evasion: 35,
      speed: 35,
    },
  },

  "head32-2": {
    game21Tournament: true,
    category: "head",
    src: "32.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 41,
      evasion: 35,
      speed: 35,
    },
  },

  "head33-2": {
    game21Tournament: true,
    category: "head",
    src: "33.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 41,
      evasion: 35,
      speed: 35,
    },
  },

  "head34-2": {
    game21Tournament: true,
    category: "head",
    src: "34.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 130000,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 35,
      accuracy: 41,
      evasion: 35,
      speed: 35,
    },
  },

  "head35-2": {
    game21Tournament: true,
    category: "head",
    src: "35.png",
    lvl: 1,
    price: {
      property: "silver",
      name: "серебра",
      count: 150000,
    },
    characteristic: {
      health: 38,
      force: 38,
      power: 38,
      protection: 38,
      accuracy: 44,
      evasion: 38,
      speed: 38,
    },
  },

  "headEvent1-2": {
    event: "Шут",
    category: "head",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 80,
      protection: 80,
      accuracy: 90,
      evasion: 80,
      speed: 80,
    },
  },

  "headEvent2-2": {
    swap: {
      man: false,
      woman: true,
    },
    event: "Турнир кости/'21'",
    category: "head",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 100,
      accuracy: 115,
      evasion: 100,
      speed: 100,
    },
  },

  "wand1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "1.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 280,
    },
    characteristic: {
      health: 14,
      force: 17,
      power: 17,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  "wand2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "2.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 280,
    },
    characteristic: {
      health: 14,
      force: 17,
      power: 17,
      protection: 14,
      accuracy: 14,
      evasion: 14,
      speed: 14,
    },
  },
  "wand3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "3.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 300,
    },
    characteristic: {
      health: 15,
      force: 18,
      power: 18,
      protection: 15,
      accuracy: 15,
      evasion: 15,
      speed: 15,
    },
  },
  "wand4-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "4.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 480,
    },
    characteristic: {
      health: 24,
      force: 29,
      power: 29,
      protection: 24,
      accuracy: 24,
      evasion: 24,
      speed: 24,
    },
  },
  "wand5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "5.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 480,
    },
    characteristic: {
      health: 24,
      force: 29,
      power: 29,
      protection: 24,
      accuracy: 24,
      evasion: 24,
      speed: 24,
    },
  },
  "wand6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "6.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 520,
    },
    characteristic: {
      health: 26,
      force: 31,
      power: 31,
      protection: 26,
      accuracy: 26,
      evasion: 26,
      speed: 26,
    },
  },
  "wand7-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "7.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 800,
    },
    characteristic: {
      health: 40,
      force: 46,
      power: 46,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "wand8-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.03,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "8.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 800,
    },
    characteristic: {
      health: 40,
      force: 46,
      power: 46,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "wand9-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "9.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1000,
    },
    characteristic: {
      health: 50,
      force: 57,
      power: 57,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "wand10-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "10.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1000,
    },
    characteristic: {
      health: 50,
      force: 57,
      power: 57,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "wand11-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "11.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1000,
    },
    characteristic: {
      health: 50,
      force: 57,
      power: 57,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "wand12-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "12.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1280,
    },
    characteristic: {
      health: 64,
      force: 72,
      power: 72,
      protection: 64,
      accuracy: 64,
      evasion: 64,
      speed: 64,
    },
  },
  "wand13-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.01,
      isSolo: false,
      stage: 2,
    },
    category: "wand",
    src: "13.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1280,
    },
    characteristic: {
      health: 64,
      force: 72,
      power: 72,
      protection: 64,
      accuracy: 64,
      evasion: 64,
      speed: 64,
    },
  },
  "wand14-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.05,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "14.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1320,
    },
    characteristic: {
      health: 66,
      force: 74,
      power: 74,
      protection: 66,
      accuracy: 66,
      evasion: 66,
      speed: 66,
    },
  },
  "wand15-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.06,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "15.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1560,
    },
    characteristic: {
      health: 78,
      force: 88,
      power: 88,
      protection: 78,
      accuracy: 78,
      evasion: 78,
      speed: 78,
    },
  },
  "wand16-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.05,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "16.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1560,
    },
    characteristic: {
      health: 78,
      force: 88,
      power: 88,
      protection: 78,
      accuracy: 78,
      evasion: 78,
      speed: 78,
    },
  },
  "wand17-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.07,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "17.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1820,
    },
    characteristic: {
      health: 91,
      force: 101,
      power: 101,
      protection: 91,
      accuracy: 91,
      evasion: 91,
      speed: 91,
    },
  },
  "wand18-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.06,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "18.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1820,
    },
    characteristic: {
      health: 91,
      force: 101,
      power: 101,
      protection: 91,
      accuracy: 91,
      evasion: 91,
      speed: 91,
    },
  },
  "wand19-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.08,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "19.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 2100,
    },
    characteristic: {
      health: 105,
      force: 115,
      power: 115,
      protection: 105,
      accuracy: 105,
      evasion: 105,
      speed: 105,
    },
  },
  "wand20-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.07,
      isSolo: true,
      stage: 2,
    },
    category: "wand",
    src: "20.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 2100,
    },
    characteristic: {
      health: 105,
      force: 115,
      power: 115,
      protection: 105,
      accuracy: 105,
      evasion: 105,
      speed: 105,
    },
  },

  "wand21-2": {
    diceGameComb: {
      name: "4 пятёрки",
      count: 20,
      property: "5555",
    },
    category: "wand",
    src: "21.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 600,
    },
    characteristic: {
      health: 30,
      force: 36,
      power: 36,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  "wand22-2": {
    diceGameTournament: true,
    category: "wand",
    src: "22.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 600,
    },
    characteristic: {
      health: 30,
      force: 36,
      power: 36,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },

  "wand23-2": {
    diceGameTournament: true,
    category: "wand",
    src: "23.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 640,
    },
    characteristic: {
      health: 32,
      force: 38,
      power: 38,
      protection: 32,
      accuracy: 32,
      evasion: 32,
      speed: 32,
    },
  },

  "wand24-2": {
    diceGameTournament: true,
    category: "wand",
    src: "24.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 640,
    },
    characteristic: {
      health: 32,
      force: 38,
      power: 38,
      protection: 32,
      accuracy: 32,
      evasion: 32,
      speed: 32,
    },
  },

  "wand25-2": {
    diceGameTournament: true,
    category: "wand",
    src: "25.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 640,
    },
    characteristic: {
      health: 32,
      force: 38,
      power: 38,
      protection: 32,
      accuracy: 32,
      evasion: 32,
      speed: 32,
    },
  },

  "wand26-2": {
    diceGameTournament: true,
    category: "wand",
    src: "26.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 700,
    },
    characteristic: {
      health: 35,
      force: 41,
      power: 41,
      protection: 35,
      accuracy: 35,
      evasion: 35,
      speed: 35,
    },
  },

  "wandEvent1-2": {
    event: "Шут",
    category: "wand",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 90,
      power: 90,
      protection: 80,
      accuracy: 80,
      evasion: 80,
      speed: 80,
    },
  },

  "wandEvent2-2": {
    event: "Турнир кости/'21'",
    category: "wand",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 115,
      power: 115,
      protection: 100,
      accuracy: 100,
      evasion: 100,
      speed: 100,
    },
  },

  "broom1-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.03,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "1.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 300,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 18,
      speed: 15,
    },
  },
  "broom2-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.02,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "2.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 300,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 18,
      speed: 15,
    },
  },
  "broom3-2": {
    bossRandom: {
      name: "Пикси",
      number: 0,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "3.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 300,
    },
    characteristic: {
      health: 15,
      force: 15,
      power: 15,
      protection: 18,
      accuracy: 15,
      evasion: 18,
      speed: 15,
    },
  },
  "broom4-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.03,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "4.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 520,
    },
    characteristic: {
      health: 26,
      force: 26,
      power: 26,
      protection: 31,
      accuracy: 26,
      evasion: 31,
      speed: 26,
    },
  },
  "broom5-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.02,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "5.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 520,
    },
    characteristic: {
      health: 26,
      force: 26,
      power: 26,
      protection: 31,
      accuracy: 26,
      evasion: 31,
      speed: 26,
    },
  },
  "broom6-2": {
    bossRandom: {
      name: "Полтергейста",
      number: 1,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "6.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 520,
    },
    characteristic: {
      health: 26,
      force: 26,
      power: 26,
      protection: 31,
      accuracy: 26,
      evasion: 31,
      speed: 26,
    },
  },
  "broom7-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.02,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "7.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 800,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 46,
      accuracy: 40,
      evasion: 46,
      speed: 40,
    },
  },
  "broom8-2": {
    bossRandom: {
      name: "Призрака Плаксы",
      number: 2,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "8.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 800,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 46,
      accuracy: 40,
      evasion: 46,
      speed: 40,
    },
  },
  "broom9-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.03,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "9.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1060,
    },
    characteristic: {
      health: 53,
      force: 53,
      power: 53,
      protection: 61,
      accuracy: 53,
      evasion: 61,
      speed: 53,
    },
  },
  "broom10-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.02,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "10.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1060,
    },
    characteristic: {
      health: 53,
      force: 53,
      power: 53,
      protection: 61,
      accuracy: 53,
      evasion: 61,
      speed: 53,
    },
  },
  "broom11-2": {
    bossRandom: {
      name: "Жуткого Завхоза",
      number: 3,
      chance: 0.01,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "11.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1060,
    },
    characteristic: {
      health: 53,
      force: 53,
      power: 53,
      protection: 61,
      accuracy: 53,
      evasion: 61,
      speed: 53,
    },
  },
  "broom12-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "12.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1320,
    },
    characteristic: {
      health: 66,
      force: 66,
      power: 66,
      protection: 74,
      accuracy: 66,
      evasion: 74,
      speed: 66,
    },
  },
  "broom13-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.03,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "13.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1320,
    },
    characteristic: {
      health: 66,
      force: 66,
      power: 66,
      protection: 74,
      accuracy: 66,
      evasion: 74,
      speed: 66,
    },
  },
  "broom14-2": {
    bossRandom: {
      name: "Кошмара",
      number: 4,
      chance: 0.02,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "14.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1320,
    },
    characteristic: {
      health: 66,
      force: 66,
      power: 66,
      protection: 74,
      accuracy: 66,
      evasion: 74,
      speed: 66,
    },
  },
  "broom15-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "15.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1560,
    },
    characteristic: {
      health: 78,
      force: 78,
      power: 78,
      protection: 88,
      accuracy: 78,
      evasion: 88,
      speed: 78,
    },
  },
  "broom16-2": {
    bossRandom: {
      name: "Зловещего Призрака",
      number: 5,
      chance: 0.032,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "16.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1560,
    },
    characteristic: {
      health: 78,
      force: 78,
      power: 78,
      protection: 88,
      accuracy: 78,
      evasion: 88,
      speed: 78,
    },
  },
  "broom17-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.05,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "17.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1820,
    },
    characteristic: {
      health: 91,
      force: 91,
      power: 91,
      protection: 101,
      accuracy: 91,
      evasion: 101,
      speed: 91,
    },
  },
  "broom18-2": {
    bossRandom: {
      name: "Обскура",
      number: 6,
      chance: 0.04,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "18.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 1820,
    },
    characteristic: {
      health: 91,
      force: 91,
      power: 91,
      protection: 101,
      accuracy: 91,
      evasion: 101,
      speed: 91,
    },
  },
  "broom19-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.06,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "19.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 2100,
    },
    characteristic: {
      health: 105,
      force: 105,
      power: 105,
      protection: 115,
      accuracy: 105,
      evasion: 115,
      speed: 105,
    },
  },
  "broom20-2": {
    bossRandom: {
      name: "Лже-мага",
      number: 7,
      chance: 0.05,
      isSolo: true,
      stage: 2,
    },
    category: "broom",
    src: "20.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 2100,
    },
    characteristic: {
      health: 105,
      force: 105,
      power: 105,
      protection: 115,
      accuracy: 105,
      evasion: 115,
      speed: 105,
    },
  },
  "broom21-2": {
    diceGameComb: {
      name: "4 шестёрки",
      count: 20,
      property: "6666",
    },
    category: "broom",
    src: "21.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 700,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 42,
      accuracy: 35,
      evasion: 42,
      speed: 35,
    },
  },

  "broom22-2": {
    diceGameTournament: true,
    category: "broom",
    src: "22.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 600,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },

  "broom23-2": {
    diceGameTournament: true,
    category: "broom",
    src: "23.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 660,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 38,
      accuracy: 33,
      evasion: 38,
      speed: 33,
    },
  },

  "broom24-2": {
    diceGameTournament: true,
    category: "broom",
    src: "24.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 660,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 38,
      accuracy: 33,
      evasion: 38,
      speed: 33,
    },
  },

  "broom25-2": {
    diceGameTournament: true,
    category: "broom",
    src: "25.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 660,
    },
    characteristic: {
      health: 33,
      force: 33,
      power: 33,
      protection: 38,
      accuracy: 33,
      evasion: 38,
      speed: 33,
    },
  },

  "broom26-2": {
    diceGameTournament: true,
    category: "broom",
    src: "26.png",
    lvl: 1,
    price: {
      property: "gold",
      name: "золота",
      count: 700,
    },
    characteristic: {
      health: 35,
      force: 35,
      power: 35,
      protection: 42,
      accuracy: 35,
      evasion: 42,
      speed: 35,
    },
  },

  "broomEvent1-2": {
    event: "Шут",
    category: "broom",
    src: "Event/Event1.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 1600,
    },
    characteristic: {
      health: 80,
      force: 80,
      power: 80,
      protection: 90,
      accuracy: 80,
      evasion: 90,
      speed: 80,
    },
  },

  "broomEvent2-2": {
    event: "Турнир кости/'21'",
    category: "broom",
    src: "Event/Event2.png",
    lvl: 1,
    price: {
      property: "gold",
      count: 2000,
    },
    characteristic: {
      health: 100,
      force: 100,
      power: 100,
      protection: 115,
      accuracy: 100,
      evasion: 115,
      speed: 100,
    },
  },
};

export const clothes2Treasures = {
  "shirtTreasure1-2": {
    category: "shirt",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0511,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "shirtTreasure2-2": {
    category: "shirt",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0521,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "shirtTreasure3-2": {
    category: "shirt",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0531,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "shirtTreasure4-2": {
    category: "shirt",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0541,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "shirtTreasure5-2": {
    category: "shirt",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0411,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "shirtTreasure6-2": {
    category: "shirt",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0421,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "shirtTreasure7-2": {
    category: "shirt",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0431,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "shirtTreasure8-2": {
    category: "shirt",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0441,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "shirtTreasure9-2": {
    category: "shirt",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0451,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "shirtTreasure10-2": {
    category: "shirt",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0461,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "shirtTreasure11-2": {
    category: "shirt",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0211,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure12-2": {
    category: "shirt",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0221,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure13-2": {
    category: "shirt",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0231,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure14-2": {
    category: "shirt",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0251,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure15-2": {
    category: "shirt",
    src: "Treasure/Treasure15.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0261,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure16-2": {
    category: "shirt",
    src: "Treasure/Treasure16.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0271,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure17-2": {
    category: "shirt",
    src: "Treasure/Treasure17.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0281,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure18-2": {
    category: "shirt",
    src: "Treasure/Treasure18.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0291,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 50,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "shirtTreasure19-2": {
    category: "shirt",
    src: "Treasure/Treasure19.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0111,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 60,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "shirtTreasure20-2": {
    category: "shirt",
    src: "Treasure/Treasure20.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00511,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "pantsTreasure1-2": {
    category: "pants",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0512,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 24,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "pantsTreasure2-2": {
    category: "pants",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0522,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 24,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "pantsTreasure3-2": {
    category: "pants",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0412,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "pantsTreasure4-2": {
    category: "pants",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0422,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "pantsTreasure5-2": {
    category: "pants",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0312,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "pantsTreasure6-2": {
    category: "pants",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0322,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "pantsTreasure7-2": {
    category: "pants",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0332,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "pantsTreasure8-2": {
    category: "pants",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0342,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "pantsTreasure9-2": {
    category: "pants",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0352,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "pantsTreasure10-2": {
    category: "pants",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0352,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "pantsTreasure11-2": {
    category: "pants",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0212,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure12-2": {
    category: "pants",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0222,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure13-2": {
    category: "pants",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0232,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure14-2": {
    category: "pants",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0242,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure15-2": {
    category: "pants",
    src: "Treasure/Treasure15.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0252,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure16-2": {
    category: "pants",
    src: "Treasure/Treasure16.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0262,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure17-2": {
    category: "pants",
    src: "Treasure/Treasure17.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0272,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure18-2": {
    category: "pants",
    src: "Treasure/Treasure18.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0282,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "pantsTreasure19-2": {
    category: "pants",
    src: "Treasure/Treasure19.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0112,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "pantsTreasure20-2": {
    category: "pants",
    src: "Treasure/Treasure20.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0122,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "handTreasure1-2": {
    category: "hand",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0513,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "handTreasure2-2": {
    category: "hand",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0523,
    },
    characteristic: {
      health: 24,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "handTreasure3-2": {
    category: "hand",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0413,
    },
    characteristic: {
      health: 30,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "handTreasure4-2": {
    category: "hand",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0423,
    },
    characteristic: {
      health: 30,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "handTreasure5-2": {
    category: "hand",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0313,
    },
    characteristic: {
      health: 35,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "handTreasure6-2": {
    category: "hand",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0323,
    },
    characteristic: {
      health: 35,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "handTreasure7-2": {
    category: "hand",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0333,
    },
    characteristic: {
      health: 35,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "handTreasure8-2": {
    category: "hand",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0213,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "handTreasure9-2": {
    category: "hand",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0223,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "handTreasure10-2": {
    category: "hand",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0233,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "handTreasure11-2": {
    category: "hand",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0243,
    },
    characteristic: {
      health: 50,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 40,
      speed: 40,
    },
  },
  "handTreasure12-2": {
    category: "hand",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0113,
    },
    characteristic: {
      health: 60,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "handTreasure13-2": {
    category: "hand",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0123,
    },
    characteristic: {
      health: 60,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "handTreasure14-2": {
    category: "hand",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0133,
    },
    characteristic: {
      health: 60,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "handTreasure15-2": {
    category: "hand",
    src: "Treasure/Treasure15.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0143,
    },
    characteristic: {
      health: 60,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "headTreasure1-2": {
    category: "head",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0514,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 24,
      evasion: 20,
      speed: 20,
    },
  },
  "headTreasure2-2": {
    category: "head",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0524,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 24,
      evasion: 20,
      speed: 20,
    },
  },
  "headTreasure3-2": {
    category: "head",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0534,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 24,
      evasion: 20,
      speed: 20,
    },
  },
  "headTreasure4-2": {
    category: "head",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0414,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure5-2": {
    category: "head",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0424,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure6-2": {
    category: "head",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0434,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure7-2": {
    category: "head",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0434,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 30,
      evasion: 25,
      speed: 25,
    },
  },
  "headTreasure8-2": {
    category: "head",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0314,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 35,
      evasion: 30,
      speed: 30,
    },
  },
  "headTreasure9-2": {
    category: "head",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0324,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 35,
      evasion: 30,
      speed: 30,
    },
  },
  "headTreasure10-2": {
    category: "head",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0214,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 50,
      evasion: 40,
      speed: 40,
    },
  },
  "headTreasure11-2": {
    category: "head",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0114,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 60,
      evasion: 50,
      speed: 50,
    },
  },
  "headTreasure12-2": {
    category: "head",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0124,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 60,
      evasion: 50,
      speed: 50,
    },
  },
  "headTreasure13-2": {
    category: "head",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0134,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 60,
      evasion: 50,
      speed: 50,
    },
  },
  "headTreasure14-2": {
    category: "head",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0144,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 60,
      evasion: 50,
      speed: 50,
    },
  },
  "headTreasure15-2": {
    category: "head",
    src: "Treasure/Treasure15.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00514,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 60,
      accuracy: 70,
      evasion: 60,
      speed: 60,
    },
  },
  "shoesTreasure1-2": {
    category: "shoes",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0515,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 24,
      speed: 24,
    },
  },
  "shoesTreasure2-2": {
    category: "shoes",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0525,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 24,
      speed: 24,
    },
  },
  "shoesTreasure3-2": {
    category: "shoes",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0535,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 24,
      speed: 24,
    },
  },
  "shoesTreasure4-2": {
    category: "shoes",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0545,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 20,
      accuracy: 20,
      evasion: 24,
      speed: 24,
    },
  },
  "shoesTreasure5-2": {
    category: "shoes",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0415,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 25,
      accuracy: 25,
      evasion: 30,
      speed: 30,
    },
  },
  "shoesTreasure6-2": {
    category: "shoes",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0315,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },
  "shoesTreasure7-2": {
    category: "shoes",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0325,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 30,
      accuracy: 30,
      evasion: 35,
      speed: 35,
    },
  },
  "shoesTreasure8-2": {
    category: "shoes",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0215,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 50,
      speed: 50,
    },
  },
  "shoesTreasure9-2": {
    category: "shoes",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0225,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 50,
      speed: 50,
    },
  },
  "shoesTreasure10-2": {
    category: "shoes",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0235,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 50,
      speed: 50,
    },
  },
  "shoesTreasure11-2": {
    category: "shoes",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0245,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 50,
      speed: 50,
    },
  },
  "shoesTreasure12-2": {
    category: "shoes",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0255,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 40,
      accuracy: 40,
      evasion: 50,
      speed: 50,
    },
  },
  "shoesTreasure13-2": {
    category: "shoes",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0115,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 60,
      speed: 60,
    },
  },
  "shoesTreasure14-2": {
    category: "shoes",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0125,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 60,
      speed: 60,
    },
  },
  "shoesTreasure15-2": {
    category: "shoes",
    src: "Treasure/Treasure15.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0135,
    },
    swap: {
      man: true,
      woman: true,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 50,
      accuracy: 50,
      evasion: 60,
      speed: 60,
    },
  },
  "wandTreasure1-2": {
    category: "wand",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0516,
    },
    characteristic: {
      health: 20,
      force: 24,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "wandTreasure2-2": {
    category: "wand",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0526,
    },
    characteristic: {
      health: 20,
      force: 24,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "wandTreasure3-2": {
    category: "wand",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0536,
    },
    characteristic: {
      health: 20,
      force: 24,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "wandTreasure4-2": {
    category: "wand",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0546,
    },
    characteristic: {
      health: 20,
      force: 24,
      power: 24,
      protection: 20,
      accuracy: 20,
      evasion: 20,
      speed: 20,
    },
  },
  "wandTreasure5-2": {
    category: "wand",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0416,
    },
    characteristic: {
      health: 25,
      force: 30,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "wandTreasure6-2": {
    category: "wand",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0426,
    },
    characteristic: {
      health: 25,
      force: 30,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "wandTreasure7-2": {
    category: "wand",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0436,
    },
    characteristic: {
      health: 25,
      force: 30,
      power: 30,
      protection: 25,
      accuracy: 25,
      evasion: 25,
      speed: 25,
    },
  },
  "wandTreasure8-2": {
    category: "wand",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0316,
    },
    characteristic: {
      health: 30,
      force: 35,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "wandTreasure9-2": {
    category: "wand",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0326,
    },
    characteristic: {
      health: 30,
      force: 35,
      power: 35,
      protection: 30,
      accuracy: 30,
      evasion: 30,
      speed: 30,
    },
  },
  "wandTreasure10-2": {
    category: "wand",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0116,
    },
    characteristic: {
      health: 50,
      force: 60,
      power: 60,
      protection: 50,
      accuracy: 50,
      evasion: 50,
      speed: 50,
    },
  },
  "wandTreasure11-2": {
    category: "wand",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00516,
    },
    characteristic: {
      health: 60,
      force: 70,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "wandTreasure12-2": {
    category: "wand",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00526,
    },
    characteristic: {
      health: 60,
      force: 70,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "wandTreasure13-2": {
    category: "wand",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00536,
    },
    characteristic: {
      health: 60,
      force: 70,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "wandTreasure14-2": {
    category: "wand",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00546,
    },
    characteristic: {
      health: 60,
      force: 70,
      power: 70,
      protection: 60,
      accuracy: 60,
      evasion: 60,
      speed: 60,
    },
  },
  "broomTreasure1-2": {
    category: "broom",
    src: "Treasure/Treasure1.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0517,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 24,
      accuracy: 20,
      evasion: 24,
      speed: 20,
    },
  },
  "broomTreasure2-2": {
    category: "broom",
    src: "Treasure/Treasure2.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0527,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 24,
      accuracy: 20,
      evasion: 24,
      speed: 20,
    },
  },
  "broomTreasure3-2": {
    category: "broom",
    src: "Treasure/Treasure3.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0537,
    },
    characteristic: {
      health: 20,
      force: 20,
      power: 20,
      protection: 24,
      accuracy: 20,
      evasion: 24,
      speed: 20,
    },
  },
  "broomTreasure4-2": {
    category: "broom",
    src: "Treasure/Treasure4.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0417,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 30,
      speed: 25,
    },
  },
  "broomTreasure5-2": {
    category: "broom",
    src: "Treasure/Treasure5.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0427,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 30,
      speed: 25,
    },
  },
  "broomTreasure6-2": {
    category: "broom",
    src: "Treasure/Treasure6.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0437,
    },
    characteristic: {
      health: 25,
      force: 25,
      power: 25,
      protection: 30,
      accuracy: 25,
      evasion: 30,
      speed: 25,
    },
  },
  "broomTreasure7-2": {
    category: "broom",
    src: "Treasure/Treasure7.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0317,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  "broomTreasure8-2": {
    category: "broom",
    src: "Treasure/Treasure8.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0327,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  "broomTreasure9-2": {
    category: "broom",
    src: "Treasure/Treasure9.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0337,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  "broomTreasure10-2": {
    category: "broom",
    src: "Treasure/Treasure10.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0347,
    },
    characteristic: {
      health: 30,
      force: 30,
      power: 30,
      protection: 35,
      accuracy: 30,
      evasion: 35,
      speed: 30,
    },
  },
  "broomTreasure11-2": {
    category: "broom",
    src: "Treasure/Treasure11.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0217,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 50,
      speed: 40,
    },
  },
  "broomTreasure12-2": {
    category: "broom",
    src: "Treasure/Treasure12.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0227,
    },
    characteristic: {
      health: 40,
      force: 40,
      power: 40,
      protection: 50,
      accuracy: 40,
      evasion: 50,
      speed: 40,
    },
  },
  "broomTreasure13-2": {
    category: "broom",
    src: "Treasure/Treasure13.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.0117,
    },
    characteristic: {
      health: 50,
      force: 50,
      power: 50,
      protection: 60,
      accuracy: 50,
      evasion: 60,
      speed: 50,
    },
  },
  "broomTreasure14-2": {
    category: "broom",
    src: "Treasure/Treasure14.png",
    lvl: 1,
    treasure: {
      lvl: 1,
      chance: 0.00517,
    },
    characteristic: {
      health: 60,
      force: 60,
      power: 60,
      protection: 70,
      accuracy: 60,
      evasion: 70,
      speed: 60,
    },
  },
};

export const allClothes = { ...clothes1, ...clothes2, ...clothes2Treasures };

export const candies = [
  "армотенции",
  "безоар",
  "омелы",
  "белладонны",
  "бубонтюбер",
  "аконита",
  "горелой спички",
  "вяленого помидора",
  "окрошки",
  "красной икры",
  "полцелуя Дементора",
  "Азкабана",
  "мокрой собаки",
  "пыли",
  "глинтвейна",
  "сюрстрёмминга",
  "первокурсника",
  "берёзового сока",
  "соли",
  "крови единорога",
  "яйца грифона",
  "губки для мытья посуды",
  "слива в раковине",
  "отрыжки огра",
  "утренней росы",
  "райского наслаждения",
  "ароматного шашлыка",
  "первой любви",
  "шлака",
  "жареных пельменей",
  "цыпленка табака",
  "кошачей шерсти",
  "мази звездочка",
  "чернил",
  "забродившей вишни",
  "сосисок",
  "маршмеллоу",
  "ушной серы",
  "рвоты",
  "черники",
  "банана",
  "арбуза",
  "вишни",
  "корицы",
  "грязи",
  "мыла",
  "яблока",
  "дождевого червя",
  "сладкой ваты",
  "травы",
  "лимона",
  "тутти-фрутти",
  "тухлого яйца",
  "черного перца",
  "соплей",
  "козявок",
  "навоза",
  "ногтей",
  "жевательной резинки",
  "вяленого мяса",
  "тухлой рыбы",
  "чипсов",
  "вонючих носков",
  "сливочного пива",
  "пота",
  "старухи",
  "туалетной бумаги",
  "подмышки Хагрида",
  "клопа",
  "луковой обжарки",
  "ядреного хрена",
  "кофе",
  "мухомора",
  "струи скунса",
  "подгузников",
  "тухлого сыра",
  "зубной пасты",
  "собачьего корма",
  "шишек",
  "брокколи",
  "мокроты",
  "несбывшихся надежд",
  "летучей мыши",
  "опарышей",
  "сиропа от кашля",
  "спрея от насморка",
  "табака",
  "поганок",
  "мышьяка",
  "серной кислоты",
  "тыквенного сока",
  "семечек с морской солью",
  "кетчунеза",
  "антибиотиков",
  "вазилина",
  "слизней",
  "слез феникса",
  "оборотного зелья",
  "приворотного зелья",
  "мази для метел",
  "слабительного",
  "сыворотки правды",
  "мандрагоры",
  "шоколадной лягушки",
  "перхоти",
  "поцелуя",
  "птичьего помета",
  "беладонны",
  "жаброслей",
  "шпрот",
  "подошвы",
  "корвалола",
  "плесени",
  "спирта",
  "мраморной говядины",
  "черной икры",
  "скорлупы",
  "мяты",
  "кока-колы",
  "помойных отходов",
  "крысиных хвостов",
  "улитки",
  "лягушачьих лапок",
  "лужи",
  "неразделенной любви",
  "выгребной ямы",
];

export const rules = [
  "Правила чата приложения Школа Магии.",
  "Незнание правил не освобождает Вас от ответственности.",

  "Раздел 1 - Общение",
  "1.1. Запрещено оскорблять проект;",
  "1.2. Запрещено оскорблять администрацию и участников чата, пресекаются любые негативные высказывания;",
  "1.3. Запрещено разводить конфликты на национальной, расовой, религиозной или любой другой почве;",
  "1.4 Запрещено оскорблять и принижать сформированные ордены;",
  "1.5. Запрещено отправлять более 3-х одинаковых сообщений ( расценивается как флуд );",
  "1.6. Запрещена клевета в сторону администрации/игроков;",
  "1.7. Запрещены любые негативные высказывания в сторону обновлений/конкурсов/новостей и т.д. (любые осуждения механики игры и новых обновлений в негативном ключе будут пресекаться (допускается только конструктивная критика));",
  "1.8. Запрещены постоянные выходы из чата;",
  "1.9. Запрещено размещать любые виды порнографии и нацизма;",
  "1.10. Запрещено упоминать родственников в любой негативной интерпретации;",
  "1.11. Запрещено обсуждать и осуждать действия администрации проекта;",
  "1.12. Запрещено обсуждать или упоминать любые другие игровые проекты;",
  "1.13. Запрещена Любая нецензурная и ненормативная лексика.",

  "Раздел 2 - Реклама.",
  "2.1. Запрещено привлекать игроков на другие проекты путем различной рекламы;",
  "2.2. Запрещено рекламировать конкурсы/группы, которые не относятся к Школе Магии;",
  "2.3. Строго запрещается размещение Любых ссылок в чате (особенно тех, которые несут потенциальное приглашение в другие чаты)",

  "Раздел 3 – Общие правила:",
  "3.1. Все решения о вынесения наказания определяются администрацией сообщества и не подлежал обжалованию;",
  "3.2. Администратор вправе избрать любую форму наказания, по своему усмотрению;",
  "3.3. Любые попытки обжаловать решения администрации о вынесении наказания со стороны других игроков будут также пресекаться баном",
  "3.4. Администрация сообщества вправе не объяснять игроку, за что тот был наказан, вся нужная информация для игроков чётко прописана в правилах.",

  "За нарушение вышеперечисленных правил Вы можете получить наказание следующего вида:",
  "1. Предупреждение в чате;",
  "2. Мут в чате;",
  "3. Кик с чата;",
  "4. Вечный бан в чате.",
  "5. Бан в игре «Школа Магии»",
  "Мера пресечения выбирается исходя из того, какой пункт правил Вы нарушили.",
];

// const test = {};

// Object.entries(clothes2Treasures).map((elem) => {
//   const max = Math.max.apply(null, Object.values(elem[1].characteristic));
//   const min = Math.min.apply(null, Object.values(elem[1].characteristic));
//
//   if (elem[1].category === "shirt") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: min,
//         power: max,
//         protection: min,
//         accuracy: min,
//         evasion: min,
//         speed: min,
//       },
//     };
//   }
//
//   if (elem[1].category === "pants") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: min,
//         power: min,
//         protection: max,
//         accuracy: min,
//         evasion: min,
//         speed: min,
//       },
//     };
//   }
//
//   if (elem[1].category === "hand") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: max,
//         force: min,
//         power: min,
//         protection: min,
//         accuracy: min,
//         evasion: min,
//         speed: min,
//       },
//     };
//   }
//
//   if (elem[1].category === "head") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: min,
//         power: min,
//         protection: min,
//         accuracy: max,
//         evasion: min,
//         speed: min,
//       },
//     };
//   }
//
//   if (elem[1].category === "shoes") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: min,
//         power: min,
//         protection: min,
//         accuracy: min,
//         evasion: max,
//         speed: max,
//       },
//     };
//   }
//
//   if (elem[1].category === "wand") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: max,
//         power: max,
//         protection: min,
//         accuracy: min,
//         evasion: min,
//         speed: min,
//       },
//     };
//   }
//
//   if (elem[1].category === "broom") {
//     test[elem[0]] = {
//       ...elem[1],
//       characteristic: {
//         health: min,
//         force: min,
//         power: min,
//         protection: max,
//         accuracy: min,
//         evasion: max,
//         speed: min,
//       },
//     };
//   }
// });
//
// console.log(test);
